export const PROFESSION_LIST_3 = [
    { value: 'PRG', name: 'PROGRAMADOR DE COMPUTADOR'},
    { value: 'OC1535', name: 'PROGRAMADOR/A DE COMPUTADORES'},
    { value: 'DRAFT', name: 'PROJETISTA'},
    { value: 'OC1536', name: 'PROJETISTA ARQUITETO'},
    { value: 'OC1537', name: 'PROJETISTA DE AUDIO'},
    { value: 'OC1538', name: 'PROJETISTA DE MOVEIS'},
    { value: 'OC1539', name: 'PROJETISTA DE SOM'},
    { value: 'OC1540', name: 'PROJETISTA ELETRICO'},
    { value: 'OC1541', name: 'PROJETISTA ELETROELETRONICO'},
    { value: 'OC1542', name: 'PROJETISTA ELETROTECNICO'},
    { value: 'OC1543', name: 'PROJETISTA NA CONSTRUCAO CIVIL'},
    { value: 'OC1544', name: 'PROMOTOR/A DE JUSTICA'},
    { value: 'OC1545', name: 'PROMOTOR/A DE VENDAS'},
    { value: 'OC1546', name: 'PROPAGANDISTA'},
    { value: 'PROSPECTER', name: 'PROSPECTER - FIELD ONLY'},
    { value: 'OC1547', name: 'PROTESISTA'},
    { value: 'OC1548', name: 'PROTETICO/A DENTARIO'},
    { value: 'OC1549', name: 'PSICANALISTA'},
    { value: 'PSYCHO', name: 'PSICOLOGO/A'},
    { value: 'OC1551', name: 'PSICOLOGO/A CLINICO'},
    { value: 'OC1552', name: 'PSICOLOGO/A CRIMINAL'},
    { value: 'OC1553', name: 'PSICOLOGO/A FORENSE'},
    { value: 'OC1554', name: 'PSICOLOGO/A HOSPITALAR'},
    { value: 'OC1555', name: 'PSICOLOGO/A JURIDICO'},
    { value: 'OC1556', name: 'PSICOLOGO/A ORGANIZACIONAL'},
    { value: 'OC1557', name: 'PSICOPEDAGOGO/A'},
    { value: 'OC1558', name: 'PSICOTERAPEUTA'},
    { value: 'OC1559', name: 'PSIQUIATRA'},
    { value: 'OC1560', name: 'PUBLICISTA'},
    { value: 'PU', name: 'PUBLICITARIO/A'},
    { value: 'OC1562', name: 'QUIMICO/A'},
    { value: 'OC1563', name: 'QUIMICO/A AGRICOLA'},
    { value: 'OC1564', name: 'QUIMICO/A AMBIENTAL'},
    { value: 'OC1565', name: 'QUIMICO/A ANALISTA'},
    { value: 'OC1566', name: 'QUIMICO/A CONSULTOR'},
    { value: 'OC1567', name: 'QUIMICO/A COSMETOLOGO'},
    { value: 'OC1568', name: 'QUIMICO/A DE ALIMENTOS'},
    { value: 'OC1569', name: 'QUIMICO/A DE LABORATORIO'},
    { value: 'OC1570', name: 'QUIMICO/A DE PETROLEO'},
    { value: 'OC1571', name: 'QUIMICO/A DE POLIMEROS'},
    { value: 'OC1572', name: 'QUIMICO/A DE PROCESSOS'},
    { value: 'OC1573', name: 'QUIMICO/A INDUSTRIAL'},
    { value: 'OC1574', name: 'QUIMICO/A PERFUMISTA'},
    { value: 'OC1575', name: 'QUIROPRATICO'},
    { value: 'OC1576', name: 'RABINO'},
    { value: 'OC1577', name: 'RADIALISTA'},
    { value: 'OC1578', name: 'RADIOLOGISTA'},
    { value: 'OC1579', name: 'RADIOTERAPEUTA'},
    { value: 'OC1580', name: 'RECENSEADOR/A'},
    { value: 'RECEP', name: 'RECEPCIONISTA'},
    { value: 'OC1581', name: 'RECEPCIONISTA/A'},
    { value: 'OC1582', name: 'RECREADOR/A'},
    { value: 'OC1583', name: 'RECUPERADOR DE ATIVOS'},
    { value: 'OC1584', name: 'RECUPERADOR DE CREDITO'},
    { value: 'OC1585', name: 'REDATOR/A'},
    { value: 'OC1586', name: 'REGENTE DE BANDA'},
    { value: 'OC1587', name: 'REGENTE DE CORAL'},
    { value: 'OC1588', name: 'REGENTE DE ORQUESTRA'},
    { value: 'OC1589', name: 'REGISTRADOR/A CIVIL DE PESSOAS JURIDICAS'},
    { value: 'OC1590', name: 'REGISTRADOR/A CIVIL DE PESSOAS NATURAIS'},
    { value: 'OC1591', name: 'REGISTRADOR/A DE CONTRATOS MARITIMOS'},
    { value: 'OC1592', name: 'REGISTRADOR/A DE TITULOS E DOCUMENTOS'},
    { value: 'OC1593', name: 'REGISTRADOR/A IMOBILIARIO'},
    { value: 'OC1594', name: 'REGULADOR/A DE SINISTROS'},
    { value: 'OC1595', name: 'REITOR/A'},
    { value: 'OC1596', name: 'RELACOES PUBLICAS'},
    { value: 'OC1597', name: 'RELOJOEIRO/A'},
    { value: 'OC1598', name: 'REPORTER'},
    { value: 'OC1599', name: 'REPORTER CINEMATOGRAFICO'},
    { value: 'OC1600', name: 'REPORTER CORRESPONDENTE'},
    { value: 'OC1601', name: 'REPORTER FOTOGRAFICO'},
    { value: 'OC1602', name: 'REPORTER INVESTIGATIVO'},
    { value: 'OC1603', name: 'REPOSITOR DE MERCADORIAS'},
    { value: 'COMMREP', name: 'REPRESENTANTE COMERCIAL'},
    { value: 'OC1605', name: 'REPRESENTANTE DE VENDAS'},
    { value: 'OC1606', name: 'RESTAURADOR/A'},
    { value: 'OC1607', name: 'RETIFICADOR/A DE MOTORES'},
    { value: 'OC1608', name: 'RETRATISTA'},
    { value: 'OC1609', name: 'REUMATOLOGISTA'},
    { value: 'OC1610', name: 'REVISOR/A CONTABIL'},
    { value: 'OC1611', name: 'REVISOR/A DE TEXTOS'},
    { value: 'OC1612', name: 'REVISOR/A GRAFICO'},
    { value: 'OC1613', name: 'ROADIE - ASSISTENTE DE PALCO'},
    { value: 'OC1614', name: 'ROTEIRISTA'},
    { value: 'OC1615', name: 'ROTULADOR/A'},
    { value: 'OC1616', name: 'ROUPEIRO/A'},
    { value: 'OC1617', name: 'SALVA-VIDAS'},
    { value: 'OC1618', name: 'SAPATEIRO/A'},
    { value: 'SARG', name: 'SARGENTO'},
    { value: 'OC1620', name: 'SARGENTO DA POLICIA MILITAR'},
    { value: 'OC1621', name: 'SARGENTO DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'SECRE', name: 'SECRETARIO/A'},
    { value: 'OC1623', name: 'SECRETARIO/A DE ESTADO'},
    { value: 'OC1624', name: 'SECRETARIO/A DE GABINETE'},
    { value: 'OC1625', name: 'SECRETARIO/A DE PRESIDENCIA'},
    { value: 'OC1626', name: 'SECRETARIO/A DIPLOMATA'},
    { value: 'OC1627', name: 'SECRETARIO/A ESTADUAL DO SERVICO PUBLICO'},
    { value: 'OC1628', name: 'SECRETARIO/A EXECUTIVO'},
    { value: 'OC1629', name: 'SECRETARIO/A MUNICIPAL DO SERVC PUBLICO'},
    { value: 'SEC', name: 'SECURITARIO/A'},
    { value: 'OC1631', name: 'SEGUNDO-SARGENTO'},
    { value: 'OC1632', name: 'SEGUNDO-SARGENTO DE POLICIA MILITAR'},
    { value: 'OC1633', name: 'SEGUNDO-SARGENTO DO CORPO DE BOMBEIROS'},
    { value: 'OC1634', name: 'SEGUNDO-TENENTE'},
    { value: 'OC1635', name: 'SEGUNDO-TENENTE DE POLICIA MILITAR'},
    { value: 'OC1636', name: 'SEGUNDO-TENENTE DO CORPO DE BOMBEIROS'},
    { value: 'SEGU', name: 'SEGURANCA'},
    { value: 'OC1638', name: 'SEGURANCA COMUNITARIO'},
    { value: 'OC1639', name: 'SEGURANCA DE EVENTOS'},
    { value: 'OC1640', name: 'SEGURANCA PESSOAL'},
    { value: 'OC1641', name: 'SENADOR/A'},
    { value: 'OC1642', name: 'SERIGRAFISTA'},
    { value: 'OC1643', name: 'SERRALHEIRO/A'},
    { value: 'OC1644', name: 'SERVENTE DE LANCHES'},
    { value: 'OC1645', name: 'SERVENTE DE LIMPEZA'},
    { value: 'OC1646', name: 'SERVENTE DE OBRAS'},
    { value: 'OC1647', name: 'SERVENTE DE PEDREIRO'},
    { value: 'OC1648', name: 'SERVENTE DE SERVICOS GERAIS'},
    { value: 'OC1649', name: 'SERVENTE NA CONSTRUCAO CIVIL'},
    { value: 'OC1650', name: 'SINDICO/A PROFISSIONAL'},
    { value: 'OC1651', name: 'SOCIOLOGO/A'},
    { value: 'SOLD', name: 'SOLDADO'},
    { value: 'OC1653', name: 'SOLDADO BOMBEIRO MILITAR'},
    { value: 'OC1654', name: 'SOLDADO DA AERONAUTICA'},
    { value: 'OC1655', name: 'SOLDADO DA MARINHA'},
    { value: 'OC1656', name: 'SOLDADO DA POLICIA MILITAR'},
    { value: 'OC1657', name: 'SOLDADO DO EXERCITO'},
    { value: 'OC1658', name: 'SOLDADO FUZILEIRO NAVAL'},
    { value: 'OC1659', name: 'SOLDADOR/A'},
    { value: 'OC1660', name: 'SOMMELIER'},
    { value: 'OC1661', name: 'SONOPLASTA'},
    { value: 'OC1662', name: 'SORVETEIRO/A'},
    { value: 'OC1663', name: 'SUBPROCURADOR/A DE JUSTICA'},
    { value: 'OC1664', name: 'SUBPROCURADOR/A DE JUSTICA MILITAR'},
    { value: 'OC1665', name: 'SUBPROCURADOR/A GERAL DA REPUBLICA'},
    { value: 'OC1666', name: 'SUBPROCURADOR/A GERAL DO TRABALHO'},
    { value: 'OC1667', name: 'SUBSCRITOR/A DE SEGUROS'},
    { value: 'OC1668', name: 'SUBSECRETARIO/A DE ESTADO'},
    { value: 'SUTE', name: 'SUBTENENTE'},
    { value: 'OC1670', name: 'SUBTENENTE DA POLICIA MILITAR'},
    { value: 'OC1671', name: 'SUBTENENTE DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'OC1673', name: 'SUPERINTENDENTE ADM E FINANCEIRO'},
    { value: 'OC1672', name: 'SUPERINTENDENTE ADMINISTRATIVO'},
    { value: 'OC1674', name: 'SUPERINTENDENTE COMERCIAL'},
    { value: 'OC1675', name: 'SUPERINTENDENTE DE FINANCAS'},
    { value: 'OC1676', name: 'SUPERINTENDENTE DE MANUTENCAO'},
    { value: 'OC1677', name: 'SUPERINTENDENTE DE MARKETING'},
    { value: 'OC1678', name: 'SUPERINTENDENTE DE OPERACOES INDUSTRIAIS'},
    { value: 'OC1679', name: 'SUPERINTENDENTE DE RELACAO INSTITUCIONAL'},
    { value: 'OC1680', name: 'SUPERINTENDENTE FINANCEIRO'},
    { value: 'OC1681', name: 'SUPERVISOR/A ADMINISTRATIVO'},
    { value: 'OC1682', name: 'SUPERVISOR/A COMERCIAL'},
    { value: 'OC1683', name: 'SUPERVISOR/A DA ADM DE AEROPORTOS'},
    { value: 'OC1684', name: 'SUPERVISOR/A DE ALMOXARIFADO'},
    { value: 'OC1685', name: 'SUPERVISOR/A DE ATENDIMENTO'},
    { value: 'OC1686', name: 'SUPERVISOR/A DE BOMBEIROS'},
    { value: 'OC1687', name: 'SUPERVISOR/A DE CALLCENTER'},
    { value: 'OC1688', name: 'SUPERVISOR/A DE CAMBIO'},
    { value: 'OC1689', name: 'SUPERVISOR/A DE CENTRAL DE ATENDIMENTO'},
    { value: 'OC1690', name: 'SUPERVISOR/A DE COBRANCA'},
    { value: 'OC1691', name: 'SUPERVISOR/A DE COMPRAS'},
    { value: 'OC1692', name: 'SUPERVISOR/A DE CONSTRUCAO CIVIL'},
    { value: 'OC1693', name: 'SUPERVISOR/A DE CONSTRUCAO NAVAL'},
    { value: 'OC1694', name: 'SUPERVISOR/A DE CONTABILIDADE'},
    { value: 'OC1695', name: 'SUPERVISOR/A DE CONTAS A PAGAR'},
    { value: 'OC1696', name: 'SUPERVISOR/A DE COZINHA'},
    { value: 'OC1697', name: 'SUPERVISOR/A DE CREDITO'},
    { value: 'OC1698', name: 'SUPERVISOR/A DE CREDITO E COBRANCA'},
    { value: 'OC1699', name: 'SUPERVISOR/A DE ENSINO'},
    { value: 'OC1700', name: 'SUPERVISOR/A DE EXPLORACAO AGRICOLA'},
    { value: 'OC1701', name: 'SUPERVISOR/A DE EXPLORACAO AGROPECUARIA'},
    { value: 'OC1702', name: 'SUPERVISOR/A DE EXPLORACAO PECUARIA'},
    { value: 'OC1703', name: 'SUPERVISOR/A DE MANUTENCAO'},
    { value: 'OC1704', name: 'SUPERVISOR/A DE MINA'},
    { value: 'OC1705', name: 'SUPERVISOR/A DE MONTAGEM'},
    { value: 'OC1706', name: 'SUPERVISOR/A DE OPERACOES'},
    { value: 'OC1707', name: 'SUPERVISOR/A DE ORCAMENTO'},
    { value: 'OC1708', name: 'SUPERVISOR/A DE PRODUCAO'},
    { value: 'OC1709', name: 'SUPERVISOR/A DE SEGURANCA DO TRABALHO'},
    { value: 'OC1710', name: 'SUPERVISOR/A DE SEGURANCA PATRIMONIAL'},
    { value: 'OC1711', name: 'SUPERVISOR/A DE SEGURANCA/VIGILANCIA'},
    { value: 'OC1712', name: 'SUPERVISOR/A DE SERVICOS GERAIS'},
    { value: 'OC1713', name: 'SUPERVISOR/A DE TELEATENDIMENTO'},
    { value: 'OC1714', name: 'SUPERVISOR/A DE TELEMARKETING E ATEND'},
    { value: 'OC1715', name: 'SUPERVISOR/A DE TELEVENDAS'},
    { value: 'OC1716', name: 'SUPERVISOR/A DE TESOURARIA'},
    { value: 'OC1717', name: 'SUPERVISOR/A DE TRAFEGO RODOVIARIO'},
    { value: 'OC1718', name: 'SUPERVISOR/A DE TRANSPORTE NA MINERACAO'},
    { value: 'OC1719', name: 'SUPERVISOR/A DE TRANSPORTES'},
    { value: 'OC1720', name: 'SUPERVISOR/A DE TRENS'},
    { value: 'OC1721', name: 'SUPERVISOR/A DE VENDAS'},
    { value: 'OC1722', name: 'SUPERVISOR/A EDUCACIONAL'},
    { value: 'OC1723', name: 'SUPERVISOR/A FLORESTAL'},
    { value: 'OC1724', name: 'SUPERVISOR/A PEDAGOGICO'},
    { value: 'OC1725', name: 'SUPERVISOR/A TECNICO'},
    { value: 'OC1726', name: 'TABELIAO/A'},
    { value: 'OC1727', name: 'TABELIAO/A DA JUSTICA'},
    { value: 'OC1728', name: 'TABELIAO/A DE CONTRATOS MARITIMOS'},
    { value: 'OC1729', name: 'TABELIAO/A DE NOTAS'},
    { value: 'OC1730', name: 'TABELIAO/A DE PROTESTO DE LETRA E TITULO'},
    { value: 'OC1731', name: 'TABELIAO/A DE PROTESTOS'},
    { value: 'OC1732', name: 'TAIFEIRO'},
    { value: 'OC1733', name: 'TAIFEIRO-MOR'},
    { value: 'OC1734', name: 'TAPECEIRO/A'},
    { value: 'OC1735', name: 'TAQUIGRAFO/A'},
    { value: 'OC1736', name: 'TAROLOGO/A'},
    { value: 'OC1737', name: 'TAXIDERMISTA'},
    { value: 'OC1738', name: 'TAXISTA'},
    { value: 'OC1739', name: 'TECELAO/A'},
    { value: 'TECH', name: 'TECNICO/A'},
    { value: 'OC1740', name: 'TECNICO/A AERONAUTICO'},
    { value: 'OC1741', name: 'TECNICO/A AGRICOLA'},
    { value: 'OC1742', name: 'TECNICO/A AGROPECUARIO'},
    { value: 'OC1743', name: 'TECNICO/A AMBIENTAL'},
    { value: 'OC1744', name: 'TECNICO/A AUTOMOTIVO'},
    { value: 'OC1745', name: 'TECNICO/A CERAMISTA'},
    { value: 'OC1746', name: 'TECNICO/A DA RECEITA FEDERAL'},
    { value: 'OC1747', name: 'TECNICO/A DE ABAST, CAPT E DISTRIB AGUA'},
    { value: 'OC1748', name: 'TECNICO/A DE ALIMENTOS'},
    { value: 'OC1749', name: 'TECNICO/A DE ANALISE QUIMICA'},
    { value: 'OC1750', name: 'TECNICO/A DE ARQUIVO'},
    { value: 'OC1751', name: 'TECNICO/A DE ARTES GRAFICAS'},
    { value: 'OC1752', name: 'TECNICO/A DE AUDIO'},
    { value: 'OC1753', name: 'TECNICO/A DE BILBIOTECA'},
    { value: 'OC1754', name: 'TECNICO/A DE CINEMA'},
    { value: 'OC1755', name: 'TECNICO/A DE CONTROLE DE QUALIDADE'},
    { value: 'OC1756', name: 'TECNICO/A DE DOCUMENTACAO'},
    { value: 'OC1757', name: 'TECNICO/A DE EDIFICACOES'},
    { value: 'OC1758', name: 'TECNICO/A DE ELETRICIDADE'},
    { value: 'OC1759', name: 'TECNICO/A DE ENFERMAGEM'},
    { value: 'OC1760', name: 'TECNICO/A DE ENGENHARIA CIVIL'},
    { value: 'OC1761', name: 'TECNICO/A DE ESTRADAS'},
    { value: 'OC1762', name: 'TECNICO/A DE FIACAO'},
    { value: 'OC1763', name: 'TECNICO/A DE FUNDICAO EM SIDERURGIA'},
    { value: 'OC1764', name: 'TECNICO/A DE GEOLOGIA'},
    { value: 'OC1765', name: 'TECNICO/A DE GESTAO DO MEIO AMBIENTE'},
    { value: 'OC1767', name: 'TECNICO/A DE IMOBILIZACAO ORTOPEDICA'},
    { value: 'OC1768', name: 'TECNICO/A DE IMPRESSORA MATRICIAL'},
    { value: 'OC1769', name: 'TECNICO/A DE INDUSTRIA ELETRONICA'},
    { value: 'OC1770', name: 'TECNICO/A DE INDUSTRIA PETROQUIMICA'},
    { value: 'OC1771', name: 'TECNICO/A DE INDUSTRIA QUIMICA'},
    { value: 'OC1772', name: 'TECNICO/A DE LABORATORIO'},
    { value: 'OC1773', name: 'TECNICO/A DE MANUTENCAO AERONAUTICA'},
    { value: 'OC1774', name: 'TECNICO/A DE MANUTENCAO DE AERONAVES'},
    { value: 'OC1775', name: 'TECNICO/A DE MANUTENCAO DE COMPUTADORES'},
    { value: 'OC1776', name: 'TECNICO/A DE MANUTENCAO DE OBRAS'},
    { value: 'OC1777', name: 'TECNICO/A DE MANUTENCAO ELETRICA'},
    { value: 'OC1778', name: 'TECNICO/A DE MANUTENCAO ELETRONICA'},
    { value: 'OC1779', name: 'TECNICO/A DE MANUTENCAO INDUSTRIAL'},
    { value: 'OC1780', name: 'TECNICO/A DE MAQUINA AUTENTICADORA'},
    { value: 'OC1781', name: 'TECNICO/A DE METEOROLOGIA'},
    { value: 'OC1782', name: 'TECNICO/A DE MINERACAO'},
    { value: 'OC1783', name: 'TECNICO/A DE MIXAGEM'},
    { value: 'OC1784', name: 'TECNICO/A DE MONTAGEM'},
    { value: 'OC1785', name: 'TECNICO/A DE OBRAS'},
    { value: 'OC1786', name: 'TECNICO/A DE ORIENTACAO PROFISSIONAL'},
    { value: 'OC1787', name: 'TECNICO/A DE ORTOPEDIA'},
    { value: 'OC1788', name: 'TECNICO/A DE PALCO'},
    { value: 'OC1789', name: 'TECNICO/A DE PANIFICACAO'},
    { value: 'OC1790', name: 'TECNICO/A DE PERFURACAO'},
    { value: 'OC1791', name: 'TECNICO/A DE PROCES EM REFINO PETRO/GAS'},
    { value: 'OC1792', name: 'TECNICO/A DE PROCES INDUST PETROQUIMICA'},
    { value: 'OC1793', name: 'TECNICO/A DE PROCESSOS'},
    { value: 'OC1798', name: 'TECNICO/A DE PROD EM REFINO DE PETROLEO'},
    { value: 'OC1800', name: 'TECNICO/A DE PROD EXTRACAO DE PETROLEO'},
    { value: 'OC1794', name: 'TECNICO/A DE PRODUCAO'},
    { value: 'OC1795', name: 'TECNICO/A DE PRODUCAO DE ALIMENTOS'},
    { value: 'OC1796', name: 'TECNICO/A DE PRODUCAO DE GAS COMBUSTIVEL'},
    { value: 'OC1797', name: 'TECNICO/A DE PRODUCAO ELETRONICA'},
    { value: 'OC1799', name: 'TECNICO/A DE PRODUCAO FLORESTAL'},
    { value: 'OC1801', name: 'TECNICO/A DE PRODUCAO PETROQUIMICA'},
    { value: 'OC1802', name: 'TECNICO/A DE PROTESE E ORTESE'},
    { value: 'OC1803', name: 'TECNICO/A DE PROTESE ORTOPEDICA'},
    { value: 'OC1804', name: 'TECNICO/A DE RADIO E TELEVISAO'},
    { value: 'OC1805', name: 'TECNICO/A DE RADIOTERAPIA'},
    { value: 'OC1806', name: 'TECNICO/A DE REDE TELECOMUNICACOES'},
    { value: 'OC1807', name: 'TECNICO/A DE REDES DE AGUA E ESGOTO'},
    { value: 'OC1808', name: 'TECNICO/A DE REFLORESTAMENTO'},
    { value: 'OC1809', name: 'TECNICO/A DE REFRIGERACAO'},
    { value: 'OC1810', name: 'TECNICO/A DE RESSEGUROS'},
    { value: 'OC1811', name: 'TECNICO/A DE SANEAMENTO'},
    { value: 'OC1812', name: 'TECNICO/A DE SEGUROS'},
    { value: 'OC1813', name: 'TECNICO/A DE SINISTROS'},
    { value: 'OC1814', name: 'TECNICO/A DE TEATRO'},
    { value: 'OC1815', name: 'TECNICO/A DE TELECOMUNICACOES'},
    { value: 'OC1816', name: 'TECNICO/A DE TELEFONIA'},
    { value: 'OC1817', name: 'TECNICO/A DE TELEVISAO'},
    { value: 'OC1818', name: 'TECNICO/A DE TRANSMISSAO TELECOM'},
    { value: 'OC1819', name: 'TECNICO/A DE TRANSPORTE FERROVIARIO'},
    { value: 'OC1820', name: 'TECNICO/A DE TRIBUTOS'},
    { value: 'OC1821', name: 'TECNICO/A DE VENDAS'},
    { value: 'OC1822', name: 'TECNICO/A ELETRICISTA'},
    { value: 'OC1823', name: 'TECNICO/A ELETRONICO'},
    { value: 'OC1824', name: 'TECNICO/A EM ACUPUNTURA'},
    { value: 'OC1826', name: 'TECNICO/A EM ADM DE COMERCIO EXTERIOR'},
    { value: 'OC1825', name: 'TECNICO/A EM ADMINISTRACAO'},
    { value: 'OC1827', name: 'TECNICO/A EM AGRICULTURA'},
    { value: 'OC1828', name: 'TECNICO/A EM AGRIMENSURA'},
    { value: 'OC1829', name: 'TECNICO/A EM AGROPECUARIA'},
    { value: 'OC1830', name: 'TECNICO/A EM ANALISE DE CONTROLE DE PROD'},
    { value: 'OC1831', name: 'TECNICO/A EM ANALISES CLINICAS'},
    { value: 'OC1832', name: 'TECNICO/A EM ATENDIMENTO E VENDAS'},
    { value: 'OC1833', name: 'TECNICO/A EM AUTOMACAO INDUSTRIAL'},
    { value: 'OC1834', name: 'TECNICO/A EM AUTOMOBILISTICA'},
    { value: 'OC1835', name: 'TECNICO/A EM AVICULTURA'},
    { value: 'OC1836', name: 'TECNICO/A EM BIBLIOTECONOMIA'},
    { value: 'OC1837', name: 'TECNICO/A EM BIOTECNOLOGIA'},
    { value: 'OC1838', name: 'TECNICO/A EM CALDEIRARIA'},
    { value: 'OC1839', name: 'TECNICO/A EM CONSTRUCAO CIVIL'},
    { value: 'OC1840', name: 'TECNICO/A EM DIREITOS AUTORAIS'},
    { value: 'OC1841', name: 'TECNICO/A EM DOCUMENTACAO'},
    { value: 'OC1842', name: 'TECNICO/A EM ELETROCARDIOGRAMA'},
    { value: 'OC1843', name: 'TECNICO/A EM ELETROENCEFALOGRAMA'},
    { value: 'OC1844', name: 'TECNICO/A EM ELETROMECANICA'},
    { value: 'OC1845', name: 'TECNICO/A EM ENOLOGIA'},
    { value: 'OC1846', name: 'TECNICO/A EM EQUIPAMENTOS OPTICOS'},
    { value: 'OC1847', name: 'TECNICO/A EM EXPLORACAO DE PETROLEO'},
    { value: 'OC1848', name: 'TECNICO/A EM FARMACIA'},
    { value: 'OC1849', name: 'TECNICO/A EM FARMACIA E EM MANIP FARMAC'},
    { value: 'OC1850', name: 'TECNICO/A EM FERRAMENTAS ELETRICAS'},
    { value: 'OC1851', name: 'TECNICO/A EM FIBRAS OPTICAS'},
    { value: 'OC1852', name: 'TECNICO/A EM GEODESIA E CARTOGRAFIA'},
    { value: 'OC1853', name: 'TECNICO/A EM GEOFISICA'},
    { value: 'OC1854', name: 'TECNICO/A EM GEOLOGIA'},
    { value: 'OC1855', name: 'TECNICO/A EM GEOQUIMICA'},
    { value: 'OC1856', name: 'TECNICO/A EM GEOTECNIA'},
    { value: 'OC1857', name: 'TECNICO/A EM GESSO HOSPITALAR'},
    { value: 'OC1858', name: 'TECNICO/A EM GESSO ORTOPEDICO'},
    { value: 'OC1859', name: 'TECNICO/A EM GRAVACAO DE AUDIO'},
    { value: 'OC1860', name: 'TECNICO/A EM HEMODIALISE'},
    { value: 'OC1861', name: 'TECNICO/A EM HEMODINAMICA'},
    { value: 'OC1862', name: 'TECNICO/A EM HEMOTRANSFUSAO'},
    { value: 'OC1863', name: 'TECNICO/A EM HIDROGRAFIA'},
    { value: 'OC1864', name: 'TECNICO/A EM HISTOLOGIA'},
    { value: 'OC1865', name: 'TECNICO/A EM IMOB DO APARELHO LOCOMOTOR'},
    { value: 'OC1866', name: 'TECNICO/A EM IMOBILIZACOES GESSADAS'},
    { value: 'OC1867', name: 'TECNICO/A EM IMUNOBIOLOGICOS'},
    { value: 'OC1868', name: 'TECNICO/A EM INJECAO'},
    { value: 'OC1869', name: 'TECNICO/A EM INSTALACAO DE EQUIP AUDIO'},
    { value: 'OC1870', name: 'TECNICO/A EM INSTALACAO DE SIST OPTICOS'},
    { value: 'OC1871', name: 'TECNICO/A EM INSTRUMENTACAO'},
    { value: 'OC1872', name: 'TECNICO/A EM LASER'},
    { value: 'OC1873', name: 'TECNICO/A EM MAMOGRAFIA'},
    { value: 'OC1874', name: 'TECNICO/A EM MANUTENCAO DE AERONAVES'},
    { value: 'OC1875', name: 'TECNICO/A EM MANUTENCAO DE EQUIP DE INFO'},
    { value: 'OC1876', name: 'TECNICO/A EM MANUTENCAO DE EQUIP HOSPIT'},
    { value: 'OC1877', name: 'TECNICO/A EM MANUTENCAO DE EQUIP INDUST'},
    { value: 'OC1878', name: 'TECNICO/A EM MASTERIZACAO DE AUDIO'},
    { value: 'OC1879', name: 'TECNICO/A EM MECANICA DE PRECISAO'},
    { value: 'OC1880', name: 'TECNICO/A EM MECATRONICA'},
    { value: 'OC1881', name: 'TECNICO/A EM MEDICINA NUCLEAR'},
    { value: 'OC1882', name: 'TECNICO/A EM MEIO AMBIENTE'},
    { value: 'OC1883', name: 'TECNICO/A EM METALURGIA'},
    { value: 'OC1884', name: 'TECNICO/A EM MIXAGEM DE AUDIO'},
    { value: 'OC1885', name: 'TECNICO/A EM MUSEOLOGIA'},
    { value: 'OC1886', name: 'TECNICO/A EM NUTRICAO'},
    { value: 'OC1887', name: 'TECNICO/A EM OPTICA E OPTOMETRIA'},
    { value: 'OC1888', name: 'TECNICO/A EM PATOLOGIA CLINICA'},
    { value: 'OC1889', name: 'TECNICO/A EM PETROQUIMICA'},
    { value: 'OC1890', name: 'TECNICO/A EM PISCICULTURA'},
    { value: 'OC1891', name: 'TECNICO/A EM PODOLOGIA'},
    { value: 'OC1892', name: 'TECNICO/A EM PROGRAMACAO VISUAL'},
    { value: 'OC1893', name: 'TECNICO/A EM QUIMICA DE ALIMENTOS'},
    { value: 'OC1894', name: 'TECNICO/A EM QUIROPRAXIA'},
    { value: 'OC1895', name: 'TECNICO/A EM RADIOLOGIA'},
    { value: 'OC1896', name: 'TECNICO/A EM REFINACAO DE PETROLEO'},
    { value: 'OC1897', name: 'TECNICO/A EM RESSONANCIA MAGNETICA'},
    { value: 'OC1898', name: 'TECNICO/A EM ROBOTICA'},
    { value: 'OC1899', name: 'TECNICO/A EM SAUDE BUCAL'},
    { value: 'OC1900', name: 'TECNICO/A EM SECRETARIADO'},
    { value: 'OC1901', name: 'TECNICO/A EM SEGURANCA INDUSTRIAL'},
    { value: 'OC1902', name: 'TECNICO/A EM SEGURANCA NO TRABALHO'},
    { value: 'OC1903', name: 'TECNICO/A EM SOLDAGEM'},
    { value: 'OC1904', name: 'TECNICO/A EM SONORIZACAO'},
    { value: 'OC1905', name: 'TECNICO/A EM TOMOGRAFIA'},
    { value: 'OC1906', name: 'TECNICO/A EM TRATAMENTO DE EFLUENTES'},
    { value: 'OC1907', name: 'TECNICO/A EM TURISMO'},
    { value: 'OC1908', name: 'TECNICO/A EM ZOOTECNIA'},
    { value: 'OC1909', name: 'TECNICO/A ENGESSADOR'},
    { value: 'OC1910', name: 'TECNICO/A FLORESTAL'},
    { value: 'OC1911', name: 'TECNICO/A GESSISTA'},
    { value: 'OC1912', name: 'TECNICO/A GRAFICO'},
    { value: 'OC1913', name: 'TECNICO/A HIDROMETRISTA'},
    { value: 'OC1766', name: 'TECNICO/A IDENTIFICACAO PAPILOSCOPISTA'},
    { value: 'OC1914', name: 'TECNICO/A IMOBILIARIO'},
    { value: 'OC1915', name: 'TECNICO/A INSTRUMENTISTA'},
    { value: 'OC1916', name: 'TECNICO/A MECANICO'},
    { value: 'OC1917', name: 'TECNICO/A MECANICO NAVAL'},
    { value: 'OC1918', name: 'TECNICO/A MONTADOR DE AERONAVES'},
    { value: 'OC1919', name: 'TECNICO/A MOVELEIRO'},
    { value: 'OC1920', name: 'TECNICO/A NAVAL'},
    { value: 'OC1921', name: 'TECNICO/A OPTOMETRISTA'},
    { value: 'OC1922', name: 'TECNICO/A ORCAMENTISTA DE OBRAS'},
    { value: 'OC1923', name: 'TECNICO/A ORTOPEDICO'},
    { value: 'OC1924', name: 'TECNICO/A PETROQUIMICO'},
    { value: 'OC1925', name: 'TECNICO/A QUIMICO'},
    { value: 'OC1926', name: 'TECNICO/A TEXTIL'},
    { value: 'OC1927', name: 'TECNOLOGO EM PROCESSOS QUIMICOS'},
    { value: 'OC1928', name: 'TECNOLOGO/A DE EVENTOS'},
    { value: 'OC1929', name: 'TECNOLOGO/A EM ALIMENTOS'},
    { value: 'OC1930', name: 'TECNOLOGO/A EM BANCO DE DADOS'},
    { value: 'OC1931', name: 'TECNOLOGO/A EM CONSTRUCAO CIVIL'},
    { value: 'OC1932', name: 'TECNOLOGO/A EM DESIGN DE JOIAS'},
    { value: 'OC1933', name: 'TECNOLOGO/A EM DESIGN DE MODA'},
    { value: 'OC1934', name: 'TECNOLOGO/A EM DESIGN DE MOVEIS'},
    { value: 'OC1935', name: 'TECNOLOGO/A EM DESIGN DE PRODUTOS'},
    { value: 'OC1936', name: 'TECNOLOGO/A EM DESIGN GRAFICO'},
    { value: 'OC1937', name: 'TECNOLOGO/A EM EDIFICACOES'},
    { value: 'OC1938', name: 'TECNOLOGO/A EM ELETRICIDADE'},
    { value: 'OC1939', name: 'TECNOLOGO/A EM ELETRONICA'},
    { value: 'OC1940', name: 'TECNOLOGO/A EM ENOLOGIA'},
    { value: 'OC1941', name: 'TECNOLOGO/A EM GASTRONOMIA'},
    { value: 'OC1942', name: 'TECNOLOGO/A EM GESTAO ADM FINANCEIRA'},
    { value: 'OC1943', name: 'TECNOLOGO/A EM GESTAO AMBIENTAL'},
    { value: 'OC1944', name: 'TECNOLOGO/A EM GESTAO DA TI'},
    { value: 'OC1945', name: 'TECNOLOGO/A EM GESTAO DE SEG EMPRESARIAL'},
    { value: 'OC1946', name: 'TECNOLOGO/A EM GESTAO DE SEGURANCA PRIV'},
    { value: 'OC1947', name: 'TECNOLOGO/A EM GESTAO DE SISTEMA DE INFO'},
    { value: 'OC1948', name: 'TECNOLOGO/A EM GESTAO DE TURISMO'},
    { value: 'OC1949', name: 'TECNOLOGO/A EM GESTAO FINANCEIRA'},
    { value: 'OC1950', name: 'TECNOLOGO/A EM GESTAO PUBLICA'},
    { value: 'OC1951', name: 'TECNOLOGO/A EM HOTELARIA'},
    { value: 'OC1952', name: 'TECNOLOGO/A EM LOGISTICA'},
    { value: 'OC1953', name: 'TECNOLOGO/A EM MECANICA'},
    { value: 'OC1954', name: 'TECNOLOGO/A EM MEIO AMBIENTE'},
    { value: 'OC1955', name: 'TECNOLOGO/A EM METALURGIA'},
    { value: 'OC1956', name: 'TECNOLOGO/A EM PETROLEO E GAS'},
    { value: 'OC1957', name: 'TECNOLOGO/A EM PROCESSAMENTO DE CARNES'},
    { value: 'OC1958', name: 'TECNOLOGO/A EM PROCESSAMENTO DE DADOS'},
    { value: 'OC1960', name: 'TECNOLOGO/A EM PROCESSO DE PROD/USINAGEM'},
    { value: 'OC1959', name: 'TECNOLOGO/A EM PROCESSO DE PRODUCAO'},
    { value: 'OC1961', name: 'TECNOLOGO/A EM PROCESSOS AMBIENTAIS'},
    { value: 'OC1962', name: 'TECNOLOGO/A EM PROCESSOS METALURGICOS'},
    { value: 'OC1963', name: 'TECNOLOGO/A EM PRODUCAO AUDIOVISUAL'},
    { value: 'OC1964', name: 'TECNOLOGO/A EM PRODUCAO DE VESTUARIO'},
    { value: 'OC1965', name: 'TECNOLOGO/A EM PRODUCAO FONOGRAFICA'},
    { value: 'OC1966', name: 'TECNOLOGO/A EM PRODUCAO GRAFICA'},
    { value: 'OC1967', name: 'TECNOLOGO/A EM PRODUCAO INDUSTRIAL'},
    { value: 'OC1968', name: 'TECNOLOGO/A EM PRODUCAO JOALHEIRA'},
    { value: 'OC1969', name: 'TECNOLOGO/A EM PRODUCAO MOVELEIRA'},
    { value: 'OC1970', name: 'TECNOLOGO/A EM PRODUCAO MULTIMIDIA'},
    { value: 'OC1971', name: 'TECNOLOGO/A EM PRODUCAO MUSICAL'},
    { value: 'OC1972', name: 'TECNOLOGO/A EM REDES DE COMPUTADORES'},
    { value: 'OC1973', name: 'TECNOLOGO/A EM REDES DE TELECOMUNICACOES'},
    { value: 'OC1974', name: 'TECNOLOGO/A EM SANEAMENTO AMBIENTAL'},
    { value: 'OC1975', name: 'TECNOLOGO/A EM SEGURANCA DA INFORMACAO'},
    { value: 'OC1976', name: 'TECNOLOGO/A EM SEGURANCA DO TRABALHO'},
    { value: 'OC1977', name: 'TECNOLOGO/A EM SISTEMAS DE TELECOM'},
    { value: 'OC1978', name: 'TECNOLOGO/A EM SISTEMAS PARA INTERNET'},
    { value: 'OC1979', name: 'TECNOLOGO/A EM TELECOMUNICACOES'},
    { value: 'OC1980', name: 'TECNOLOGO/A GRAFICO'},
    { value: 'OC1981', name: 'TECNOLOGO/A METALURGISTA'},
    { value: 'OC1982', name: 'TELEFONISTA'},
    { value: 'OC1983', name: 'TELEOPERADOR/A'},
    { value: 'TENE', name: 'TENENTE'},
    { value: 'OC1984', name: 'TENENTE DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'OC1985', name: 'TENENTE-BRIGADEIRO'},
    { value: 'TECO', name: 'TENENTE-CORONEL'},
    { value: 'OC1987', name: 'TENENTE-CORONEL DA POLICIA MILITAR'},
    { value: 'OC1988', name: 'TENENTE-CORONEL DO CORPO DE BOMB MILITAR'},
    { value: 'OC1989', name: 'TENISTA PROFISSIONAL'},
    { value: 'OC1990', name: 'TEOLOGO/A'},
    { value: 'TE', name: 'TERAPEUTA'},
    { value: 'OC1992', name: 'TERAPEUTA ALTERNATIVO'},
    { value: 'OC1993', name: 'TERAPEUTA HOLISTICO'},
    { value: 'OC1994', name: 'TERAPEUTA NATURALISTA'},
    { value: 'OC1995', name: 'TERAPEUTA OCUPACIONAL'},
    { value: 'OC1996', name: 'TERCEIRO-SARGENTO'},
    { value: 'OC1997', name: 'TESOUREIRO/A'},
    { value: 'OC1998', name: 'TINTUREIRO/A'},
    { value: 'OC1999', name: 'TIPOGRAFO/A'},
    { value: 'OC2000', name: 'TOPOGRAFO/A'},
    { value: 'OC2001', name: 'TORNEIRO/A MECANICO'},
    { value: 'OC2002', name: 'TOSADOR/A DE ANIMAIS DOMESTICOS'},
    { value: 'OC2003', name: 'TOSQUIADOR/A'},
    { value: 'MAINT', name: 'TRABALHADOR DE MANUTENCAO'},
    { value: 'OC2004', name: 'TRADUTOR/A'},
    { value: 'OC2005', name: 'TRADUTOR/A DE LIBRAS'},
    { value: 'OC2006', name: 'TRAPEZISTA'},
    { value: 'OC2007', name: 'TRATADOR/A DE ANIMAIS DOMESTICOS'},
    { value: 'OC2008', name: 'TRATADOR/A DE ANIMAIS NO JARDIM ZOO'},
    { value: 'OC2009', name: 'TRATADOR/A DE ANIMAIS SELVAGENS'},
    { value: 'OC2010', name: 'TRATADOR/A DE CAVALOS'},
    { value: 'OC2011', name: 'TRAUMATOLOGISTA'},
    { value: 'OC2012', name: 'TREINADOR/A DE ANIMAIS CIRCENSE'},
    { value: 'OC2013', name: 'TREINADOR/A DE ANIMAIS DOMESTICOS'},
    { value: 'OC2014', name: 'TREINADOR/A DE ANIMAIS SELVAGENS'},
    { value: 'OC2015', name: 'TREINADOR/A DE CAVALOS'},
    { value: 'OC2016', name: 'TREINADOR/A ESPORTIVO'},
    { value: 'OC2017', name: 'ULTRASONOGRAFISTA'},
    { value: 'OC2018', name: 'URBANISTA'},
    { value: 'OC2019', name: 'UROLOGISTA'},
    { value: 'SALES', name: 'VENDEDOR/A'},
    { value: 'OC2021', name: 'VENTRILOQUO/A'},
    { value: 'OC2022', name: 'VEREADOR/A'},
    { value: 'VE', name: 'VETERINARIO/A'},
    { value: 'OC2024', name: 'VICE-ALMIRANTE'},
    { value: 'OC2025', name: 'VICE-GOVERNADOR DO DISTRITO FEDERAL'},
    { value: 'OC2026', name: 'VICE-GOVERNADOR/A DE ESTADO'},
    { value: 'OC2027', name: 'VICE-PREFEITO'},
    { value: 'OC2028', name: 'VICE-PRESIDENTE COMERCIAL'},
    { value: 'OC2029', name: 'VICE-PRESIDENTE DA REPUBLICA'},
    { value: 'OC2030', name: 'VICE-PRESIDENTE DE MARKETING'},
    { value: 'OC2031', name: 'VICE-PRESIDENTE DE VENDAS'},
    { value: 'OC2032', name: 'VIDRACEIRO/A'},
    { value: 'VIGI', name: 'VIGIA'},
    { value: 'OC2034', name: 'VIGIA FLORESTAL'},
    { value: 'OC2035', name: 'VIGIA NOTURNO'},
    { value: 'OC2036', name: 'VIGIA PORTUARIO'},
    { value: 'OC2037', name: 'VIGILANTE'},
    { value: 'VIBA', name: 'VIGILANTE BANCARIO'},
    { value: 'OC2039', name: 'VIGILANTE DE AEROPORTO'},
    { value: 'VIFO', name: 'VIGILANTE DE CARRO-FORTE'},
    { value: 'OC2040', name: 'VIGILANTE DE SAUDE'},
    { value: 'VIPP', name: 'VIGILANTE DE SEGURANCA PESSOAL PRIVADA'},
    { value: 'OC2041', name: 'VINICULTOR'},
    { value: 'OC2042', name: 'VISTORIADOR DE RISCO DE AUTO'},
    { value: 'OC2043', name: 'VISTORIADOR DE SINISTROS'},
    { value: 'OC2044', name: 'VISTORIADOR NAVAL'},
    { value: 'OC2045', name: 'VITRINISTA'},
    { value: 'OC2046', name: 'VOCABULARISTA'},
    { value: 'OC2047', name: 'WEB DESIGNER'},
    { value: 'OC2048', name: 'YOUTUBER'},
    { value: 'OC2049', name: 'ZELADOR/A'},
    { value: 'OC2050', name: 'ZOOLOGISTA'},
    { value: 'OC2051', name: 'ZOOLOGO/A'},
    { value: 'OC2052', name: 'ZOOTECNISTA'}
];
