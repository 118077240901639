import React from 'react';
import DatePicker from "react-datepicker";
import { TEXT_FILLED_CLASS } from '../../../utils/prepare-data';
import ReactHtmlParser from 'react-html-parser';
import "react-datepicker/dist/react-datepicker.css";
import './form-input.styles.scss';

const createAutoCompleteInput = (inputObj) => {
    const { id, name, value, handleChange, values, otherProps } = inputObj;
    const textFilledClass = (value && value.length) ? TEXT_FILLED_CLASS : '';
    const { fi } = otherProps;
    return (
        <React.Fragment>
            <input type='text' id={id} className={`${textFilledClass} form-input`}
                onChange={handleChange} {...otherProps} />
            {
                (values && values.length > 0) && (
                    <ul className="list-unstyled custom-select">
                        {
                            values.map((v, i) => {
                                const dummyObj = {
                                    target: {
                                        name: name,
                                        value: v.name,
                                        selectedIndex: (i + 1)
                                    }
                                }
                                return (<li key={`${name}-${i}`} onClick={(e) => handleChange('click', dummyObj, fi)} >
                                    {v.name}
                                </li>);
                            })
                        }
                    </ul>)
            }
        </React.Fragment>
    );
};

const createDropdownInput = (inputObj) => {
    const { type, id, name, value, handlefocus,
        dropdownitemstatus, values, handleChange,
        jsonvalue, level, otherProps } = inputObj;
    const { fi } = otherProps;
    let showDropdownValues, eleName = name;
    if (typeof fi !== 'undefined') {
        showDropdownValues = dropdownitemstatus[fi] ? dropdownitemstatus[fi].show : false;
        eleName = `${name}-${fi}`;
    } else {
        showDropdownValues = dropdownitemstatus[name].show;
    }

    return (
        <React.Fragment>
            <input type={type} id={id} className={`${value && value.length ? 'text-filled' : ''} form-input custom-dropdown`}
                onFocus={(e) => handlefocus(e, eleName, true)} onBlur={(e) => handlefocus(e, eleName, false)} {...otherProps} />
            <div className={`${(showDropdownValues) ? 'open' : ''}`} onClick={(e) => handlefocus(e, eleName, true)}>
                <div className="arrow"></div>
            </div>
            {
                (showDropdownValues) && (
                    <ul className="list-unstyled custom-select">
                        {
                            values.map((v, i) => (
                                <li key={`${name}-${i}`} onClick={(e) => handleChange(e, eleName, v.value, (i + 1))}
                                    jsonvalue={jsonvalue} level={level} order={i}>
                                    {v.name}
                                </li>
                            ))
                        }
                    </ul>
                )
            }
        </React.Fragment>
    );
};

const createRadioInput = (inputObj) => {
    const { content, name, checked, type, id, handleChange, className, otherProps } = inputObj;
    return (
        <div className="btn-group" data-toggle="buttons">
            {
                content.map((c, i) => (
                    <div>
                        <br />
                        <label key={`${name}-${i}`} className={`freq-radio ${className[i]} ${(checked === content[i] ? 'active' : '')}`}>
                            <input type={type} name={name} id={id[i]} value={content[i]} autoComplete="off"
                                checked={checked === content[i]} onChange={handleChange} {...otherProps} />
                            {content[i]}
                        </label>
                    </div>
                ))
            }
        </div>
    );
}

const createInput = (typeInput, props) => {
    const { handleChange, id, type, ...otherProps } = props;
    const { content, className, checked, name, value, values, handlefocus, dropdownitemstatus, jsonvalue, level } = otherProps;
    switch (typeInput) {
        case 'span':
            return <span {...otherProps}> {content} </span>;
        case 'radio':
            return createRadioInput({ content, name, checked, type, id, handleChange, className, otherProps });
        case 'textarea':
            return (<textarea id={id} className='form-control' style={{ minHeight: '100px' }}></textarea>);
        case 'select':
            return createDropdownInput({ type, id, name, value, handlefocus, dropdownitemstatus, values, handleChange, jsonvalue, level, otherProps });
        case 'date':
            return (
                <DatePicker className={`${value && value.length ? 'text-filled' : ''} form-input`} onChange={handleChange} showMonthDropdown={true}
                    showYearDropdown={true} scrollableYearDropdown={true} {...otherProps} />
            );
        case 'autocomplete':
            return createAutoCompleteInput({ id, name, value, handleChange, values, otherProps });
        default:
            return <input type={type} id={id} className={`${value && value.length ? 'text-filled' : ''} form-input`}
                onChange={handleChange} {...otherProps} />;
    }
};

const FormInput = (props) => {
    const { label, sup, type, id, error, source, reviewvalue, moreclass, language, ...otherProps } = props;
    const errorClass = (error && error.isError) ? 'with-error' : '';
    const lng = (language) ? language : 'PT';
    return (
        <div className="group">
            {
                (label && source !== 'review') && (
                    <React.Fragment>
                        <label className={`${(otherProps.value && otherProps.value.length) ? 'shrink' : ''} form-input-label ${errorClass}`}>
                            {label} {sup && <sup>*</sup>}
                        </label>
                    </React.Fragment>
                )
            }
            {(source !== 'review') && createInput(type, props)}
            {
                label ? (
                    <React.Fragment>
                        {
                            error && error.isError && (
                                <div className="error-message">
                                    {error.msg[lng]}
                                </div>
                            )
                        }
                        {
                            (source === 'review') && (
                                <React.Fragment>
                                    <div className={`col-md-12 review-label`}>
                                        {label}
                                    </div>
                                    <div className={`col-md-12 review-value ${moreclass}`}>
                                        {ReactHtmlParser(reviewvalue)}
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                ) : null
            }
        </div>
    )
};

export default FormInput;
