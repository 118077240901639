import React from 'react';
import './declaration.styles.scss';
import { Link } from "react-router-dom";
import DECLARATION_CONTENT from '../../utils/declaration-content';
import { CONTENT } from '../../utils/page-content';

class Declaration extends React.Component {
    render() {
        return (
            <div className="container declaration-page">
                <h4 className="common-title">{CONTENT[this.props.language].DECLARATION}</h4>
                <div className="declaration-panel">

                    {CONTENT[this.props.language].HEREBY_DECLARE}:
                    {
                        DECLARATION_CONTENT[this.props.language].GENERAL_TERMS.map((text, i) => (
                            <p key={i}>{`${i + 1}. ${text}`}</p>
                        ))
                    }
                </div>

                <div className="row margin-common">
                    <div className="col-md-3">
                        <Link className='option' to={{
                                        pathname: '/newPolicy',
                                        search: `?lang=${this.props.language}`
                                    }}>
                            <button type="button" className="btn btn-submit btn-block btn-common">
                            {CONTENT[this.props.language].BACK_TO_APPLICATION}
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        )
    }
}

export default Declaration;
