import React from 'react';
import { HompePageData } from '../../utils/prepare-data';
import FormInput from '../../components/html-elements/form-input/form-input.component';
import './loginpage.styles.scss';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            test: HompePageData
        }
    }

    componentDidMount = () => {
        this.props.setPage('Login');
    }

    FunName = () => {
        this.props.history.push({
            pathname: '/home',
            search: `?lang=${this.props.language}`
        });

    }

    handleChange = (event) => {
        this.props.handleChange(event);
    }

    render() {
        const { currentUser } = this.props;
        return (
            <div className="container login-page">
                <div className="panel panel-default form-panel">
                    <div className="panel-body">
                        <form className="form-horizontal ">

                            <FormInput type="text" id="UserName" name="UserName" placeholder="User Name"
                                label="User Name" jsonvalue="currentUser,UserName" level="2" maxLenght="20"
                                value={currentUser.UserName} handleChange={this.handleChange} />

                            <FormInput type="password" id="Password" name="Password" placeholder="Password"
                                label="Password" jsonvalue="currentUser,BrokerType" level="2" maxLenght="20"
                                handleChange={this.handleChange} />
                        </form>
                        <div className="row margin-common">
                            <div className="col-md-6">
                                <button type="button" className="btn btn-block btn-next" onClick={this.FunName}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginPage;
