export let ENV = 'dev'
export let DOMAIN = 'https://general.sonic.dev.coherent.com.hk';
export let host = window.location.origin;


if (process.browser) {
  if (window.location.hostname.includes("prudential.com")) {
      ENV = 'prudential';
      DOMAIN = 'https://internal-sg-pii-pob-coherent-res-dev-1604690441.sa-east-1.elb.amazonaws.com';
  }
}

console.log("ENV:"+ENV)
console.log("DOMAIN:"+DOMAIN)

const CONFIG = {
    dev: {
        // Host URL
        serverUrl: 'https://general.sonic.dev.coherent.com.hk/general',
        serverUrl1: 'https://general.sonic.dev.coherent.com.hk/api',
        productUrl: '/newbusiness/GetProducts/all', // Product Call
        createPolicyUrl: '/v2/newbusiness/newpolicy', // to create new policy
        getQuoteUrl: '/newbusiness/DoQuotation',
        paymentGetJWTToken: 'https://api-dev.prudential.com/pob/security/token',
        paymentGetAccessToken: 'https://api-dev.prudential.com/pob/finance/credit-card/tokenization/token',
        prudentialCardCharge: 'https://api-dev.prudential.com/pob/v1/finance/corporate/credit-card-billing-api/credit-card-billings-batch'
    },
    prod: {
        // Host URL
        DOMAIN: process.env.REACT_APP_DOMAIN,
        serverUrl: DOMAIN + '/general',
        serverUrl1: DOMAIN + '/api',
        productUrl: '/newbusiness/GetProducts/all',
        productFunction: 'GetProducts', // Product Call
        createPolicyUrl: DOMAIN + '/general/v2/newbusiness/newpolicy', // to create new policy
        createPolicyFunction: 'NewPolicy',
        getQuoteUrl: '/newbusiness/DoQuotation'
    },
    prudential: {
        // Host URL
        
        serverUrl: host + '/api/general',
        serverUrl1: host + '/api',
        productUrl: '/newbusiness/GetProducts/all',
        productFunction: 'GetProducts', // Product Call
        createPolicyUrl : host + '/api/general/v2/newbusiness/newpolicy', // to create new policy
        createPolicyFunction: 'NewPolicy',
        getQuoteUrl: '/newbusiness/DoQuotation'
    }
};

export default CONFIG;