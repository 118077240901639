import React from "react";
//import { BrlCurrency } from "../../utils/prepare-data";
import FormInput from "../../components/html-elements/form-input/form-input.component";
import "./homepage.styles.scss";
import "../../assets/bootstrap.min.css";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Products: {
        Tropical: {
          SumInsured: []
        },
        Funeral: {
          SumInsured: []
        }
      },
      DropdownItems: {
        'TropicalDisease:TropicalDisease_v2.Plan1': { show: false },
        'TropicalDisease:TropicalDisease_v2.Plan2': { show: false },
        'TropicalDisease:TropicalDisease_v2.Plan3': { show: false },
        'Funeral:Funeral_v3.Individual': { show: false },
        'Funeral:Funeral_v3.Family1': { show: false },
        'Funeral:Funeral_v3.Family2': { show: false },
        'Funeral:Funeral_v3.FamilyOpen': { show: false }
      },
      HomePage: {
        'TropicalDisease:TropicalDisease_v2': {
          Plan1: '',
          Plan2: '',
          Plan3: ''
        },
        'Funeral:Funeral_v3': {
          Individual: '',
          Family1: '',
          Family2: '',
          FamilyOpen: ''
        }
      },
      indexMap: {
        TropicalMapping :{
          "TropicalDisease:TropicalDisease_v2.Plan1": {
            '1500': '40,65',
            '3000': '81,31',
            '4500': '121,96'
          },
          "TropicalDisease:TropicalDisease_v2.Plan2": {
            '1500': '81,31',
            '3000': '162,62',
            '4500': '243,92'
          },
          "TropicalDisease:TropicalDisease_v2.Plan3": {
            '1500': '203,27',
            '3000': '406,54',
            '4500': '609,81'
          }
         }
      },
    };
  }

  componentDidMount = () => {
    this.props.setPage("Home");
    setTimeout(() => {
      this.createData();
    }, 2000)
  };

  createData = () => {
    const { formData } = this.props;
    const newState = { ...this.state };
    if (formData.products.length > 0) {
      newState.Products.Tropical = formData.products[1];
      newState.Products.Funeral = formData.products[0];
      this.setState(newState);
    }
  }


  handleDropdownClick = (e, ele, act) => {
    try {
      e.preventDefault();
      setTimeout(() => {
        const newState = { ...this.state };
        if (ele.includes('Relationship')) {
          newState.DropdownItems.Relationship[ele.split('-')[1]].show = act;
        } else if (ele.includes('GenderF')) {
          newState.DropdownItems.GenderF[ele.split('-')[1]].show = act;
        } else {
          newState.DropdownItems[ele].show = act;
        }
        this.setState(newState);
      }, 200);
    } catch (e) {
      console.log('Error in dropdown select ', e.message);
    }

  }

  handleValueSelect = (e, name, value, selectedIndex) => {
    try {
      this.handleDropdownClick(e, name, false);
      const newState = { ...this.state };
      const nm = name.split('.');
      newState.HomePage[nm[0]][nm[1]] = value;
      newState.indexMap[name] = selectedIndex;
      this.setState(newState);
    } catch (e) {
      console.log('error in assigning the value', e.message);
    }
  }

  selectPlan = (product, productIndex, plan, planIndex, sumValue) => {
    const eventObj = {
      target: {
        name: "Product",
        value: product,
        selectedIndex: parseInt(productIndex) + 1,
      },
    };
    const addtionalObj = {
      index: `${productIndex}`,
      jsonvalue: "Product",
      level: "1",
    };
    this.props.handleChange(eventObj, addtionalObj);
    const planEventObj = {
      target: {
        name: "Plan",
        value: plan,
        selectedIndex: parseInt(planIndex) + 1,
      },
    };
    const planAdditionalObj = {
      index: `${planIndex}`,
      jsonvalue: "Plan",
      level: "1",
    };
    setTimeout(() => {
      this.props.handleChange(planEventObj, planAdditionalObj);
      if (product === 'Funeral:Funeral_v3') {
        this.props.history.push({
          pathname: "/newPolicy",
          search: `?lang=${this.props.language}`,
        });
      }
    }, 0);
    if (product !== 'Funeral:Funeral_v3') {
      const SumInsuredEventObj = {
        target: {
          name: "SumInsured",
          value: sumValue,
          selectedIndex: this.state.indexMap[`${product}.${plan}`],
        },
      };
      const SumInsuredAdditionalObj = {
        index: `${planIndex}`,
        jsonvalue: "SumInsured",
        level: "1",
      };
      setTimeout(() => {
        this.props.handleChange(SumInsuredEventObj, SumInsuredAdditionalObj);
        this.props.history.push({
          pathname: "/newPolicy",
          search: `?lang=${this.props.language}`,
        });
      }, 200);
    }
  };

  handleChange = (event) => {
    this.props.handleChange(event);
  };

  gotoClaim = () => {
    this.props.history.push({
      pathname: "/initiateClaim",
      search: `?lang=${this.props.language}`,
    });
  }

  render() {
    // const { Products, HomePage, DropdownItems } = this.state;
    const { currentUser } = this.props;
    return (
      <div className="container home-page">
        <div className="panel panel-default form-panel">
          <div className="panel-body">
            <form className="form-horizontal col-md-6">
              <FormInput
                type="text"
                id="Broker"
                name="Broker"
                placeholder="Agent Number"
                label="Agent Number"
                jsonvalue="Broker"
                level="1"
                maxLenght="20"
                value={currentUser.Broker}
                handleChange={this.handleChange}
              />

              <FormInput
                type="text"
                id="BrokerType"
                name="BrokerType"
                placeholder="Channel"
                label="Channel"
                jsonvalue="BrokerType"
                level="1"
                maxLenght="20"
                value={currentUser.BrokerType}
                handleChange={this.handleChange}
              />
            </form>
          </div>
        </div>

        <div className="panel panel-default form-panel">

        <div className="panel-body">
            <div className="product-heading">
              <h3 className="panel-title">Escolha o produto</h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Dread Disease - DD</h3>
                      <div className="caption1">
                        <p><br /></p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('DD:DD', 0, '', 0, '')}
                        >
                        Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Doenças tropicais</h3>
                      <div className="caption1">
                      <p><br /></p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('TropicalDisease:TropicalDisease_v2', 2, 'Plan1', 1)} >
                        Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Proteção Familiar</h3>
                      <div className="caption1">
                      <p><br /></p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('Funeral:Funeral_v3', 1, 'Individual', 1)} >
                          Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="panel-body">
            <div className="product-heading">
              <h3 className="panel-title">Doenças tropicais</h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Individual</h3>
                      <div className="btn-group col-md-12 tropical-dropdown">
                        <FormInput type="select" id="TropicalDisease:TropicalDisease_v2.Plan1" name="TropicalDisease:TropicalDisease_v2.Plan1" label="" 
                          placeholder="Capital Segurado" values={Products.Tropical.SumInsured}
                          handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['TropicalDisease:TropicalDisease_v2'].Plan1)}
                          handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                      </div>
                      <div className="caption1">
                        <p>A Partir de R$ {this.state.indexMap.TropicalMapping['TropicalDisease:TropicalDisease_v2.Plan1'][HomePage['TropicalDisease:TropicalDisease_v2'].Plan1]}/mes</p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('TropicalDisease:TropicalDisease_v2', 1, 'Plan1', 1, HomePage['TropicalDisease:TropicalDisease_v2'].Plan1)}
                        >
                        Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Casal</h3>
                      <div className="btn-group col-md-12 tropical-dropdown">
                        <FormInput type="select" id="TropicalDisease:TropicalDisease_v2.Plan2" name="TropicalDisease:TropicalDisease_v2.Plan2" label=""
                          placeholder="Capital Segurado" values={Products.Tropical.SumInsured}
                          handleChange={this.handleValueSelect} 
                          value={BrlCurrency(HomePage['TropicalDisease:TropicalDisease_v2'].Plan2)}
                          handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                      </div>
                      <div className="caption1">
                      <p>A Partir de R$ {this.state.indexMap.TropicalMapping['TropicalDisease:TropicalDisease_v2.Plan2'][HomePage['TropicalDisease:TropicalDisease_v2'].Plan2]}/mes</p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('TropicalDisease:TropicalDisease_v2', 1, 'Plan2', 2, HomePage['TropicalDisease:TropicalDisease_v2'].Plan2)}
                        >
                        Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="thumbnail product-select">
                    <div className="caption">
                      <h3>Familiar</h3>
                      <div className="btn-group col-md-12 tropical-dropdown">
                        <FormInput type="select" id="TropicalDisease:TropicalDisease_v2.Plan3" name="TropicalDisease:TropicalDisease_v2.Plan3"
                          label="" placeholder="Capital Segurado" values={Products.Tropical.SumInsured}
                          handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['TropicalDisease:TropicalDisease_v2'].Plan3)}
                          handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                      </div>
                      <div className="caption1">
                      <p>A Partir de R$ {this.state.indexMap.TropicalMapping['TropicalDisease:TropicalDisease_v2.Plan3'][HomePage['TropicalDisease:TropicalDisease_v2'].Plan3]}/mes</p>
                      </div>
                      <div className="row margin-common">
                        <button
                          type="button"
                          className="btn btn-block btn-next" onClick={() => this.selectPlan('TropicalDisease:TropicalDisease_v2', 1, 'Plan3', 3, HomePage['TropicalDisease:TropicalDisease_v2'].Plan3)}
                        >
                          Contratar 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="panel panel-default form-panel">
            <div className="panel-body">
              <div className="product-heading">
                <h3 className="panel-title">Proteção Familiar</h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-3">
                    <div className="thumbnail product-select">
                      <div className="caption">
                        <h3>Individual</h3>
                        <div className="btn-group col-md-12 funeral-dropdown">
                          <FormInput type="select" id="Funeral:Funeral_v3.Individual" name="Funeral:Funeral_v3.Individual" label="" 
                            placeholder="Capital Segurado" values={Products.Funeral.SumInsured}
                            handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['Funeral:Funeral_v3'].Individual)}
                            handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                        </div>
                        <div className="caption1">
                          <p>A Partir de R$ 25,00/mes</p>
                        </div>
                        <div className="row margin-common">
                          <button
                            type="button"
                            className="btn btn-block btn-next" onClick={() => this.selectPlan('Funeral:Funeral_v3', 0, 'Individual', 1)}
                          >
                            Contratar 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="thumbnail product-select">
                      <div className="caption">
                        <h3>Casal</h3>
                        <div className="btn-group col-md-12 funeral-dropdown">
                          <FormInput type="select" id="Funeral:Funeral_v3.Family1" name="Funeral:Funeral_v3.Family1" label=""
                           placeholder="Capital Segurado" values={Products.Funeral.SumInsured}
                            handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['Funeral:Funeral_v3'].Family1)}
                            handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                        </div>
                        <div className="caption1">
                          <p>A Partir de R$ 50,00/mes</p>
                        </div>
                        <div className="row margin-common">
                          <button
                            type="button"
                            className="btn btn-block btn-next" onClick={() => this.selectPlan('Funeral:Funeral_v3', 0, 'Family1', 2)}
                          >
                            Contratar 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="thumbnail product-select">
                      <div className="caption">
                        <h3>Familiar</h3>

                        <div className="btn-group col-md-12 funeral-dropdown">
                          <FormInput type="select" id="Funeral:Funeral_v3.Family2" name="Funeral:Funeral_v3.Family2"
                            label="" placeholder="Capital Segurado" 
                            values={Products.Funeral.SumInsured}
                            handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['Funeral:Funeral_v3'].Family2)}
                            handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                        </div>
                        <div className="caption1">
                          <p>A Partir de R$ 75,00/mes</p>
                        </div>
                        <div className="row margin-common">
                          <button
                            type="button"
                            className="btn btn-block btn-next" onClick={() => this.selectPlan('Funeral:Funeral_v3', 0, 'Family2', 3)}
                          >
                            Contratar 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="thumbnail product-select">
                      <div className="caption">
                        <h3>Familiar Extensivo</h3>

                        <div className="btn-group col-md-12 funeral-dropdown">
                          <FormInput type="select" id="Funeral:Funeral_v3.FamilyOpen" name="Funeral:Funeral_v3.FamilyOpen" label=""
                            placeholder="Capital Segurado" values={Products.Funeral.SumInsured}
                            handleChange={this.handleValueSelect} value={BrlCurrency(HomePage['Funeral:Funeral_v3'].FamilyOpen)}
                            handlefocus={this.handleDropdownClick} dropdownitemstatus={DropdownItems} />
                        </div>
                        <div className="caption1">
                          <p>A Partir de R$ 100,00/mes</p>
                        </div>
                        <div className="row margin-common">
                          <button
                            type="button"
                            className="btn btn-block btn-next" onClick={() => this.selectPlan('Funeral:Funeral_v3', 0, 'FamilyOpen', 4)}
                          >
                            Contratar 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="panel panel-default form-panel">
            <div className="panel-body">

              <div className="row">
                <div className="col-md-12">
                  <div className="claim-container">
                    <div className="thumbnail product-select">
                      <div className="caption">
                        <h3>Iniciar reivindicação</h3>
                        <div className="row margin-common">
                          <button type="button"
                            className="btn btn-block btn-next claim-button" onClick={this.gotoClaim} >
                            Iniciar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
