import React from 'react';
import FormInput from '../../components/html-elements/form-input/form-input.component';
import { CONTENT } from '../../utils/page-content';
import { PaymentValidationData } from '../../utils/validation-data';
import { BrlCurrency, DDValues, PAGE_CONST } from '../../utils/prepare-data';
import './confirm-payment.styles.scss';

class ConfirmPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentMode: 'creditCard',
            DropdownItems: {
                PaymentDay: { show: false }
            },
            BankName: '',
            BankAgnecy: '',
            CheckingAccount: '',
            CardNumber: '',
            Validity: '',
            PaymentFreq: '',
            submitClicked: false,
            validateFields: {
                Bank: ['BankName', 'BankAgnecy', 'CheckingAccount', 'PaymentDay'],
                creditCard: ['CardNumber', 'Validity'],
            },
            errors: PaymentValidationData
        }
    }

    componentDidMount = () => {
        this.props.setPage('policyPayment');
    }

    paymentSelected = mode => {
        const { updatePaymentMethod } = this.props;
        this.setState({ paymentMode: mode });
        updatePaymentMethod((mode === 'Bank' ? 'DirectDebit' : 'CreditCard'));
        return false;
    }

    handleCreatePolicySubmit = () => {
        try {
            const { currentUser } = this.props;
            const { errors, validateFields, paymentMode } = this.state;
            let formHasError = false;
            const newState = { ...this.state };
            newState.submitClicked = true;
            validateFields[paymentMode] && validateFields[paymentMode].map((field) => {
                errors[paymentMode][field].isError = false;
                const { stateValue, level } = errors[paymentMode][field];
                const jsonPositions = stateValue.split(',');
                let fValue = '';
                switch (level) {
                    case 1:
                        fValue = currentUser[jsonPositions[0]];
                        break;
                    case 2:
                        fValue = currentUser[jsonPositions[0]][jsonPositions[1]];
                        break;
                    case 3:
                        fValue = currentUser[jsonPositions[0]][jsonPositions[1]][jsonPositions[2]];
                        break;
                    default:
                        fValue = currentUser[jsonPositions[0]];
                }
                let isError = this.validateData(errors[paymentMode][field], fValue);
                if (field === 'Validity' && !isError) {
                    isError = this.validateDate(this.state.errors[paymentMode][field], fValue);
                }
                if (isError) {
                    formHasError = isError;
                }
                errors[paymentMode][field].isError = isError;
                return true;
            });
            const isFreqError = this.checkPaymentFreq();
            if (isFreqError) {
                formHasError = isFreqError;
            }
            newState.errors = errors;
            this.setState(newState);
            if (!validateFields[paymentMode]) {
                formHasError = true;
            }
            this.props.handleSubmit(this.getRandomString());
            
            if (!formHasError) {
                // TODO: Call CyberSource API
                // if (payment === successfull) {
                    // show payment reference number and create new policy
                // } else {
                    // change paymentMethod value
                //}
                // this.props.handleSubmit(this.getRandomString()); // This is to call Create NewPolicy API
            }
        } catch (Exception) {
            console.error('Error in on submit validation', Exception.message);
        }
    }

    getRandomString = () => {
        let length = 18;
        let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    validateData = (errObj, value) => {
        const { language = 'PT' } = this.props;
        if (!value) {
            return true;
        }
        if ('checkFor' in errObj) {
            let isValid = true;
            if ('test' in errObj.checkFor) {
                if (!errObj.checkFor.test.test(value)) {
                    isValid = false;
                    errObj.msg[language] = errObj.checkFor.msg[language];
                }
            } else if ('numberOnly' in errObj.checkFor) {
                if (isNaN(value)) {
                    isValid = false;
                    errObj.msg[language] = errObj.checkFor.msg[language];
                }
            }
            return !isValid;
        }
        return false;
    }

    handleBlur = (event, payment) => {
        const { name, value } = event.target;
        const newState = { ...this.state };
        let hasError = this.validateData(this.state.errors[payment][name], value);
        if (name === 'Validity' && !hasError) {
            hasError = this.validateDate(this.state.errors[payment][name], value);
        }
        newState.errors[payment][name].isError = hasError;
        this.setState(newState);
    }

    handleValidityCheck = event => {
        let { value } = event.target;
        if (value.length === 2) {
            value = `${value}/`;
        }
        this.setState({ Validity: value });
        this.props.handleChange(event);
    }

    handleValidityKeyUp = event => {
        const { keyCode } = event;
        let { Validity } = this.state;
        if (keyCode === 8) {
            if (Validity.length === 3) {
                Validity = Validity.replace('/', '');
            }
            this.setState({ Validity });
        }
    }

    validateDate = (errObj, date) => {
        const { language = 'PT' } = this.props;
        const d = date.split('/');
        const date1 = new Date(`${d[1]}-${d[0]}-01`);
        if (Object.prototype.toString.call(date1) === "[object Date]") {
            if (isNaN(date1.getTime())) {
                errObj.msg[language] = errObj.checkMonthYear.msg[language];
                return true;
            } else {
                const today = new Date().getTime();
                const idate = new Date(`${d[1]}-${d[0]}-${new Date().getDate()}`).getTime();
                errObj.msg[language] = errObj.checkExpiry.msg[language];
                return (idate - today) < 0;
            }
        } else {
            return true;
        }
    }

    handleDropdownClick = (e, ele, act) => {
        e.preventDefault();
        setTimeout(() => {
            const newState = { ...this.state };
            newState.DropdownItems[ele].show = act;
            this.setState(newState);
        }, 300);
    }

    handleValueSelect = (e, name, value, selectedIndex) => {
        const dummyObj = {
            target: {
                name,
                value,
                selectedIndex
            }
        };
        const additionalObj = {
            jsonvalue: e.target.getAttribute('jsonvalue'),
            level: e.target.getAttribute('level'),
            index: e.target.getAttribute('order')
        };
        this.handleBlur(dummyObj, this.state.paymentMode);
        this.props.handleChange(dummyObj, additionalObj);
    }

    handleCardNumberCheck = event => {
        let { value } = event.target;
        if (value.length === 4 || value.length === 9 || value.length === 14) {
            value = `${value}-`;
        }
        this.setState({ CardNumber: value });
        this.props.handleChange(event);
    }

    handleCardNumberKeyUp = event => {
        const { keyCode } = event;
        let { CardNumber } = this.state;
        if (keyCode === 8) {
            if (CardNumber.length === 15 || CardNumber.length === 10 || CardNumber.length === 5) {
                CardNumber = CardNumber.replace(/-([^-]*)$/, '$1');
            }
            this.setState({ CardNumber });
            this.props.handleChange(event);
        }
    }

    checkPaymentFreq = () => {
        return !this.state.PaymentFreq;
    }

    onFreqSelect = (e, amt) => {
        const { value } = e.target;
        this.setState({ PaymentFreq: value });
        const additionalObj = {
            jsonvalue: 'PayFreq',
            level: 1
        };
        this.props.handleChange(e, additionalObj);
        this.props.updateOnFreqSelect(amt);
    }

    render() {
        const { validateFields, paymentMode, errors } = this.state;
        // formData, handleChange
        const { currentUserDisplay, currentUser, language } = this.props;
        const _LNG = CONTENT[language];
        let monthlyPremium, annualPremium, singlePremium;
        if (currentUser.Product === PAGE_CONST.FUNERAL) {
             monthlyPremium = currentUserDisplay.Premium + Number(currentUserDisplay.AD.value);
             annualPremium = currentUserDisplay.AnnualPremium + Number(DDValues["1"][currentUserDisplay.AD.value]);
             singlePremium = currentUserDisplay.SinglePremium + Number(DDValues["0"][currentUserDisplay.AD.value]);
        } else {
             monthlyPremium = currentUserDisplay.Premium;
             annualPremium = currentUserDisplay.AnnualPremium;
             singlePremium = currentUserDisplay.SinglePremium
        }
        return (
            <div className="payment-info-form">
                <div className="container-center">
                    <div className="vertical-div">
                        <div className="panel-heading">
                            <h2 className="page-title">{_LNG.PAYMENT_INFORMATION}</h2>
                        </div>

                        <div className="panel panel-default form-panel">
                            <div className="panel-body payment-form">

                                {/* <div className="col-md-12 group">
                                    <label className="frequency-label">{_LNG.FREQUENCY}</label>
                                    <div className="">
                                        <div className="btn-group" data-toggle="buttons">
                                            <label className="freq-radio">
                                                <input type="radio" name="Freqeuncy" id="Freqeuncy" value="12" onChange={(e) => this.onFreqSelect(e, monthlyPremium)} />
                                                {`${BrlCurrency(monthlyPremium)} ${_LNG.MONTHLY}`}
                                            </label> <br />
                                            <label className="freq-radio">
                                                <input type="radio" name="Freqeuncy" id="Freqeuncy" value="1" onChange={(e) => this.onFreqSelect(e, annualPremium)} />
                                                {`${BrlCurrency(annualPremium)} ${_LNG.ANNUAL}`}
                                            </label> <br />
                                            <label className="freq-radio">
                                                <input type="radio" name="Freqeuncy" id="Freqeuncy" value="0" onChange={(e) => this.onFreqSelect(e, singlePremium)} />
                                                {`${BrlCurrency(singlePremium)} ${_LNG.SINGLE_PREMIUM}`}
                                            </label>
                                            {
                                                (this.state.submitClicked && !this.state.PaymentFreq) && (
                                                    <div className="error-message">
                                                        {_LNG.PLEASE_SELECT_FREQ}
                                                    </div>
                                                )
                                            }
                                            {
                                                (this.state.submitClicked && this.state.PaymentFreq && !validateFields[paymentMode]) && (
                                                    <div className="error-message">
                                                        {_LNG.PLEASE_SELECT_PAYMODE}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-md-12 group">
                                    <label className="col-md-12 frequency-label freq-button-title">{_LNG.FORM_OF_PAYMENT}</label>
                                    {/* <div className="col-md-6 payment-buttons">
                                        <button type="button" className={`btn payment-button ${(paymentMode === 'Bank') ? 'active' : ''}`}
                                            onClick={() => this.paymentSelected('Bank')}>{_LNG.AUTOMATIC_DEBIT}</button>
                                    </div> */}
                                    <div className="col-md-12 payment-buttons">
                                        <button type="button" className={`btn payment-button ${(paymentMode === 'creditCard') ? 'active' : ''}`}
                                            onClick={() => this.paymentSelected('creditCard')}>{_LNG.CREDIT_CARD}</button>
                                    </div>
                                </div>

                                <div className="col-md-12 group">
                                    <form className="form-horizontal ">
                                        {/* {
                                            (this.state.paymentMode === 'Bank') && (
                                                <React.Fragment>
                                                    <FormInput type="text" id="BankName" name="BankName" placeholder={_LNG.BANK_P} value={currentUser.PaymentInfo.Bank.BankName}
                                                        label={_LNG.BANK} jsonvalue="PaymentInfo,Bank,BankName" level="3" source="payment" handleChange={handleChange}
                                                        error={errors.Bank.BankName} onBlur={(e) => this.handleBlur(e, 'Bank')} language={language} />

                                                    <FormInput type="text" id="BankAgnecy" name="BankAgnecy" placeholder={_LNG.BANK_AGENCY_P} value={currentUser.PaymentInfo.Bank.BankAgency}
                                                        label={_LNG.BANK_AGENCY} jsonvalue="PaymentInfo,Bank,BankAgency" level="3" source="payment" handleChange={handleChange}
                                                        error={errors.Bank.BankAgnecy} onBlur={(e) => this.handleBlur(e, 'Bank')} language={language} />

                                                    <FormInput type="text" id="CheckingAccount" name="CheckingAccount" placeholder={_LNG.CHECKING_ACCOUNT_P}
                                                        value={currentUser.PaymentInfo.Bank.CheckingAccount} label={_LNG.CHECKING_ACCOUNT} jsonvalue="PaymentInfo,Bank,CheckingAccount"
                                                        level="3" source="payment" handleChange={handleChange} error={errors.Bank.CheckingAccount} onBlur={(e) => this.handleBlur(e, 'Bank')}
                                                        language={language} />

                                                    <FormInput type="select" id="PaymentDay" name="PaymentDay" label={_LNG.PAYMENT_DAY} placeholder={_LNG.PAYMENT_DAY_P}
                                                        value={currentUser.PaymentInfo.Bank.PaymentDay} values={formData.PaymentDay} jsonvalue="PaymentInfo,Bank,PaymentDay"
                                                        level="3" source="payment" dropdownitemstatus={this.state.DropdownItems} handleChange={this.handleValueSelect}
                                                        handlefocus={this.handleDropdownClick} error={errors.Bank.PaymentDay} language={language} />

                                                </React.Fragment>
                                            )
                                        } */}

                                        {
                                            (this.state.paymentMode === 'creditCard') && (
                                                <React.Fragment>
                                                    <FormInput type="text" id="CardNumber" name="CardNumber" placeholder={_LNG.CARD_NUMBER_P}  label={_LNG.CARD_NUMBER} value={this.state.CardNumber}
                                                        jsonvalue="PaymentInfo,creditCard,CardNumber" level="3" source="payment"
                                                        error={errors.creditCard.CardNumber} onBlur={(e) => this.handleBlur(e, 'creditCard')}
                                                        onKeyUp={this.handleCardNumberKeyUp} handleChange={this.handleCardNumberCheck} maxLength="19" language={language} />

                                                    <FormInput type="text" id="Validity" name="Validity" placeholder={_LNG.VALIDITY_P} label={_LNG.VALIDITY} value={this.state.Validity}
                                                        onKeyUp={this.handleValidityKeyUp} handleChange={this.handleValidityCheck} jsonvalue="PaymentInfo,creditCard,Validity" level="3"
                                                        error={errors.creditCard.Validity} onBlur={(e) => this.handleBlur(e, 'creditCard')} maxLength="7" language={language} />
                                                </React.Fragment>
                                            )
                                        }
                                    </form>
                                </div>

                                {/* {
                                    (this.state.showSuccess) && (
                                        <div className="row success-msg success-msg-desktop">
                                            <div className="background-success">
                                                <span className="glyphicon glyphicon-ok-circle success-icon"></span>
                                                <span className="message">
                                                    {_LNG.POLICY_SUCCESS_MESSAGE}
                                                </span>
                                                <div className="dismiss-box">
                                                    <span className="dismiss" onClick={this.onDismiss}>
                                                        Dismiss
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row footer-button">
                    <div className="button-style">
                        <button type="button" className="btn btn-block btn-next" onClick={this.handleCreatePolicySubmit}>{_LNG.CONFIRM_ORDER}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmPayment;
