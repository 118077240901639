import React from 'react';
import './claim.styles.scss';

class Claim extends React.Component {
    componentDidMount = () => {
        this.props.setPage("Home");
    };

    render() {
        return (
            <div className="policy-claim">
                <div className="panel panel-default form-panel">
                    <div className="panel-body">
                        In Progress ...!
                    </div>
                </div>
            </div>
        );
    }
}

export default Claim;
