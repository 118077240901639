export const PROFESSION_LIST_1 = [
    { value: 'OC0001', name: 'ACOMPANHANTE DE IDOSOS'},
    { value: 'OC0002', name: 'ACOUGUEIRO/A'},
    { value: 'OC0003', name: 'ACROBATA'},
    { value: 'OC0004', name: 'ACUPUNTOR/A'},
    { value: 'OC0005', name: 'ADESTRADOR/A DE ANIMAIS SELVAGENS'},
    { value: 'OC0006', name: 'ADESTRADOR/A DE AVES'},
    { value: 'OC0007', name: 'ADESTRADOR/A DE CAES'},
    { value: 'OC0008', name: 'ADESTRADOR/A DE CAVALOS'},
    { value: 'OC0014', name: 'ADM DE CARTEIRA DE CAMBIO E COMERCIO EXT'},
    { value: 'OC0015', name: 'ADM DE CARTEIRAS DE CREDITO E COBRANCA'},
    { value: 'OC0016', name: 'ADM DE CARTEIRAS DE CREDITO IMOBILIARIO'},
    { value: 'OC0018', name: 'ADM DE CONTADORIAS E REGISTROS FISCAIS'},
    { value: 'OC0021', name: 'ADM DE EMPRESAS DE HOSPEDAGEM'},
    { value: 'OC0023', name: 'ADM DE FUNDOS E CARTEIRAS DE INVEST'},
    { value: 'OC0024', name: 'ADM DE INSTITUICOES CULTURAIS'},
    { value: 'OC0029', name: 'ADM DE PEQUENA E MEDIA EMPRESA'},
    { value: 'OC0034', name: 'ADM DE REDE E DE SISTEMAS COMPUTACIONAIS'},
    { value: 'OC0039', name: 'ADM DE SISTEMA OPERACIONAL DE REDE'},
    { value: 'OC0040', name: 'ADM DE SISTEMAS COMPUTACIONAIS'},
    { value: 'OC0042', name: 'ADM DE SISTEMAS OPERACIONAIS DE REDE'},
    { value: 'OC0043', name: 'ADM DE TECNOLOGIA DA INFORMACAO'},
    { value: 'OC0044', name: 'ADM EM SEGURANCA DA INFORMACAO'},
    { value: 'OC0048', name: 'ADM NO COMERCIO DE MERCADORIAS'},
    { value: 'OC0009', name: 'ADMINISTRADOR/A'},
    { value: 'OC0010', name: 'ADMINISTRADOR/A DE AMBULATORIO'},
    { value: 'OC0011', name: 'ADMINISTRADOR/A DE ARQUIVOS'},
    { value: 'OC0012', name: 'ADMINISTRADOR/A DE BANCO DE DADOS'},
    { value: 'OC0013', name: 'ADMINISTRADOR/A DE CAMPING'},
    { value: 'OC0017', name: 'ADMINISTRADOR/A DE COMPRAS'},
    { value: 'OC0019', name: 'ADMINISTRADOR/A DE EDIFICIOS'},
    { value: 'ADMIN', name: 'ADMINISTRADOR/A DE EMPRESAS'},
    { value: 'OC0022', name: 'ADMINISTRADOR/A DE EMPRESAS DE PESCA'},
    { value: 'OC0025', name: 'ADMINISTRADOR/A DE MARKETING'},
    { value: 'OC0026', name: 'ADMINISTRADOR/A DE MATERIAIS'},
    { value: 'OC0027', name: 'ADMINISTRADOR/A DE ORCAMENTO'},
    { value: 'OC0028', name: 'ADMINISTRADOR/A DE PATRIMONIO'},
    { value: 'OC0030', name: 'ADMINISTRADOR/A DE PESSOAL'},
    { value: 'OC0031', name: 'ADMINISTRADOR/A DE PREDIOS'},
    { value: 'OC0032', name: 'ADMINISTRADOR/A DE RECURSOS HUMANOS'},
    { value: 'OC0033', name: 'ADMINISTRADOR/A DE RECURSOS TECNOLOGICOS'},
    { value: 'OC0035', name: 'ADMINISTRADOR/A DE REDES'},
    { value: 'OC0036', name: 'ADMINISTRADOR/A DE REFEITORIO'},
    { value: 'OC0037', name: 'ADMINISTRADOR/A DE RESTAURANTE'},
    { value: 'OC0038', name: 'ADMINISTRADOR/A DE SERVICO DE HOSPEDAGEM'},
    { value: 'OC0041', name: 'ADMINISTRADOR/A DE SISTEMAS OPERACIONAIS'},
    { value: 'OC0045', name: 'ADMINISTRADOR/A FINANCEIRO'},
    { value: 'OC0046', name: 'ADMINISTRADOR/A FLORESTAL'},
    { value: 'OC0047', name: 'ADMINISTRADOR/A HOSPITALAR'},
    { value: 'OC0049', name: 'ADMINISTRADOR/A PAROQUIAL'},
    { value: 'OC0050', name: 'ADMINISTRADOR/A PUBLICO'},
    { value: 'OC0067', name: 'ADV EM DIREITO DE FAMILIA E SUCESSOES'},
    { value: 'OC0073', name: 'ADV EM DIREITOS DA CRIANCA E ADOLESC'},
    { value: 'OC0077', name: 'ADV EM RECURSOS HIDRICOS E MINERAIS'},
    { value: 'LAW', name: 'ADVOGADO/A'},
    { value: 'OC0052', name: 'ADVOGADO/A AEROESPACIAL'},
    { value: 'OC0053', name: 'ADVOGADO/A AGENTE DE PROP INDUSTRIAL'},
    { value: 'OC0054', name: 'ADVOGADO/A AMBIENTALISTA'},
    { value: 'OC0055', name: 'ADVOGADO/A CIVILISTA'},
    { value: 'OC0056', name: 'ADVOGADO/A COMERCIAL'},
    { value: 'OC0057', name: 'ADVOGADO/A CONSTITUCIONALISTA'},
    { value: 'OC0058', name: 'ADVOGADO/A CONTRATUALISTA'},
    { value: 'OC0059', name: 'ADVOGADO/A CRIMINALISTA'},
    { value: 'OC0060', name: 'ADVOGADO/A DA UNIAO'},
    { value: 'OC0061', name: 'ADVOGADO/A DESPORTIVO'},
    { value: 'OC0062', name: 'ADVOGADO/A EM ARBITRAGEM'},
    { value: 'OC0063', name: 'ADVOGADO/A EM AREAS ESPECIAIS'},
    { value: 'OC0064', name: 'ADVOGADO/A EM BIODIREITO'},
    { value: 'OC0065', name: 'ADVOGADO/A EM DIREITO ADMINISTRATIVO'},
    { value: 'OC0066', name: 'ADVOGADO/A EM DIREITO CIVIL'},
    { value: 'OC0068', name: 'ADVOGADO/A EM DIREITO DO TRABALHO'},
    { value: 'OC0069', name: 'ADVOGADO/A EM DIREITO FISCAL'},
    { value: 'OC0070', name: 'ADVOGADO/A EM DIREITO INTERNACIONAL'},
    { value: 'OC0071', name: 'ADVOGADO/A EM DIREITO PENAL'},
    { value: 'OC0072', name: 'ADVOGADO/A EM DIREITO PUBLICO'},
    { value: 'OC0074', name: 'ADVOGADO/A EM DIREITOS DO CONSUMIDOR'},
    { value: 'OC0075', name: 'ADVOGADO/A EM ENERGIA ELETRICA'},
    { value: 'OC0076', name: 'ADVOGADO/A EM PROPRIEDADE INTELECTUAL'},
    { value: 'OC0078', name: 'ADVOGADO/A EM TELECOMUNICACOES'},
    { value: 'OC0079', name: 'ADVOGADO/A EMPRESARIAL'},
    { value: 'OC0080', name: 'ADVOGADO/A GENERALISTA'},
    { value: 'OC0081', name: 'ADVOGADO/A PREVIDENCIARIO'},
    { value: 'OC0082', name: 'ADVOGADO/A TRABALHISTA'},
    { value: 'OC0083', name: 'ADVOGADO/A TRIBUTARISTA'},
    { value: 'OC0084', name: 'AEROMOCA'},
    { value: 'OC0085', name: 'AFINADOR/A DE INSTRUMENTOS MUSICAIS'},
    { value: 'OC0086', name: 'AFINADOR/A DE PIANO'},
    { value: 'OC0087', name: 'AGENCIADOR/A DE CARGAS'},
    { value: 'OC0088', name: 'AGENCIADOR/A DE PROPAGANDA'},
    { value: 'OC0089', name: 'AGENTE ADMINISTRATIVO'},
    { value: 'OC0090', name: 'AGENTE ADMINISTRATIVO SUPERVISOR'},
    { value: 'OC0091', name: 'AGENTE COMUNITARIO DE SAUDE'},
    { value: 'OC0092', name: 'AGENTE DA GUARDA PORTUARIA'},
    { value: 'APRF', name: 'AGENTE DA POLICIA RODOVIARIA FEDERAL'},
    { value: 'OC0093', name: 'AGENTE DE ACAO SOCIAL'},
    { value: 'OC0094', name: 'AGENTE DE CARGA'},
    { value: 'OC0095', name: 'AGENTE DE COLETA CENSO E PESQ AMOSTRAIS'},
    { value: 'OC0096', name: 'AGENTE DE COLETA DE LIXO'},
    { value: 'OC0097', name: 'AGENTE DE COLETA DE LIXO SUPERVISOR'},
    { value: 'OC0098', name: 'AGENTE DE COMERCIO EXTERIOR'},
    { value: 'OC0099', name: 'AGENTE DE DEFESA AMBIENTAL'},
    { value: 'OC0100', name: 'AGENTE DE DEFESA FLORESTAL'},
    { value: 'OC0101', name: 'AGENTE DE DIREITOS AUTORAIS'},
    { value: 'OC0102', name: 'AGENTE DE ESTACAO FERROVIA E METRO'},
    { value: 'OC0103', name: 'AGENTE DE ESTACAO PORTUARIA'},
    { value: 'OC0104', name: 'AGENTE DE FISCALIZACAO AMBIENTAL'},
    { value: 'OC0105', name: 'AGENTE DE HIGIENE E SEGURANCA'},
    { value: 'OC0106', name: 'AGENTE DE INSPECAO DE PESCA'},
    { value: 'OC0107', name: 'AGENTE DE INSPECAO QUALIDADE'},
    { value: 'OC0108', name: 'AGENTE DE INVESTIGACAO DE INCENDIO'},
    { value: 'OC0109', name: 'AGENTE DE INVESTIGACAO PRIVADA'},
    { value: 'OC0110', name: 'AGENTE DE MANOBRA E DOCAGEM'},
    { value: 'OC0111', name: 'AGENTE DE MANUT DE VEICULOS FERROVIARIOS'},
    { value: 'OC0112', name: 'AGENTE DE MICROCREDITO'},
    { value: 'OC0113', name: 'AGENTE DE MICROFINANCAS'},
    { value: 'OC0114', name: 'AGENTE DE NAVIO NO TRANSPORTE MARITIMO'},
    { value: 'OC0115', name: 'AGENTE DE ORGANIZACAO ESCOLAR'},
    { value: 'OC0116', name: 'AGENTE DE PASSAGENS'},
    { value: 'OC0117', name: 'AGENTE DE PASSAGENS AEREAS'},
    { value: 'OC0118', name: 'AGENTE DE PASTORAL'},
    { value: 'OC0119', name: 'AGENTE DE PATIO'},
    { value: 'OC0120', name: 'AGENTE DE PATIO/TERMINAIS FERROVIA/METRO'},
    { value: 'OC0121', name: 'AGENTE DE PESQUISA'},
    { value: 'APFE', name: 'AGENTE DE POLICIA FEDERAL'},
    { value: 'OC0123', name: 'AGENTE DE PORTARIA'},
    { value: 'OC0124', name: 'AGENTE DE PROTECAO DE AEROPORTO'},
    { value: 'OC0125', name: 'AGENTE DE PROTECAO SOCIAL'},
    { value: 'OC0126', name: 'AGENTE DE PROTECAO SOCIAL DE RUA'},
    { value: 'OC0127', name: 'AGENTE DE PUBLICIDADE'},
    { value: 'OC0128', name: 'AGENTE DE RECRUTAMENTO E SELECAO'},
    { value: 'OC0129', name: 'AGENTE DE RELACOES PUBLICAS'},
    { value: 'OC0131', name: 'AGENTE DE RESERVA DE PASSAGEM RODOVIARIA'},
    { value: 'OC0130', name: 'AGENTE DE RESERVA DE PASSAGENS AEREAS'},
    { value: 'OC0132', name: 'AGENTE DE RESERVAS'},
    { value: 'OC0133', name: 'AGENTE DE SANEAMENTO'},
    { value: 'OC0134', name: 'AGENTE DE SAUDE'},
    { value: 'OC0135', name: 'AGENTE DE SAUDE PUBLICA'},
    { value: 'OC0136', name: 'AGENTE DE SEGURANCA'},
    { value: 'OC0137', name: 'AGENTE DE SEGURANCA FERROVIARIA'},
    { value: 'ASPA', name: 'AGENTE DE SEGURANCA PATRIMONIAL'},
    { value: 'OC0138', name: 'AGENTE DE SEGURANCA PENITENCIARIA'},
    { value: 'ASSO', name: 'AGENTE DE SEGURANCA SOCIOEDUCATIVO'},
    { value: 'OC0140', name: 'AGENTE DE TRAFEGO'},
    { value: 'OC0141', name: 'AGENTE DE TRANSITO'},
    { value: 'OC0142', name: 'AGENTE DE TRANSPORTE'},
    { value: 'OC0143', name: 'AGENTE DE TRANSPORTE E TRANSITO'},
    { value: 'OC0144', name: 'AGENTE DE VENDA DE PASSAGENS'},
    { value: 'OC0145', name: 'AGENTE DE VENDAS DE SERVICOS'},
    { value: 'OC0146', name: 'AGENTE DE VIAGENS'},
    { value: 'OC0147', name: 'AGENTE EDUCADOR'},
    { value: 'OC0148', name: 'AGENTE EM RADIO E TELEVISAO'},
    { value: 'AEPR', name: 'AGENTE ESPECIAL POLICIA RODOV. FEDERAL'},
    { value: 'OC0149', name: 'AGENTE FISCAL DE QUALIDADE'},
    { value: 'OC0150', name: 'AGENTE FISCAL METROLOGICO'},
    { value: 'OC0151', name: 'AGENTE FISCAL TEXTIL'},
    { value: 'OC0152', name: 'AGENTE FUNERARIO'},
    { value: 'OC0153', name: 'AGENTE FUNERARIO TANATOPRAXISTA'},
    { value: 'OC0154', name: 'AGENTE INDIGENA DE SANEAMENTO'},
    { value: 'OC0155', name: 'AGENTE INDIGENA DE SAUDE'},
    { value: 'OC0156', name: 'AGENTE OPERACIONAL DE ESTACAO'},
    { value: 'AEPO', name: 'AGENTE OPERACIONAL POLICIA RODOV. FED.'},
    { value: 'AGPE', name: 'AGENTE PENITENCIARIO'},
    { value: 'OC0158', name: 'AGENTE POLICIAL'},
    { value: 'OC0159', name: 'AGENTE PUBLICITARIO'},
    { value: 'OC0160', name: 'AGENTE RODOVIARIO'},
    { value: 'OC0161', name: 'AGENTE SANITARISTA'},
    { value: 'OC0139', name: 'AGENTE SERV/FISC TRANSP COLET E LIC VEIC'},
    { value: 'OC0162', name: 'AGENTE SOCIAL'},
    { value: 'OC0163', name: 'AGENTE TECNICO OP CAPTA?/TRAT/DIST AGUA'},
    { value: 'OC0164', name: 'AGRICULTOR/A'},
    { value: 'OC0165', name: 'AGRIMENSOR/A'},
    { value: 'OC0166', name: 'AGRONOMO/A'},
    { value: 'FARMI', name: 'AGROPECUARISTA'},
    { value: 'OC0168', name: 'AGROTECNICO/A'},
    { value: 'OC0169', name: 'AGUARDENTEIRO/A (FABRICACAO)'},
    { value: 'OC0170', name: 'AJUDANTE DE ACOUGUEIRO'},
    { value: 'OC0171', name: 'AJUDANTE DE BOIADEIRO'},
    { value: 'OC0172', name: 'AJUDANTE DE CABELEIREIRO'},
    { value: 'OC0173', name: 'AJUDANTE DE CARGA E DESCARGA MERCADORIAS'},
    { value: 'OC0174', name: 'AJUDANTE DE CARVOARIA'},
    { value: 'OC0175', name: 'AJUDANTE DE CHURRASQUEIRO'},
    { value: 'OC0176', name: 'AJUDANTE DE CONFECCAO'},
    { value: 'OC0177', name: 'AJUDANTE DE CONFEITEIRO'},
    { value: 'OC0178', name: 'AJUDANTE DE COZINHA'},
    { value: 'OC0179', name: 'AJUDANTE DE DESPACHANTE ADUANEIRO'},
    { value: 'OC0180', name: 'AJUDANTE DE DESPACHANTE ALFANDEGARIO'},
    { value: 'OC0181', name: 'AJUDANTE DE ELETRICISTA'},
    { value: 'OC0182', name: 'AJUDANTE DE FARMACIA'},
    { value: 'OC0183', name: 'AJUDANTE DE FERREIRO'},
    { value: 'OC0184', name: 'AJUDANTE DE LABORATORIO'},
    { value: 'OC0185', name: 'AJUDANTE DE OBRAS'},
    { value: 'OC0186', name: 'AJUDANTE DE OPERACAO PORTUARIA'},
    { value: 'OC0187', name: 'AJUDANTE DE PADEIRO'},
    { value: 'OC0188', name: 'AJUDANTE DE PASTELEIRO'},
    { value: 'OC0189', name: 'AJUDANTE DE PINTOR'},
    { value: 'OC0190', name: 'AJUDANTE DE PIZZAIOLO'},
    { value: 'OC0191', name: 'AJUDANTE DE SERRALHEIRO'},
    { value: 'OC0192', name: 'AJUDANTE, AUXILIAR DE BAR'},
    { value: 'OC0193', name: 'AJUDANTE, AUXILIAR DE LANCHONETE'},
    { value: 'OC0194', name: 'AJUDANTE, AUXILIAR DE MECANICO DE AVIAO'},
    { value: 'OC0195', name: 'ALAMBIQUEIRO'},
    { value: 'OC0196', name: 'ALAMBRADOR NA FABRICACAO DE ARAME'},
    { value: 'OC0197', name: 'ALERGISTA'},
    { value: 'OC0198', name: 'ALERGISTA E IMUNOLOGISTA'},
    { value: 'OC0199', name: 'ALERGOLOGISTA'},
    { value: 'OC0200', name: 'ALFAIATE'},
    { value: 'OC0201', name: 'ALMIRANTE'},
    { value: 'OC0202', name: 'ALMIRANTE DE ESQUADRA'},
    { value: 'OC0203', name: 'ALMOXARIFE'},
    { value: 'OC0204', name: 'AMBULANTE'},
    { value: 'OC0205', name: 'ANALISTA ADMINISTRATIVO'},
    { value: 'OC0206', name: 'ANALISTA AGROINDUSTRIAL'},
    { value: 'OC0207', name: 'ANALISTA AMBIENTAL'},
    { value: 'OC0208', name: 'ANALISTA CONTABIL'},
    { value: 'OC0209', name: 'ANALISTA DE AGRONEGOCIOS'},
    { value: 'OC0210', name: 'ANALISTA DE CAMBIO'},
    { value: 'OC0211', name: 'ANALISTA DE CARGOS E SALARIOS'},
    { value: 'OC0212', name: 'ANALISTA DE COBRANCA'},
    { value: 'OC0213', name: 'ANALISTA DE CONTABILIDADE'},
    { value: 'OC0214', name: 'ANALISTA DE CONTAS'},
    { value: 'OC0215', name: 'ANALISTA DE CONTAS A PAGAR'},
    { value: 'OC0216', name: 'ANALISTA DE CONTROLE DE QUALIDADE'},
    { value: 'OC0217', name: 'ANALISTA DE CONTROLE E GESTAO'},
    { value: 'OC0218', name: 'ANALISTA DE CONTROLE ORCAMENTARIO'},
    { value: 'OC0219', name: 'ANALISTA DE CREDITO'},
    { value: 'OC0220', name: 'ANALISTA DE CUSTOS'},
    { value: 'OC0221', name: 'ANALISTA DE DESENVOLVIMENTO DE SISTEMAS'},
    { value: 'OC0222', name: 'ANALISTA DE DOCUMENTACAO'},
    { value: 'OC0223', name: 'ANALISTA DE ECONOMIA INTERNACIONAL'},
    { value: 'OC0224', name: 'ANALISTA DE ESTUDOS DE MERCADO'},
    { value: 'OC0225', name: 'ANALISTA DE ESTUDOS ECONOMICOS'},
    { value: 'OC0226', name: 'ANALISTA DE EXPORTACAO E IMPORTACAO'},
    { value: 'OC0227', name: 'ANALISTA DE FINANCAS PUBLICAS'},
    { value: 'OC0228', name: 'ANALISTA DE FOLHA DE PAGAMENTO'},
    { value: 'OC0229', name: 'ANALISTA DE FUNDOS DE INVESTIMENTO'},
    { value: 'OC0230', name: 'ANALISTA DE IMPACTOS AMBIENTAIS'},
    { value: 'OC0231', name: 'ANALISTA DE INFORMACOES DE MERCADO'},
    { value: 'OC0232', name: 'ANALISTA DE INTELIGENCIA DE MERCADO'},
    { value: 'OC0233', name: 'ANALISTA DE LAB DE CONTROLE DE QUALIDADE'},
    { value: 'OC0234', name: 'ANALISTA DE LAB EM ANALISES CLINICAS'},
    { value: 'OC0236', name: 'ANALISTA DE LAB QUIMICO PETROLEO'},
    { value: 'OC0235', name: 'ANALISTA DE LABORATORIO QUIMICO'},
    { value: 'OC0237', name: 'ANALISTA DE LEASING'},
    { value: 'OC0238', name: 'ANALISTA DE LOGISTICA DE TRANSPORTE'},
    { value: 'OC0239', name: 'ANALISTA DE LOGISTICA TEC NIVEL MEDIO'},
    { value: 'MKTAN', name: 'ANALISTA DE MARKETING'},
    { value: 'OC0241', name: 'ANALISTA DE MEIO AMBIENTE'},
    { value: 'OC0242', name: 'ANALISTA DE MERCADO AGRICOLA'},
    { value: 'OC0243', name: 'ANALISTA DE MERCADO DE TRABALHO'},
    { value: 'OC0244', name: 'ANALISTA DE MERCADO E PRODUTOS'},
    { value: 'OC0245', name: 'ANALISTA DE MERCADO INDUSTRIAL'},
    { value: 'OC0246', name: 'ANALISTA DE MERCADO INTERNACIONAL'},
    { value: 'OC0247', name: 'ANALISTA DE MERCADOLOGIA'},
    { value: 'OC0248', name: 'ANALISTA DE MICROBIOS'},
    { value: 'BA', name: 'ANALISTA DE NEGOCIOS'},
    { value: 'OC0250', name: 'ANALISTA DE OCUPACOES'},
    { value: 'OC0251', name: 'ANALISTA DE PESQUISA DE MERCADO'},
    { value: 'OC0252', name: 'ANALISTA DE PESQUISA OPERACIONAL'},
    { value: 'OC0253', name: 'ANALISTA DE PLANEJAMENTO E ORCAMENTO'},
    { value: 'OC0254', name: 'ANALISTA DE PLANEJAMENTO FINANCEIRO'},
    { value: 'OC0255', name: 'ANALISTA DE PLANEJAMENTO INDUSTRIAL'},
    { value: 'OC0256', name: 'ANALISTA DE POLITICAS PUBLICAS'},
    { value: 'OC0257', name: 'ANALISTA DE PRODUTO AGROPECUARIO'},
    { value: 'OC0258', name: 'ANALISTA DE PRODUTO INDUSTRIAL'},
    { value: 'OC0259', name: 'ANALISTA DE PRODUTOS BANCARIOS'},
    { value: 'OC0260', name: 'ANALISTA DE PRODUTOS QUIMICOS'},
    { value: 'OC0261', name: 'ANALISTA DE PROJETOS INDUSTRIAIS'},
    { value: 'OC0262', name: 'ANALISTA DE PROJETOS VIARIOS'},
    { value: 'OC0263', name: 'ANALISTA DE RECURSOS HUMANOS'},
    { value: 'OC0264', name: 'ANALISTA DE RECURSOS NATURAIS'},
    { value: 'OC0265', name: 'ANALISTA DE REDE'},
    { value: 'OC0266', name: 'ANALISTA DE REDES E COMUNICACAO DE DADOS'},
    { value: 'OC0267', name: 'ANALISTA DE SEGUROS TECNICO'},
    { value: 'OC0268', name: 'ANALISTA DE SERVICO DE VENDAS'},
    { value: 'OC0269', name: 'ANALISTA DE SINISTROS'},
    { value: 'A', name: 'ANALISTA DE SISTEMAS'},
    { value: 'OC0270', name: 'ANALISTA DE SISTEMAS DE AUTOMACAO'},
    { value: 'OC0271', name: 'ANALISTA DE SISTEMAS INFORMATICA'},
    { value: 'OC0272', name: 'ANALISTA DE SISTEMAS PARA INTERNET'},
    { value: 'OC0273', name: 'ANALISTA DE SISTEMAS WEB'},
    { value: 'OC0274', name: 'ANALISTA DE SUBSCRICAO DE TITULOS'},
    { value: 'OC0275', name: 'ANALISTA DE SUPORTE COMPUTACIONAL'},
    { value: 'OC0276', name: 'ANALISTA DE SUPORTE DE BANCO DE DADOS'},
    { value: 'OC0277', name: 'ANALISTA DE SUPORTE DE SISTEMA'},
    { value: 'OC0278', name: 'ANALISTA DE SUPORTE TECNICO'},
    { value: 'OC0279', name: 'ANALISTA DE TELECOMUNICACAO'},
    { value: 'OC0280', name: 'ANALISTA DE TRAFEGO'},
    { value: 'OC0281', name: 'ANALISTA DE TRANSPORTE EM COMERCIO EXT'},
    { value: 'OC0282', name: 'ANALISTA DE TRANSPORTE MULTIMODAL'},
    { value: 'OC0283', name: 'ANALISTA DE TRANSPORTES E TRANSITO'},
    { value: 'OC0284', name: 'ANALISTA DE TRATAMENTO DE AGUA'},
    { value: 'OC0285', name: 'ANALISTA ECONOMICO'},
    { value: 'OC0286', name: 'ANALISTA ECONOMICO-FINANCEIRO'},
    { value: 'OC0287', name: 'ANALISTA EM SEGURANCA DA INFORMACAO'},
    { value: 'OC0288', name: 'ANALISTA FINANCEIRO'},
    { value: 'OC0289', name: 'ANALISTA FISCAL'},
    { value: 'OC0290', name: 'ANALISTA PSICANALISE'},
    { value: 'OC0291', name: 'ANALISTA QUIMICO'},
    { value: 'OC0292', name: 'ANALISTA QUIMICO PETROLEO'},
    { value: 'OC0293', name: 'ANALISTA TECNICO DE SEGUROS'},
    { value: 'OC0294', name: 'ANALISTA TECNICO DE SINISTROS'},
    { value: 'OC0295', name: 'ANALISTA TRIBUTARIO'},
    { value: 'OC0296', name: 'ANDROLOGISTA'},
    { value: 'OC0297', name: 'ANESTESIOLOGISTA'},
    { value: 'OC0298', name: 'ANESTESISTA'},
    { value: 'OC0299', name: 'ANGIOLOGISTA'},
    { value: 'OC0300', name: 'ANIMADOR/A DE EVENTOS'},
    { value: 'OC0301', name: 'ANTIQUARIO/A'},
    { value: 'OC0302', name: 'ANTROPOLOGO/A'},
    { value: 'OC0303', name: 'APICULTOR/A'},
    { value: 'RETIR', name: 'APOSENTADO/A'},
    { value: 'OC0304', name: 'ARBITRO/A ESPORTIVO'},
    { value: 'OC0305', name: 'ARQUEOLOGO/A'},
    { value: 'ARCHI', name: 'ARQUITETO/A'},
    { value: 'OC0307', name: 'ARQUITETO/A URBANISTA'},
    { value: 'OC0308', name: 'ARQUIVADOR/A'},
    { value: 'OC0309', name: 'ARQUIVISTA'},
    { value: 'OC0310', name: 'ARRUMADOR/A DE HOTEL'},
    { value: 'OC0311', name: 'ARRUMADOR/A DE PRATELEIRAS NO COMERCIO'},
    { value: 'OC0312', name: 'ARTESAO/A'},
    { value: 'OC0313', name: 'ARTISTA DE CIRCO'},
    { value: 'OC0314', name: 'ARTISTA EM ARTES VISUAIS'},
    { value: 'OC0315', name: 'ARTISTA PLASTICO'},
    { value: 'OC0316', name: 'ASCENSORISTA'},
    { value: 'ASPM', name: 'ASPIRANTE'},
    { value: 'OC0317', name: 'ASSESSOR/A DE DIRETORIA'},
    { value: 'OC0318', name: 'ASSESSOR/A DE IMPRENSA'},
    { value: 'OC0319', name: 'ASSESSOR/A DE MICROCREDITO'},
    { value: 'OC0320', name: 'ASSESSOR/A DE MICROFINANCAS'},
    { value: 'OC0321', name: 'ASSESSOR/A DE PRESIDENCIA'},
    { value: 'OC0322', name: 'ASSESSOR/A DE RELACOES PUBLICAS'},
    { value: 'OC0323', name: 'ASSESSOR/A IMOBILIARIO'},
    { value: 'OC0324', name: 'ASSESSOR/A JURIDICO'},
    { value: 'OC0325', name: 'ASSISTENTE ADMINISTRATIVO'},
    { value: 'OC0326', name: 'ASSISTENTE COMERCIAL DE SEGUROS'},
    { value: 'OC0327', name: 'ASSISTENTE DE ADMINISTRACAO'},
    { value: 'OC0328', name: 'ASSISTENTE DE BIBLIOTECA'},
    { value: 'OC0329', name: 'ASSISTENTE DE COBRANCA'},
    { value: 'OC0330', name: 'ASSISTENTE DE CONTABILIDADE INDUSTRIAL'},
    { value: 'OC0331', name: 'ASSISTENTE DE CONTADOR DE CUSTOS'},
    { value: 'OC0332', name: 'ASSISTENTE DE CONTADORIA FISCAL'},
    { value: 'OC0333', name: 'ASSISTENTE DE CONTAS DE SEGUROS'},
    { value: 'OC0334', name: 'ASSISTENTE DE CONTROLADOR DE ORCAMENTO'},
    { value: 'OC0335', name: 'ASSISTENTE DE CONTROLADORIA'},
    { value: 'OC0336', name: 'ASSISTENTE DE CONTROLE ADMINISTRATIVO'},
    { value: 'OC0337', name: 'ASSISTENTE DE COREOGRAFIA'},
    { value: 'OC0338', name: 'ASSISTENTE DE DIRECAO'},
    { value: 'OC0339', name: 'ASSISTENTE DE DIRETORIA'},
    { value: 'OC0340', name: 'ASSISTENTE DE EDITORIAL'},
    { value: 'OC0341', name: 'ASSISTENTE DE ENG CONSTRUCAO CIVIL'},
    { value: 'OC0342', name: 'ASSISTENTE DE ESCRITORIO'},
    { value: 'OC0343', name: 'ASSISTENTE DE ESTACAO METRO E FERROVIA'},
    { value: 'OC0344', name: 'ASSISTENTE DE ESTUDIO - CINEMA E VIDEO'},
    { value: 'OC0345', name: 'ASSISTENTE DE ESTUDIO -GRAVACAO DE AUDIO'},
    { value: 'OC0346', name: 'ASSISTENTE DE FATURAMENTO'},
    { value: 'OC0347', name: 'ASSISTENTE DE FINANCAS'},
    { value: 'OC0348', name: 'ASSISTENTE DE GOVERNANTA'},
    { value: 'OC0349', name: 'ASSISTENTE DE LABORATORIO INDUSTRIAL'},
    { value: 'OC0350', name: 'ASSISTENTE DE PUBLICIDADE'},
    { value: 'OC0351', name: 'ASSISTENTE DE RELACOES PUBLICAS'},
    { value: 'OC0352', name: 'ASSISTENTE DE SEGURANCA'},
    { value: 'OC0353', name: 'ASSISTENTE DE SERVICO DE CONTABILIDADE'},
    { value: 'OC0354', name: 'ASSISTENTE DE TRAFEGO RODOVIARIO'},
    { value: 'OC0355', name: 'ASSISTENTE DE VENDAS'},
    { value: 'OC0356', name: 'ASSISTENTE JURIDICO'},
    { value: 'OC0357', name: 'ASSISTENTE SOCIAL'},
    { value: 'OC0358', name: 'ASSISTENTE TECNICO ADMINISTRATIVO'},
    { value: 'OC0359', name: 'ASTROLOGO/A'},
    { value: 'OC0360', name: 'ASTRONOMO/A'},
    { value: 'OC0361', name: 'ATACADISTA'},
    { value: 'OC0362', name: 'ATENDENTE DE AEROPORTO'},
    { value: 'OC0363', name: 'ATENDENTE DE AGENCIA BANCARIA'},
    { value: 'AMBUL', name: 'ATENDENTE DE AMBULANCIA'},
    { value: 'OC0364', name: 'ATENDENTE DE AMBULATORIO'},
    { value: 'OC0365', name: 'ATENDENTE DE BAR'},
    { value: 'OC0366', name: 'ATENDENTE DE BERCARIO'},
    { value: 'OC0367', name: 'ATENDENTE DE BUFFET'},
    { value: 'OC0368', name: 'ATENDENTE DE CALLCENTER'},
    { value: 'OC0369', name: 'ATENDENTE DE CENTRO CIRURGICO'},
    { value: 'OC0370', name: 'ATENDENTE DE CLINICA DENTARIA'},
    { value: 'OC0371', name: 'ATENDENTE DE CLINICA MEDICA'},
    { value: 'OC0372', name: 'ATENDENTE DE CLINICA VETERINARIA'},
    { value: 'OC0373', name: 'ATENDENTE DE CONSULTORIO MEDICO'},
    { value: 'OC0374', name: 'ATENDENTE DE CONSULTORIO VETERINARIO'},
    { value: 'OC0375', name: 'ATENDENTE DE CRECHE'},
    { value: 'OC0376', name: 'ATENDENTE DE ENFERMAGEM'},
    { value: 'OC0377', name: 'ATENDENTE DE HOSPITAL'},
    { value: 'OC0378', name: 'ATENDENTE DE JUDICIARIO'},
    { value: 'OC0379', name: 'ATENDENTE DE LANCHONETE'},
    { value: 'OC0380', name: 'ATENDENTE DE LAVANDERIA'},
    { value: 'OC0381', name: 'ATENDENTE DE PEDAGIO'},
    { value: 'OC0382', name: 'ATENDENTE DE PORTARIA DE HOTEL'},
    { value: 'OC0383', name: 'ATENDENTE DE POSTO DE GASOLINA'},
    { value: 'OC0384', name: 'ATENDENTE DE SEGURO SAUDE'},
    { value: 'OC0385', name: 'ATENDENTE DE SERVICO DE SAUDE'},
    { value: 'OC0386', name: 'ATENDENTE DE TELEMARKETING'},
    { value: 'OC0387', name: 'ATENDENTE EM AGENCIA DE CORREIOS'},
    { value: 'OC0388', name: 'ATENDENTE FUNERARIO'},
    { value: 'OC0389', name: 'ATENDENTE HOSPITALAR'},
    { value: 'OC0390', name: 'ATENDENTE ODONTOLOGICO'},
    { value: 'ATLE', name: 'ATLETA PROFISSIONAL'},
    { value: 'OC0392', name: 'ATOR / ATRIZ'},
    { value: 'ACTOR', name: 'ATOR/ATRIZ'},
    { value: 'OC0393', name: 'ATUARIO/A'},
    { value: 'AUDIT', name: 'AUDITOR/A'},
    { value: 'OC0394', name: 'AUDITOR/A CONTABIL'},
    { value: 'OC0395', name: 'AUDITOR/A DA PREVIDENCIA SOCIAL'},
    { value: 'OC0396', name: 'AUDITOR/A DA RECEITA FEDERAL'},
    { value: 'OC0397', name: 'AUDITOR/A DO TRABALHO'},
    { value: 'OC0398', name: 'AUDITOR/A FINANCEIRO'},
    { value: 'OC0399', name: 'AUDITOR/A FISCAL'},
    { value: 'OC0400', name: 'AUDITOR/A INDEPENDENTE'},
    { value: 'OC0401', name: 'AUDITOR/A INTERNO'},
    { value: 'AUTON', name: 'AUTONOMO/A'},
    { value: 'ASSIS', name: 'AUXILIAR ADMINISTRATIVO/A'},
    { value: 'OC0402', name: 'AUXILIAR CONTABIL'},
    { value: 'OC0403', name: 'AUXILIAR DE ADMINISTRACAO'},
    { value: 'OC0404', name: 'AUXILIAR DE ALMOXARIFADO'},
    { value: 'OC0405', name: 'AUXILIAR DE AMBULATORIO'},
    { value: 'OC0406', name: 'AUXILIAR DE BIBLIOTECA'},
    { value: 'OC0407', name: 'AUXILIAR DE CABELEIREIRO'},
    { value: 'OC0408', name: 'AUXILIAR DE CARTORIO'},
    { value: 'OC0409', name: 'AUXILIAR DE COBRANCA'},
    { value: 'OC0410', name: 'AUXILIAR DE COMPRAS'},
    { value: 'OC0411', name: 'AUXILIAR DE CONFECCAO'},
    { value: 'OC0412', name: 'AUXILIAR DE CONFEITARIA'},
    { value: 'OC0413', name: 'AUXILIAR DE CONFEITEIRO'},
    { value: 'OC0414', name: 'AUXILIAR DE CONTABILIDADE'},
    { value: 'OC0415', name: 'AUXILIAR DE CONTAS A PAGAR'},
    { value: 'OC0416', name: 'AUXILIAR DE CONTAS A RECEBER'},
    { value: 'OC0417', name: 'AUXILIAR DE COORDENADOR ESCOLAR'},
    { value: 'OC0418', name: 'AUXILIAR DE COSTURA'},
    { value: 'OC0419', name: 'AUXILIAR DE COZINHA'},
    { value: 'OC0420', name: 'AUXILIAR DE CRECHE'},
    { value: 'OC0421', name: 'AUXILIAR DE CREDITO'},
    { value: 'OC0422', name: 'AUXILIAR DE DENTISTA'},
    { value: 'OC0423', name: 'AUXILIAR DE DESENVOLVIMENTO INFANTIL'},
    { value: 'OC0424', name: 'AUXILIAR DE ENFERMAGEM'},
    { value: 'CLERK', name: 'AUXILIAR DE ESCRITORIO'},
    { value: 'OC0426', name: 'AUXILIAR DE ESTATISTICA'},
    { value: 'OC0427', name: 'AUXILIAR DE ESTETICA'},
    { value: 'OC0428', name: 'AUXILIAR DE ESTOQUE'},
    { value: 'OC0429', name: 'AUXILIAR DE EXPORTACAO E IMPORTACAO'},
    { value: 'OC0430', name: 'AUXILIAR DE FARMACIA DE MANIPULACAO'},
    { value: 'OC0431', name: 'AUXILIAR DE FATURAMENTO'},
    { value: 'OC0432', name: 'AUXILIAR DE FUNERARIA'},
    { value: 'OC0433', name: 'AUXILIAR DE JUDICIARIO'},
    { value: 'OC0434', name: 'AUXILIAR DE LABORATORIO'},
    { value: 'OC0435', name: 'AUXILIAR DE LAVANDERIA'},
    { value: 'OC0436', name: 'AUXILIAR DE LIMPEZA'},
    { value: 'OC0437', name: 'AUXILIAR DE LINHA DE PRODUCAO'},
    { value: 'OC0438', name: 'AUXILIAR DE MARCENEIRO'},
    { value: 'OC0439', name: 'AUXILIAR DE MECANICO DE AUTOS'},
    { value: 'OC0440', name: 'AUXILIAR DE NUTRICAO E DIETETICA'},
    { value: 'OC0441', name: 'AUXILIAR DE OBSTETRICIA'},
    { value: 'OC0442', name: 'AUXILIAR DE OFTALMOLOGIA'},
    { value: 'OC0443', name: 'AUXILIAR DE ORIENTACAO EDUCACIONAL'},
    { value: 'OC0444', name: 'AUXILIAR DE ORIENTACAO PEDAGOGICA'},
    { value: 'OC0445', name: 'AUXILIAR DE PADEIRO'},
    { value: 'OC0446', name: 'AUXILIAR DE PEDREIRO'},
    { value: 'OC0447', name: 'AUXILIAR DE PESSOAL'},
    { value: 'OC0448', name: 'AUXILIAR DE RECEPCAO'},
    { value: 'OC0449', name: 'AUXILIAR DE RELACOES PUBLICAS'},
    { value: 'OC0450', name: 'AUXILIAR DE SEGURANCA'},
    { value: 'OC0451', name: 'AUXILIAR DE SEGUROS'},
    { value: 'OC0452', name: 'AUXILIAR DE SERVICO DE COPA'},
    { value: 'OC0453', name: 'AUXILIAR DE TRAFEGO'},
    { value: 'OC0454', name: 'AUXILIAR DE VETERINARIO'},
    { value: 'OC0455', name: 'AUXILIAR FINANCEIRO'},
    { value: 'OC0456', name: 'AUXILIAR JURIDICO'},
    { value: 'OC0457', name: 'AUXILIAR TECNICO'},
    { value: 'OC0458', name: 'AVALIADOR DE ARTES E ANTIGUIDADES'},
    { value: 'OC0459', name: 'AVALIADOR DE AUTOMOVEIS'},
    { value: 'OC0460', name: 'AVALIADOR DE BENS'},
    { value: 'OC0461', name: 'AVALIADOR DE IMOVEIS'},
    { value: 'OC0462', name: 'AVALIADOR DE JOIAS'},
    { value: 'OC0463', name: 'AVALIADOR JUDICIAL'},
    { value: 'OC0464', name: 'AVICULTOR'},
    { value: 'OC0465', name: 'BABA'},
    { value: 'OC0466', name: 'BACTERIOLOGISTA'},
    { value: 'OC0467', name: 'BAILARINO/A'},
    { value: 'OC0468', name: 'BALCONISTA'},
    { value: 'TELLR', name: 'BANCARIO/A'},
    { value: 'OC0469', name: 'BARBEIRO'},
    { value: 'OC0470', name: 'BARMAN / BARMAID'},
    { value: 'OC0471', name: 'BIBLIOGRAFO/A'},
    { value: 'OC0472', name: 'BIBLIOTECARIO/A'},
    { value: 'OC0473', name: 'BIBLIOTECONOMISTA'},
    { value: 'OC0474', name: 'BILHETEIRO/A DE CINEMA/CIRCO/TEATRO/PARQ'},
    { value: 'OC0475', name: 'BILHETEIRO/A DE TRANSPORTES COLETIVOS'},
    { value: 'OC0476', name: 'BILHETEIRO/A ESTACAO METRO/FERROVIARIA'},
    { value: 'OC0477', name: 'BIOENGENHEIRO/A'},
    { value: 'OC0478', name: 'BIOESTATISTICO/A'},
    { value: 'OC0479', name: 'BIOGRAFO/A'},
    { value: 'OC0480', name: 'BIOLOGISTA'},
    { value: 'BIOL', name: 'BIOLOGO/A'},
    { value: 'OC0482', name: 'BIOMEDICO/A'},
    { value: 'OC0483', name: 'BIOTECNOLOGISTA'},
    { value: 'FIREMAN', name: 'BOMBEIRO (FOGO)'},
    { value: 'BOHI', name: 'BOMBEIRO HIDRAULICO'},
    { value: 'OC0484', name: 'BOMBEIRO/A CIVIL'},
    { value: 'OC0485', name: 'BOMBEIRO/A ELETRICISTA'},
    { value: 'OC0486', name: 'BOMBEIRO/A HIDRAULICO'},
    { value: 'OC0487', name: 'BOMBEIRO/A MILITAR'},
    { value: 'OC0488', name: 'BORRACHEIRO/A'},
    { value: 'OC0489', name: 'BOTANICO/A'},
    { value: 'OC0490', name: 'BOTICARIO/A'},
    { value: 'OC0491', name: 'BRIGADEIRO'},
    { value: 'CABEL', name: 'CABELEIREIRO/A'},
    { value: 'CAPM', name: 'CABO'},
    { value: 'OC0494', name: 'CABO BOMBEIRO MILITAR'},
    { value: 'OC0495', name: 'CABO DA POLICIA MILITAR'},
    { value: 'OC0496', name: 'CAIXA DE BANCO'},
    { value: 'OC0497', name: 'CAIXA DE BAR, LANCHONETES E RESTAURANTES'},
    { value: 'OC0498', name: 'CAIXA DE LOJA'},
    { value: 'OC0499', name: 'CAIXA DE SUPERMERCADO'},
    { value: 'OC0500', name: 'CAIXA LOTERICO'},
    { value: 'OC0501', name: 'CAIXA NO COMERCIO'},
    { value: 'OC0502', name: 'CAIXA NO SERVICO DE ALIMENTACAO'},
    { value: 'OC0503', name: 'CAMAREIRO/A'},
    { value: 'OC0504', name: 'CAMERAMAN'},
    { value: 'OC0505', name: 'CAMINHONEIRO/A'},
    { value: 'OC0506', name: 'CANCEROLOGISTA'},
    { value: 'CANTOR', name: 'CANTOR'},
    { value: 'CAPP', name: 'CAPITAO'},
    { value: 'OC0508', name: 'CAPITAO BOMBEIRO MILITAR'},
    { value: 'OC0509', name: 'CAPITAO DA POLICIA MILITAR'},
    { value: 'CARC', name: 'CARCEREIRO/A'},
    { value: 'OC0511', name: 'CARCINOLOGISTA'},
    { value: 'OC0512', name: 'CARDIOLOGISTA'},
    { value: 'OC0513', name: 'CARICATURISTA'},
    { value: 'OC0514', name: 'CARPINTEIRO/A'},
    { value: 'COURIER', name: 'CARTEIRO'},
    { value: 'OC0515', name: 'CARTEIRO/A'},
    { value: 'OC0516', name: 'CARTOGRAFO/A'},
    { value: 'OC0517', name: 'CARTOMANTE'},
    { value: 'OC0518', name: 'CARTUNISTA'},
    { value: 'OC0519', name: 'CASEIRO/A'},
    { value: 'CATERER', name: 'CATERER'},
    { value: 'OC0520', name: 'CAVALEIRO'},
    { value: 'OC0521', name: 'CENOGRAFO/A'},
    { value: 'OC0522', name: 'CERAMISTA'},
    { value: 'OC0523', name: 'CERVEJEIRO (FABRICACAO)'},
    { value: 'OC0524', name: 'CHAPELEIRO/A'},
    { value: 'OC0525', name: 'CHARGISTA'},
    { value: 'OC0526', name: 'CHAVEIRO/A'},
    { value: 'OC0527', name: 'CHEF (CULINARIA)'},
    { value: 'OC0528', name: 'CHOFER'},
    { value: 'OC0529', name: 'CHURRASQUEIRO/A'},
    { value: 'OC0530', name: 'CIENTISTA POLITICO'},
    { value: 'OC0531', name: 'CIENTISTA SOCIAL'},
    { value: 'OC0532', name: 'CIRURGIAO DENTISTA'},
    { value: 'OC0533', name: 'CIRURGIAO/A'},
    { value: 'OC0534', name: 'CLINICO/A GERAL'},
    { value: 'OC0535', name: 'COLETOR DE LIXO OU DEMAIS RESIDUOS'},
    { value: 'OC0536', name: 'COLOPROCTOLOGISTA'},
    { value: 'OC0537', name: 'COLORISTA (CABELO)'},
    { value: 'COMA', name: 'COMANDANTE'},
    { value: 'OC0539', name: 'COMEDIANTE'},
    { value: 'C', name: 'COMERCIANTE'},
    { value: 'OC0541', name: 'COMISSARIO/A DE BORDO'},
    { value: 'OC0542', name: 'COMISSARIO/A DE POLICIA'},
    { value: 'OC0543', name: 'COMISSARIO/A DE TREM'},
    { value: 'OC0544', name: 'COMISSARIO/A DE VOO'},
    { value: 'OC0545', name: 'COMPOSITOR/A MUSICAL'},
    { value: 'PURC', name: 'COMPRADOR/A'},
    { value: 'OC0547', name: 'COMUNICADOR/A VISUAL'},
    { value: 'OC0548', name: 'CONDUTOR/A DE MAQUINAS'},
    { value: 'OC0549', name: 'CONDUTOR/A DE TAXI'},
    { value: 'OC0550', name: 'CONDUTOR/A DE VEICULO DE CARGA'},
    { value: 'OC0551', name: 'CONFEITEIRO/A'},
    { value: 'OC0552', name: 'CONFERENTE'},
    { value: 'OC0553', name: 'CONSELHEIRO/A TUTELAR'},
    { value: 'OC0554', name: 'CONSTRUTOR/A CIVIL'},
    { value: 'CONSUL', name: 'CONSULTOR/A'},
    { value: 'OC0556', name: 'CONSULTOR/A ADMINISTRATIVO'},
    { value: 'OC0557', name: 'CONSULTOR/A CONTABIL'},
    { value: 'OC0558', name: 'CONSULTOR/A DE COBRANCA'},
    { value: 'OC0559', name: 'CONSULTOR/A DE INFORMACAO'},
    { value: 'OC0560', name: 'CONSULTOR/A DE ORGANIZACAO'},
    { value: 'OC0561', name: 'CONSULTOR/A DE TECNOLOGIA DA INFORMACAO'},
    { value: 'OC0562', name: 'CONSULTOR/A DE VENDAS'},
    { value: 'OC0563', name: 'CONSULTOR/A DE VIAGENS'},
    { value: 'OC0564', name: 'CONSULTOR/A IMOBILIARIO'},
    { value: 'OC0565', name: 'CONSULTOR/A JURIDICO'},
    { value: 'OC0566', name: 'CONTABILISTA'},
    { value: 'ACCOU', name: 'CONTADOR/A'},
    { value: 'OC0568', name: 'CONTADOR/A JUDICIAL'},
    { value: 'OC0569', name: 'CONTATOLOGO/A'},
    { value: 'OC0570', name: 'CONTINUO/A'},
    { value: 'OC0571', name: 'CONTORCIONISTA'},
    { value: 'OC0572', name: 'CONTRA-ALMIRANTE'},
    { value: 'OC0573', name: 'CONTROLADOR/A DE ORCAMENTO'},
    { value: 'OC0574', name: 'CONTROLADOR/A DE PRODUCAO'},
    { value: 'OC0575', name: 'CONTROLADOR/A DE QUALIDADE'},
    { value: 'OC0576', name: 'CONTROLADOR/A DE TRAFEGO'},
    { value: 'OC0577', name: 'CONTROLADOR/A DE TRAFEGO AEREO'},
    { value: 'OC0578', name: 'CONTROLADOR/A DE VOO'},
    { value: 'OC0579', name: 'CONTROLLER'},
    { value: 'OC0580', name: 'COORDENADOR/A'},
    { value: 'OC0581', name: 'COORDENADOR/A ADMINISTRATIVO'},
    { value: 'OC0582', name: 'COORDENADOR/A DE ADM DE PESSOAL'},
    { value: 'OC0583', name: 'COORDENADOR/A DE COBRANCA'},
    { value: 'OC0584', name: 'COORDENADOR/A DE COMPRAS'},
    { value: 'OC0585', name: 'COORDENADOR/A DE CONTABILIDADE'},
    { value: 'OC0586', name: 'COORDENADOR/A DE ENSINO'},
    { value: 'OC0587', name: 'COORDENADOR/A DE EVENTOS'},
    { value: 'OC0588', name: 'COORDENADOR/A DE MICROCREDITO'},
    { value: 'OC0589', name: 'COORDENADOR/A DE OBRAS CONSTRUCAO CIVIL'},
    { value: 'OC0590', name: 'COORDENADOR/A DE OPERACOES PORTUARIAS'},
    { value: 'OC0591', name: 'COORDENADOR/A DE ORIENTACAO PEDAGOGICA'},
    { value: 'OC0592', name: 'COORDENADOR/A DE PROJETOS SOCIAIS'},
    { value: 'OC0593', name: 'COORDENADOR/A DE RH'},
    { value: 'OC0594', name: 'COORDENADOR/A DE SERVICOS DE MANUTENCAO'},
    { value: 'OC0595', name: 'COORDENADOR/A DE TRAFEGO RODOVIARIO'},
    { value: 'OC0596', name: 'COORDENADOR/A DE TURISMO'},
    { value: 'OC0597', name: 'COORDENADOR/A DE VENDAS'},
    { value: 'OC0598', name: 'COORDENADOR/A ESCOLAR'},
    { value: 'OC0599', name: 'COORDENADOR/A EXECUTIVO'},
    { value: 'OC0600', name: 'COORDENADOR/A PEDAGOGICO'},
    { value: 'OC0601', name: 'COPEIRO/A'},
    { value: 'OC0602', name: 'COREOGRAFO/A'},
    { value: 'COPM', name: 'CORONEL'},
    { value: 'OC0604', name: 'CORONEL DA POLICIA MILITAR'},
    { value: 'OC0605', name: 'CORONEL DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'BROKER', name: 'CORRETOR/A'},
    { value: 'OC0606', name: 'CORRETOR/A DE IMOVEIS'},
    { value: 'OC0607', name: 'CORRETOR/A DE MERCADORIAS'},
    { value: 'OC0608', name: 'CORRETOR/A DE MERCADORIAS E FUTUROS'},
    { value: 'OC0609', name: 'CORRETOR/A DE SEGUROS'},
    { value: 'OC0610', name: 'CORRETOR/A DE VALORES - BOLSA DE VALORES'},
    { value: 'OC0611', name: 'COSMOLOGO/A'},
    { value: 'OC0612', name: 'COSTUREIRO/A'},
    { value: 'OC0613', name: 'COVEIRO/A'},
    { value: 'CO', name: 'COZINHEIRO/A'},
    { value: 'OC0615', name: 'CRAVADOR/A DE JOIAS'},
    { value: 'OC0616', name: 'CRAVEJADOR/A DE JOIAS'},
    { value: 'OC0617', name: 'CRAVEJADOR/A DE OURIVESARIA'},
    { value: 'OC0618', name: 'CRIADOR/A DE ANIMAIS'},
    { value: 'OC0619', name: 'CRIMINALISTA'},
    { value: 'OC0620', name: 'CROCHETEIRO, A MAO'},
    { value: 'OC0621', name: 'CROCHETEIRO/A'},
    { value: 'OC0622', name: 'CRONISTA'},
    { value: 'OC0623', name: 'CRONOMETRISTA'},
    { value: 'OC0624', name: 'CUIDADOR DE ANIMAIS'},
    { value: 'OC0625', name: 'CUIDADOR DE CRIANCAS'},
    { value: 'OC0626', name: 'CUIDADOR DE IDOSOS'},
    { value: 'OC0627', name: 'CUIDADOR DE PESSOAS IDOSAS E DEPENDENTES'},
    { value: 'OC0628', name: 'CUMIM'},
    { value: 'OC0629', name: 'DANCARINO/A'},
    { value: 'OC0630', name: 'DATILOGRAFO/A'},
    { value: 'OC0631', name: 'DATILOSCOPISTA/A'},
    { value: 'OC0632', name: 'DECORADOR/A'},
    { value: 'OC0633', name: 'DEDETIZADOR/A'},
    { value: 'OC0634', name: 'DEFENSOR/A PUBLICO'},
    { value: 'OC0635', name: 'DEFENSOR/A PUBLICO ESTADUAL'},
    { value: 'OC0636', name: 'DEFENSOR/A PUBLICO FEDERAL'},
    { value: 'OC0637', name: 'DEGUSTADOR/A DE BEBIDAS'},
    { value: 'DPCI', name: 'DELEGADO'},
    { value: 'DE', name: 'DELEGADO/A'},
    { value: 'OC0638', name: 'DELEGADO/A DE POLICIA'},
    { value: 'OC0639', name: 'DEMOGRAFO/A'},
    { value: 'OC0640', name: 'DEMOLIDOR/A DE EDIFICACOES'},
    { value: 'OC0641', name: 'DEMONSTRADOR/A DE PRODUTOS'},
    { value: 'DENTIST', name: 'DENTISTA'},
    { value: 'OC0643', name: 'DEPILADOR/A'},
    { value: 'OC0644', name: 'DEPUTADO/A ESTADUAL'},
    { value: 'OC0645', name: 'DEPUTADO/A FEDERAL'},
    { value: 'OC0646', name: 'DERMATOLOGISTA'},
    { value: 'OC0647', name: 'DESEMBARGADOR/A'},
    { value: 'NONE', name: 'DESEMPREGADO'},
    { value: 'OC0648', name: 'DESENHISTA'},
    { value: 'OC0649', name: 'DESENHISTA DE PRODUTOS'},
    { value: 'OC0650', name: 'DESENHISTA GRAFICO'},
    { value: 'OC0651', name: 'DESENHISTA INDUSTRIAL'},
    { value: 'OC0652', name: 'DESENHISTA MECANICO'},
    { value: 'OC0653', name: 'DESENHISTA PROJETISTA'},
    { value: 'OC0654', name: 'DESENHISTA TECNICO'},
    { value: 'OC0655', name: 'DESENHISTA TECNICO AERONAUTICO'},
    { value: 'OC0656', name: 'DESENHISTA TEXTIL'},
    { value: 'OC0657', name: 'DESIGNER DE INTERIORES'},
    { value: 'OC0658', name: 'DESIGNER DE VITRINES'},
    { value: 'OC0659', name: 'DESPACHANTE ADUANEIRO'},
    { value: 'OC0660', name: 'DESPACHANTE ALFANDEGARIO'},
    { value: 'OC0661', name: 'DESPACHANTE DE BAGAGENS EM AEROPORTOS'},
    { value: 'OC0662', name: 'DESPACHANTE DE DOCUMENTOS'},
    { value: 'OC0663', name: 'DESPACHANTE DE EMBARQUE'},
    { value: 'OC0664', name: 'DESPACHANTE DE PLATAFORMA PETROLEO'},
    { value: 'OC0665', name: 'DESPACHANTE DE TRAFEGO'},
    { value: 'OC0666', name: 'DESPACHANTE DE TRANSITO'},
    { value: 'OC0667', name: 'DESPACHANTE DE VEICULOS'},
    { value: 'OC0668', name: 'DESPACHANTE DOCUMENTALISTA'},
    { value: 'OC0669', name: 'DESPACHANTE EMPLACADOR'},
    { value: 'OC0670', name: 'DESPACHANTE NOS TRANSPORTES'},
    { value: 'OC0671', name: 'DESPACHANTE OPERACIONAL DE VOO'},
    { value: 'OC0672', name: 'DESPACHANTE POLICIAL'},
    { value: 'DTCI', name: 'DETETIVE'},
    { value: 'OC0673', name: 'DETETIVE DE POLICIA'},
    { value: 'OC0674', name: 'DETETIVE PARTICULAR'},
    { value: 'OC0675', name: 'DETETIVE PROFISSIONAL'},
    { value: 'OC0676', name: 'DETONADOR/A'},
    { value: 'OC0677', name: 'DIAGRAMADOR/A'},
    { value: 'OC0678', name: 'DIARISTA'},
    { value: 'OC0679', name: 'DIGITADOR/A'},
    { value: 'OC0680', name: 'DINAMITADOR/A'},
    { value: 'OC0681', name: 'DIPLOMATA'},
    { value: 'DIRECTOR', name: 'DIRETOR/A'},
    { value: 'OC0683', name: 'DIRETOR/A ADMINISTRATIVO'},
    { value: 'OC0684', name: 'DIRETOR/A ADMINISTRATIVO E FINANCEIRO'},
    { value: 'OC0685', name: 'DIRETOR/A CINEMATOGRAFICO'},
    { value: 'OC0686', name: 'DIRETOR/A CLINICO'},
    { value: 'OC0687', name: 'DIRETOR/A COMERCIAL'},
    { value: 'OC0688', name: 'DIRETOR/A DE ARTE'},
    { value: 'OC0689', name: 'DIRETOR/A DE CENTRO DE ENSINO'},
    { value: 'OC0690', name: 'DIRETOR/A DE COLEGIO'},
    { value: 'OC0691', name: 'DIRETOR/A DE ESCOLA'},
    { value: 'OC0692', name: 'DIRETOR/A DE FINANCAS'},
    { value: 'OC0693', name: 'DIRETOR/A DE FOTOGRAFIA'},
    { value: 'OC0694', name: 'DIRETOR/A DE MARKETING'},
    { value: 'OC0695', name: 'DIRETOR/A DE MERCADO DE CAPITAIS'},
    { value: 'OC0696', name: 'DIRETOR/A DE MUSEU'},
    { value: 'OC0697', name: 'DIRETOR/A DE OBRAS PUBLICA E CIVIL'},
    { value: 'OC0698', name: 'DIRETOR/A DE OPERACOES'},
    { value: 'OC0699', name: 'DIRETOR/A DE PESQUISA'},
    { value: 'OC0700', name: 'DIRETOR/A DE PRODUCAO'},
    { value: 'OC0701', name: 'DIRETOR/A DE PROGRAMAS DE RADIO'},
    { value: 'OC0702', name: 'DIRETOR/A DE PROGRAMAS DE TELEVISAO'},
    { value: 'OC0703', name: 'DIRETOR/A DE RECURSOS HUMANOS'},
    { value: 'OC0704', name: 'DIRETOR/A DE REDACAO'},
    { value: 'OC0705', name: 'DIRETOR/A DE SERVICOS DE INFORMATICA'},
    { value: 'OC0706', name: 'DIRETOR/A DE SERVICOS DE SAUDE'},
    { value: 'OC0707', name: 'DIRETOR/A DE SERVICOS MEDICOS'},
    { value: 'OC0708', name: 'DIRETOR/A DE TECNOLOGIA'},
    { value: 'OC0709', name: 'DIRETOR/A DE TELEVISAO'},
    { value: 'OC0710', name: 'DIRETOR/A DE VENDAS'},
    { value: 'OC0711', name: 'DIRETOR/A FINANCEIRO'},
    { value: 'OC0712', name: 'DIRETOR/A REGENTE DE BATERIA'},
    { value: 'OC0713', name: 'DIRETOR/A REGENTE MUSICAL'},
    { value: 'OC0714', name: 'DIRETOR/A TEATRAL'},
    { value: 'OC0715', name: 'DIRIGENTE DE CLUBE /ASSOCIACAO ESPORTIVA'},
    { value: 'OC0716', name: 'DIRIGENTE DE PARTIDO POLITICO'},
    { value: 'OC0717', name: 'DISTRIBUIDOR/A'},
    { value: 'OC0718', name: 'DISTRIBUIDOR/A DE ALIMENTOS'},
    { value: 'OC0719', name: 'DISTRIBUIDOR/A DE PRODUTOS QUIMICOS'},
    { value: 'OC0720', name: 'DOCEIRO/A'},
    { value: 'OC0721', name: 'DOCENTE'},
    { value: 'OC0722', name: 'DOCUMENTALISTA'},
    { value: 'OC0723', name: 'DOMADOR DE ANIMAIS CIRCENSE'},
    { value: 'OC0724', name: 'DOMADOR DE ANIMAIS DOMESTICOS'},
    { value: 'OC0725', name: 'DOMADOR DE ANIMAIS SELVAGENS'},
    { value: 'OC0726', name: 'DOMADOR DE CAVALOS'},
    { value: 'DOMES', name: 'DOMESTICA'},
    { value: 'DO LAR', name: 'DONA DE CASA / DO LAR'},
    { value: 'BAKER', name: 'DONO DE PADARIA'},
    { value: 'OC0727', name: 'DRAMATURGO/A'},
    { value: 'DUBL', name: 'DUBLE'},
    { value: 'OC0728', name: 'ECOLOGISTA'},
    { value: 'OC0729', name: 'ECOLOGO/A'},
    { value: 'OC0730', name: 'ECONOMETRISTA'},
    { value: 'ECON', name: 'ECONOMISTA'},
    { value: 'OC0732', name: 'EDITOR/A'},
    { value: 'OC0733', name: 'EDITOR/A ASSISTENTE'},
    { value: 'OC0734', name: 'EDITOR/A DE ARTE'},
    { value: 'OC0735', name: 'EDITOR/A DE FOTOGRAFIA'},
    { value: 'OC0736', name: 'EDITOR/A DE IMAGEM'},
    { value: 'OC0737', name: 'EDITOR/A DE JORNAL'},
    { value: 'OC0738', name: 'EDITOR/A DE LIVROS'},
    { value: 'OC0739', name: 'EDITOR/A DE MIDIA ELETRONICA'},
    { value: 'OC0740', name: 'EDITOR/A DE RADIO'},
    { value: 'OC0741', name: 'EDITOR/A DE REVISTA'},
    { value: 'OC0742', name: 'EDITOR/A DE TV E VIDEO'},
    { value: 'OC0743', name: 'EDITOR/A DE WEB'},
    { value: 'OC0744', name: 'EDITOR/A EXECUTIVO'},
    { value: 'OC0745', name: 'EDITORIALISTA'},
    { value: 'OC0746', name: 'EDUCADOR/A FISICO'},
    { value: 'EL', name: 'ELETRICISTA'},
    { value: 'OC0748', name: 'ELETROMECANICO/A'},
    { value: 'OC0749', name: 'ELETROTECNICO/A'},
    { value: 'ENTREPENR', name: 'EMPREENDEDOR'},
    { value: 'OC0750', name: 'EMPREGADO/A DOMESTICO'},
    { value: 'EMPRE', name: 'EMPRESARIO/A'},
    { value: 'OC0752', name: 'ENCANADOR/A'},
    { value: 'OC0753', name: 'ENCICLOPEDISTA'},
    { value: 'OC0754', name: 'ENDOCRINOLOGISTA'},
    { value: 'OC0755', name: 'ENDODONTOLOGO/A'},
    { value: 'OC0756', name: 'ENDOSCOPISTA'},
    { value: 'NURSE', name: 'ENFERMEIRA/O'},
    { value: 'OC0757', name: 'ENFERMEIRO/A'},
    { value: 'ENGEN', name: 'ENGENHEIRO/A'},
    { value: 'OC0759', name: 'ENGENHEIRO/A AERONAUTICO'},
    { value: 'OC0760', name: 'ENGENHEIRO/A AGRICOLA'},
    { value: 'OC0761', name: 'ENGENHEIRO/A AGRIMENSOR'},
    { value: 'OC0762', name: 'ENGENHEIRO/A AGRONOMO'},
    { value: 'OC0763', name: 'ENGENHEIRO/A AMBIENTAL'},
    { value: 'OC0764', name: 'ENGENHEIRO/A AQUICULTOR'},
    { value: 'OC0765', name: 'ENGENHEIRO/A ARQUITETO'},
    { value: 'OC0766', name: 'ENGENHEIRO/A CARTOGRAFO'},
    { value: 'CENGE', name: 'ENGENHEIRO/A CIVIL'},
    { value: 'OC0768', name: 'ENGENHEIRO/A DE ALIMENTOS'}
];
