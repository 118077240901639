const DECLARATION_CONTENT = {
    EN: {
        GENERAL_TERMS: [
            // 1.
            'The answers to all the above questions are complete, true and accurate and are given to the best of my knowledge and belief;',
            // 2.
            'The answers to the above questions are given for the purposes of this Application for Life Insurance and will be relied upon' +
            ' in connection with any matters arising out of any policy issued pursuant to this application. These answers, together with' +
            ' any other statements which may subsequently be made to PRUDENTIAL BRAZIL Life Insurance Company Limited (the "Company" )or' +
            ' to the medical examiner for the Company for the purposes stated herein shall form the basis and become a part of such policy;',
            // 3.
            'I have the duty to immediately inform the Company and correct the answers to the above questions if they have become incomplete,' +
            ' untrue and inaccurate subsequently before any policy is issued;',
            // 4.
            'Any personal data concerning myself or the insured(if different)collected and held by the company may be used, stored, disclosed and transferred(whether' +
            ' within or outside Brazil)to such individual/organizations associated with the Company., These include reinsurers, claims investigators, industry' +
            ' associations/federations for the purposes of (i)underwriting and evaluating this application; (ii)dealing with any matters arising from any policy issued' +
            ' pursuant to this application; (iii) providing all services related to this application;(iv)any promotion of financial products and services by the company and its' +
            ' affiliated companies and(v)communicating with me or the Insured(if different)for such purposes;',
            // 5.
            'Any payment made in connection with this application does not guarantee immediate approval of the coverage applied for. The insurance coverage applied' +
            ' for shall only take effect when the relevant policy has been issued and the initial premium paid(including any additional initial premium payable due to' +
            ' revisions of the policy terms and conditions)[during my or the lnsured\'s(if different)life time and whilst I and or the Insured(if different)is in good health];',
            // 6.
            'I understand that I have or the Insured(if different)has the right to request access to and, following such access, to request correction(if appropriate)of any' +
            ' personal information concerning myself or the Insured(if different)held by the company or be given reasons for any refusal of access. I also understand that' +
            ' the Company has the right to charge a reasonable fee for process of any access.[Note: Any request for access or correction can be made in writing or' +
            ' addressed to the Customer Service Department at 1/F., PRUDENTIAL BRAZIL Financial Centre, 308 Saint Road, Rio, Brazil.]',
            // 7.
            'I have the full authority from and consent of the Insured to provide the information requested on this Application for Life Insurance and to make the' +
            ' declarations, agreements and authorizations herein on behalf of the Insured.',
            // 8.
            'I confirm this Application for Life Insurance is signed in Brazil.',
            // 9.
            'I/We am/are'
        ]
    },
    PT: {
        GENERAL_TERMS: [
            // 1.
            'As respostas a todas as perguntas acima são completas, verdadeiras e precisas e são fornecidas de acordo com o meu conhecimento e crença;',
            // 2.
            'As respostas às perguntas acima são fornecidas para os fins desta Solicitação de Seguro de Vida e serão consideradas' +
            ' em relação a quaisquer questões decorrentes de qualquer política emitida de acordo com esta aplicação. Essas respostas, junto com' +
            ' quaisquer outras declarações que possam ser feitas posteriormente à PRUDENTIAL BRAZIL Life Insurance Company Limited (a "Empresa") ou' +
            ' ao médico legista da Empresa, para os fins aqui estabelecidos, constituirá a base e fará parte dessa política;',
            // 3.
            'Tenho o dever de informar imediatamente a Empresa e corrigir as respostas às perguntas acima, caso se tenham tornado incompletas,' +
            ' falso e impreciso posteriormente, antes de qualquer apólice ser emitida;',
            // 4.
            'Quaisquer dados pessoais relativos a mim ou ao segurado (se diferente) coletados e mantidos pela empresa podem ser usados, armazenados, divulgados e transferidos (quer' +
            ' dentro ou fora do Brasil) para tais indivíduos / organizações associadas à Empresa., Estes incluem resseguradoras, investigadores de sinistros, indústria' +
            ' associações / federações com o objetivo de (i) subscrever e avaliar este pedido; (ii) lidar com quaisquer questões decorrentes de qualquer política emitida' +
            ' de acordo com este pedido; (iii) fornecer todos os serviços relacionados a este aplicativo; (iv) qualquer promoção de produtos e serviços financeiros pela empresa e seus' +
            ' empresas afiliadas e (v) comunicar-se comigo ou com o Segurado (se diferente) para tais fins;',
            // 5.
            'Qualquer pagamento feito em conexão com esta aplicação não garante a aprovação imediata da cobertura solicitada. A cobertura de seguro aplicada' +
            ' pois só terá efeito quando a apólice relevante tiver sido emitida e o prêmio inicial pago (incluindo qualquer prêmio inicial adicional devido a' +
            ' revisões dos termos e condições da apólice) [durante a minha vida ou a do segurado (diferente) e enquanto eu e ou o segurado (se diferente) estivermos de boa saúde];',
            // 6.
            'Eu entendo que tenho ou o Segurado (se diferente) tem o direito de solicitar acesso e, após tal acesso, solicitar correção (se apropriado) de qualquer' +
            ' informações pessoais relativas a mim ou ao Segurado (se diferente) detidas pela empresa ou a justificar qualquer recusa de acesso. Eu também entendo que' +
            ' a Empresa tem o direito de cobrar uma taxa razoável para o processo de qualquer acesso. [Nota: Qualquer pedido de acesso ou correção pode ser feito por escrito ou' +
            ' dirigido ao Departamento de Atendimento ao Cliente em 1 / F., PRUDENTIAL BRAZIL Financial Center, 308 Saint Road, Rio, Brasil.]',
            // 7.
            'Tenho total autoridade e consentimento do segurado para fornecer as informações solicitadas neste Formulário de inscrição para seguro de vida e para fazer o' +
            ' declarações, acordos e autorizações aqui em nome do Segurado.',
            // 8.
            'Confirmo que este Formulário de Pedido de Seguro de Vida foi assinado no Brasil.',
            // 9.
            'Eu / nós somos / somos'
        ]
    }
};

export default DECLARATION_CONTENT;
