export const PROFESSION_LIST_2 = [
    { value: 'OC0769', name: 'ENGENHEIRO/A DE AUTOMACAO'},
    { value: 'OC0770', name: 'ENGENHEIRO/A DE MATERIAIS'},
    { value: 'OC0771', name: 'ENGENHEIRO/A DE MINERACAO'},
    { value: 'OC0772', name: 'ENGENHEIRO/A DE PROCESSOS'},
    { value: 'OC0773', name: 'ENGENHEIRO/A DE PRODUCAO'},
    { value: 'OC0774', name: 'ENGENHEIRO/A DE SISTEMAS COMPUTACIONAIS'},
    { value: 'OC0775', name: 'ENGENHEIRO/A DE SISTEMAS DE COMUNICACAO'},
    { value: 'OC0776', name: 'ENGENHEIRO/A DE SOFTWARE'},
    { value: 'OC0777', name: 'ENGENHEIRO/A DE SOM'},
    { value: 'OC0778', name: 'ENGENHEIRO/A DE TELECOMUNICACOES'},
    { value: 'OC0779', name: 'ENGENHEIRO/A DE TRAFEGO'},
    { value: 'OC0780', name: 'ENGENHEIRO/A DE TRANSMISSAO'},
    { value: 'OC0781', name: 'ENGENHEIRO/A DE TRANSPORTES'},
    { value: 'OC0782', name: 'ENGENHEIRO/A ELETRICISTA'},
    { value: 'OC0783', name: 'ENGENHEIRO/A ELETRICO'},
    { value: 'OC0784', name: 'ENGENHEIRO/A ELETRONICO'},
    { value: 'OC0785', name: 'ENGENHEIRO/A ELETROTECNICO'},
    { value: 'OC0786', name: 'ENGENHEIRO/A FLORESTAL'},
    { value: 'OC0787', name: 'ENGENHEIRO/A MECANICO'},
    { value: 'OC0788', name: 'ENGENHEIRO/A MECATRONICO'},
    { value: 'OC0789', name: 'ENGENHEIRO/A METALURGICO'},
    { value: 'OC0790', name: 'ENGENHEIRO/A NAVAL'},
    { value: 'OC0791', name: 'ENGENHEIRO/A ORCAMENTISTA'},
    { value: 'OC0792', name: 'ENGENHEIRO/A PROJETISTA'},
    { value: 'OC0793', name: 'ENGENHEIRO/A QUIMICO'},
    { value: 'OC0794', name: 'ENGENHEIRO/A RODOVIARIO'},
    { value: 'OC0795', name: 'ENGENHEIRO/A SANITARISTA'},
    { value: 'OC0796', name: 'ENOLOGO/A'},
    { value: 'OC0797', name: 'ENOTECNICO/A'},
    { value: 'OC0798', name: 'ENTOMOLOGISTA'},
    { value: 'OC0799', name: 'ENTOMOLOGO/A'},
    { value: 'OC0800', name: 'ENTREVISTADOR/A CENSO E PESQ AMOSTRAL'},
    { value: 'OC0801', name: 'ENTREVISTADOR/A DE CAMPO'},
    { value: 'OC0802', name: 'ENTREVISTADOR/A DE PESQ DE OPIN E MIDIA'},
    { value: 'OC0803', name: 'ENTREVISTADOR/A DE PESQUISAS DE MERCADO'},
    { value: 'OC0804', name: 'ENTREVISTADOR/A DE PRECOS'},
    { value: 'OC0805', name: 'EPIDEMIOLOGISTA'},
    { value: 'OC0806', name: 'EQUILIBRISTA'},
    { value: 'OC0807', name: 'ESCREVENTE'},
    { value: 'OC0808', name: 'ESCRITOR/A'},
    { value: 'OC0809', name: 'ESCRITURARIO/A'},
    { value: 'ESPC', name: 'ESCRIVAO'},
    { value: 'OC0810', name: 'ESCRIVAO/A CIVIL'},
    { value: 'OC0811', name: 'ESCRIVAO/A CRIMINAL'},
    { value: 'OC0812', name: 'ESCRIVAO/A DE CARTORIO'},
    { value: 'OC0813', name: 'ESCRIVAO/A DE POLICIA'},
    { value: 'OC0814', name: 'ESCRIVAO/A JUDICIAL'},
    { value: 'OC0815', name: 'ESCULTOR/A'},
    { value: 'OC0816', name: 'ESTATISTICO/A'},
    { value: 'OC0817', name: 'ESTENOGRAFO/A'},
    { value: 'OC0818', name: 'ESTENOTIPISTA'},
    { value: 'ESTP', name: 'ESTETICISTA'},
    { value: 'ESTEL', name: 'ESTILISTA'},
    { value: 'OC0820', name: 'ESTILISTA DE MODA'},
    { value: 'OC0821', name: 'ESTOFADOR/A DE MOVEIS'},
    { value: 'OC0822', name: 'ESTOQUISTA'},
    { value: 'ESTUDANTE', name: 'ESTUDANTE'},
    { value: 'EXEC', name: 'EXECUTIVO/A'},
    { value: 'OC0823', name: 'EXPORTADOR/A'},
    { value: 'PHARM', name: 'FARMACEUTICO/A'},
    { value: 'OC0825', name: 'FARMACOLOGISTA'},
    { value: 'OC0826', name: 'FARMACOTECNICO/A'},
    { value: 'OC0827', name: 'FAXINEIRO/A'},
    { value: 'FARM-PROD', name: 'FAZENDEIRO DE AGRICULTURA'},
    { value: 'FARM-GRAIN', name: 'FAZENDEIRO DE GRAOS'},
    { value: 'FARM-DAIRY', name: 'FAZENDEIRO DE LATICINIOS'},
    { value: 'FARM-LIVE', name: 'FAZENDEIRO DE PECUARIA'},
    { value: 'OC0828', name: 'FAZENDEIRO/A'},
    { value: 'OC0829', name: 'FEIRANTE'},
    { value: 'OC0830', name: 'FERRAMENTEIRO'},
    { value: 'OC0831', name: 'FERREIRO/A'},
    { value: 'OC0832', name: 'FIGURANTE'},
    { value: 'OC0833', name: 'FILOLOGO/A'},
    { value: 'OC0834', name: 'FILOSOFO/A'},
    { value: 'OC0835', name: 'FISCAL DA PREVIDENCIA SOCIAL'},
    { value: 'OC0836', name: 'FISCAL DA RECEITA FEDERAL'},
    { value: 'OC0837', name: 'FISCAL DE AVIACAO CIVIL'},
    { value: 'OC0838', name: 'FISCAL DE LOJA'},
    { value: 'OC0839', name: 'FISCAL DE OBRAS'},
    { value: 'OC0840', name: 'FISCAL DE ONIBUS'},
    { value: 'OC0841', name: 'FISCAL DE OPERACAO'},
    { value: 'OC0842', name: 'FISCAL DE SEGURANCA'},
    { value: 'OC0843', name: 'FISCAL DE TRAFEGO'},
    { value: 'OC0844', name: 'FISCAL DE TRANSPORTE RODOVIARIO'},
    { value: 'OC0845', name: 'FISCAL DE TRANSPORTES COLETIVOS'},
    { value: 'OC0846', name: 'FISCAL DE TRIBUTOS'},
    { value: 'OC0847', name: 'FISCAL DE VIGILANCIA BANCARIA'},
    { value: 'OC0848', name: 'FISCAL DO MEIO AMBIENTE'},
    { value: 'OC0849', name: 'FISCAL DO TRABALHO'},
    { value: 'OC0850', name: 'FISCAL FLORESTAL'},
    { value: 'OC0851', name: 'FISCAL RODOVIARIO'},
    { value: 'OC0852', name: 'FISIATRA'},
    { value: 'OC0853', name: 'FISICO/A'},
    { value: 'OC0854', name: 'FISICO/A HOSPITALAR'},
    { value: 'OC0855', name: 'FISICO/A MEDICO'},
    { value: 'OC0856', name: 'FISICO/A NUCLEAR'},
    { value: 'OC0857', name: 'FISICOQUIMICO/A'},
    { value: 'FISIO', name: 'FISIOTERAPEUTA'},
    { value: 'OC0859', name: 'FLAVORISTA'},
    { value: 'OC0860', name: 'FLEBOTOMISTA'},
    { value: 'OC0861', name: 'FLORICULTOR/A'},
    { value: 'OC0862', name: 'FLORISTA'},
    { value: 'OC0863', name: 'FONIATRA'},
    { value: 'OC0864', name: 'FONOAUDIOLOGO/A'},
    { value: 'OC0865', name: 'FOTOGRAFO/A'},
    { value: 'OC0866', name: 'FOTOGRAFO/A DE AEROFOTOGRAFIA'},
    { value: 'OC0867', name: 'FRENTISTA'},
    { value: 'FPLP', name: 'FUNCIONARIO DE  PLATAFORMA DE PETROLEO'},
    { value: 'FIPE', name: 'FUNCIONARIO DE INDUSTRIA PETROQUIMICA'},
    { value: 'FICP', name: 'FUNCIONARIO INST. CORRECIONAL/PRESIDIO'},
    { value: 'FUNCPUB', name: 'FUNCIONARIO PUBLICO'},
    { value: 'OC0868', name: 'FUNCIONARIO/A PUBLICO'},
    { value: 'OC0869', name: 'GALVANIZADOR/A'},
    { value: 'OC0870', name: 'GARCOM / GARCONETE'},
    { value: 'GA', name: 'GARCOM/GARCONETE'},
    { value: 'OC0871', name: 'GARI'},
    { value: 'OC0872', name: 'GARIMPEIRO/A'},
    { value: 'OC0873', name: 'GASTROENTEROLOGISTA'},
    { value: 'OC0874', name: 'GASTROLOGO/A'},
    { value: 'OC0875', name: 'GENERAL'},
    { value: 'OC0876', name: 'GENETICISTA'},
    { value: 'OC0877', name: 'GEOFISICO/A'},
    { value: 'OC0878', name: 'GEOGRAFO/A'},
    { value: 'OC0879', name: 'GEOLOGO/A'},
    { value: 'OC0880', name: 'GEOQUIMICO/A'},
    { value: 'MANAG', name: 'GERENTE'},
    { value: 'OC0881', name: 'GERENTE ADMINISTRATIVO'},
    { value: 'CMANA', name: 'GERENTE COMERCIAL'},
    { value: 'OC0883', name: 'GERENTE DE CAMBIO E COMERCIO EXTERIOR'},
    { value: 'OC0884', name: 'GERENTE DE COMPRAS'},
    { value: 'OC0885', name: 'GERENTE DE COMUNICACAO'},
    { value: 'OC0886', name: 'GERENTE DE CONTABILIDADE'},
    { value: 'OC0887', name: 'GERENTE DE CONTAS'},
    { value: 'OC0888', name: 'GERENTE DE CONTRATOS'},
    { value: 'OC0889', name: 'GERENTE DE CREDITO E COBRANCA'},
    { value: 'OC0890', name: 'GERENTE DE CREDITO IMOBILIARIO'},
    { value: 'OC0891', name: 'GERENTE DE CREDITO RURAL'},
    { value: 'OC0892', name: 'GERENTE DE LOJA'},
    { value: 'OC0893', name: 'GERENTE DE MARKETING'},
    { value: 'OC0894', name: 'GERENTE DE MERCADO'},
    { value: 'OC0895', name: 'GERENTE DE OBRAS'},
    { value: 'OC0896', name: 'GERENTE DE OPERACOES'},
    { value: 'OC0897', name: 'GERENTE DE PESQUISAS'},
    { value: 'OC0898', name: 'GERENTE DE PROCESSOS'},
    { value: 'OC0899', name: 'GERENTE DE PRODUCAO'},
    { value: 'OC0900', name: 'GERENTE DE PRODUTOS'},
    { value: 'OC0901', name: 'GERENTE DE PROJETOS'},
    { value: 'OC0902', name: 'GERENTE DE RECURSOS HUMANOS'},
    { value: 'OC0903', name: 'GERENTE DE RELACOES CORPORATIVAS'},
    { value: 'OC0904', name: 'GERENTE DE RELACOES PUBLICAS'},
    { value: 'OC0905', name: 'GERENTE DE RISCOS'},
    { value: 'OC0906', name: 'GERENTE DE SEGUROS'},
    { value: 'OC0907', name: 'GERENTE DE SUPERMERCADO'},
    { value: 'OC0908', name: 'GERENTE DE TECNOLOGIA DA INFORMACAO'},
    { value: 'OC0909', name: 'GERENTE DE TRAFEGO'},
    { value: 'OC0910', name: 'GERENTE DE TRAFEGO RODOVIARIO'},
    { value: 'OC0911', name: 'GERENTE DE TRANSPORTES'},
    { value: 'OC0912', name: 'GERENTE DE TURISMO'},
    { value: 'OC0913', name: 'GERENTE DE VAREJO'},
    { value: 'OC0914', name: 'GERENTE DE VENDAS'},
    { value: 'OC0915', name: 'GERENTE FINANCEIRO'},
    { value: 'OC0916', name: 'GERENTE INDUSTRIAL'},
    { value: 'OC0917', name: 'GERENTE TECNICO'},
    { value: 'OC0918', name: 'GERIATRA'},
    { value: 'OC0919', name: 'GERONTOLOGISTA'},
    { value: 'OC0920', name: 'GESSEIRO'},
    { value: 'OC0921', name: 'GESTOR/A DE DOCUMENTOS'},
    { value: 'OC0922', name: 'GESTOR/A DE EVENTOS'},
    { value: 'OC0923', name: 'GESTOR/A DE RISCOS'},
    { value: 'OC0924', name: 'GESTOR/A EM SEGURANCA'},
    { value: 'OC0925', name: 'GESTOR/A PUBLICO ADMINISTRADOR'},
    { value: 'OC0926', name: 'GINECOLOGISTA'},
    { value: 'OC0927', name: 'GOVERNADOR/A DE ESTADO'},
    { value: 'GO', name: 'GOVERNANTA'},
    { value: 'OC0929', name: 'GUARDA AMBIENTAL'},
    { value: 'OC0930', name: 'GUARDA CIVIL'},
    { value: 'OC0931', name: 'GUARDA DE BANCO'},
    { value: 'OC0932', name: 'GUARDA DE PRESIDIO'},
    { value: 'OC0933', name: 'GUARDA DE SEGURANCA'},
    { value: 'OC0934', name: 'GUARDA DE VIGILANCIA'},
    { value: 'OC0935', name: 'GUARDA FERROVIARIO'},
    { value: 'OC0936', name: 'GUARDA FLORESTAL'},
    { value: 'OC0937', name: 'GUARDA PENITENCIARIO'},
    { value: 'OC0938', name: 'GUARDA PORTUARIO'},
    { value: 'OC0939', name: 'GUARDA VALORES'},
    { value: 'OC0940', name: 'GUARDA VIGIA'},
    { value: 'OC0941', name: 'GUARDA-COSTAS'},
    { value: 'OC0942', name: 'GUARDA-VIDAS'},
    { value: 'OC0943', name: 'GUARITEIRO/A'},
    { value: 'OC0944', name: 'GUIA DE TURISMO'},
    { value: 'OC0945', name: 'GUIA FLORESTAL'},
    { value: 'OC0946', name: 'GUIA INTERPRETE'},
    { value: 'OC0947', name: 'HEBIATRA'},
    { value: 'OC0948', name: 'HEMATOLOGISTA'},
    { value: 'OC0949', name: 'HEMOTERAPEUTA'},
    { value: 'OC0950', name: 'HIDROBIOLOGISTA'},
    { value: 'OC0951', name: 'HIDROGEOLOGO/A'},
    { value: 'OC0952', name: 'HIDROMETRISTA'},
    { value: 'OC0953', name: 'HIDROTECNICO/A'},
    { value: 'OC0954', name: 'HISTOLOGISTA'},
    { value: 'OC0955', name: 'HISTORIADOR/A'},
    { value: 'OC0956', name: 'HOMEOPATA'},
    { value: 'OC0957', name: 'HORTICULTOR/A'},
    { value: 'OC0958', name: 'HUMORISTA'},
    { value: 'OC0959', name: 'ILUSIONISTA'},
    { value: 'OC0960', name: 'IMEDIATO DA MARINHA MERCANTE'},
    { value: 'OC0961', name: 'IMPORTADOR'},
    { value: 'OC0962', name: 'INFECTOLOGISTA'},
    { value: 'OC0963', name: 'INSEMINADOR DE ANIMAIS'},
    { value: 'OC0964', name: 'INSETOLOGISTA'},
    { value: 'IPRF', name: 'INSPETOR'},
    { value: 'OC0965', name: 'INSPETOR/A AGRICOLA'},
    { value: 'OC0966', name: 'INSPETOR/A DE AGENCIA BANCARIA'},
    { value: 'OC0967', name: 'INSPETOR/A DE ALUNOS DE ESCOLA'},
    { value: 'OC0968', name: 'INSPETOR/A DE AUDITORIA'},
    { value: 'OC0969', name: 'INSPETOR/A DE AVARIAS'},
    { value: 'OC0970', name: 'INSPETOR/A DE AVIACAO CIVIL'},
    { value: 'OC0971', name: 'INSPETOR/A DE BORDO'},
    { value: 'OC0972', name: 'INSPETOR/A DE EQUIPAMENTOS'},
    { value: 'OC0973', name: 'INSPETOR/A DE FUNDICAO'},
    { value: 'OC0974', name: 'INSPETOR/A DE GUARDA PORTUARIA'},
    { value: 'OC0975', name: 'INSPETOR/A DE OBRAS'},
    { value: 'OC0976', name: 'INSPETOR/A DE POLICIA'},
    { value: 'OC0977', name: 'INSPETOR/A DE POLICIA RODOVIARIA FEDERAL'},
    { value: 'OC0978', name: 'INSPETOR/A DE PRESIDIO'},
    { value: 'OC0979', name: 'INSPETOR/A DE QUALIDADE'},
    { value: 'OC0980', name: 'INSPETOR/A DE RISCO'},
    { value: 'OC0981', name: 'INSPETOR/A DE SANEAMENTO'},
    { value: 'OC0982', name: 'INSPETOR/A DE SEGURANCA'},
    { value: 'OC0983', name: 'INSPETOR/A DE SINISTROS'},
    { value: 'OC0984', name: 'INSPETOR/A DE SOLDAGEM'},
    { value: 'OC0985', name: 'INSPETOR/A DE VIGILANCIA'},
    { value: 'OC0986', name: 'INSPETOR/A NAVAL'},
    { value: 'OC0987', name: 'INSTRUMENTADOR/A CIRURGICO'},
    { value: 'OC0988', name: 'INSTRUMENTISTA DE AVIOES'},
    { value: 'OC0989', name: 'INSTRUMENTISTA DE LABORATORIO'},
    { value: 'OC0990', name: 'INSTRUMENTISTA DE PRECISAO'},
    { value: 'OC0991', name: 'INSTRUTOR/A DE ANIMAIS'},
    { value: 'OC0992', name: 'INSTRUTOR/A DE AUTOESCOLA'},
    { value: 'OC0993', name: 'INSTRUTOR/A DE AVIACAO'},
    { value: 'OC0994', name: 'INSTRUTOR/A DE IDIOMAS'},
    { value: 'OC0995', name: 'INSTRUTOR/A DE VOO'},
    { value: 'OC0996', name: 'INSTRUTOR/A EDUCACIONAL'},
    { value: 'OC0997', name: 'INSTRUTOR/A NO ENSINO PROFISSIONALIZANTE'},
    { value: 'OC0998', name: 'INTENSIVISTA'},
    { value: 'OC0999', name: 'INTERPRETE'},
    { value: 'OC1000', name: 'INTERPRETE DE LIBRAS'},
    { value: 'IN', name: 'INTERPRETE/TRADUTOR'},
    { value: 'INPC', name: 'INVESTIGADOR'},
    { value: 'OC1001', name: 'INVESTIGADOR/A DE POLICIA'},
    { value: 'OC1002', name: 'INVESTIGADOR/A PARTICULAR'},
    { value: 'OC1003', name: 'JARDINEIRO/A'},
    { value: 'OC1004', name: 'JOALHEIRO/A'},
    { value: 'JOFU', name: 'JOGADOR DE FUTEBOL'},
    { value: 'OC1006', name: 'JOGADOR DE GOLFE PROFISSIONAL'},
    { value: 'OC1007', name: 'JOGADOR DE TENIS PROFISSIONAL'},
    { value: 'OC1008', name: 'JOGADOR/A DE BASQUETE'},
    { value: 'OC1009', name: 'JOGADOR/A DE FUTEBOL'},
    { value: 'OC1010', name: 'JOGADOR/A DE FUTSAL'},
    { value: 'OC1011', name: 'JOGADOR/A DE HANDEBOL'},
    { value: 'OC1012', name: 'JOGADOR/A DE VOLEIBOL'},
    { value: 'OC1013', name: 'JOQUEI'},
    { value: 'OC1014', name: 'JORNALEIRO/A'},
    { value: 'OC1015', name: 'JORNALISTA'},
    { value: 'JOUR', name: 'JORNALISTA/REPORTER'},
    { value: 'OC1017', name: 'JUDOCA'},
    { value: 'OC1018', name: 'JUIZ/A'},
    { value: 'OC1019', name: 'JUIZ/A DE DIREITO'},
    { value: 'OC1020', name: 'JUIZ/A DO TRABALHO'},
    { value: 'OC1021', name: 'JUIZ/A DO TRIBUNAL REGIONAL DO TRABALHO'},
    { value: 'OC1022', name: 'JUIZ/A DO TRIBUNAL REGIONAL FEDERAL'},
    { value: 'OC1023', name: 'JUIZ/A DOS TRIBUNAIS MILITARES ESTADUAIS'},
    { value: 'OC1024', name: 'JUIZ/A ESPORTIVO (ARBITRO)'},
    { value: 'OC1025', name: 'JUIZ/A FEDERAL'},
    { value: 'OC1026', name: 'JURISTA'},
    { value: 'OC1027', name: 'KARATECA'},
    { value: 'OC1028', name: 'LABORATORISTA'},
    { value: 'OC1029', name: 'LANTERNEIRO DE AUTOMOVEIS'},
    { value: 'OC1030', name: 'LANTERNINHA'},
    { value: 'OC1031', name: 'LAVRADOR/A'},
    { value: 'OC1032', name: 'LEGISTA'},
    { value: 'OC1033', name: 'LEILOEIRO/A'},
    { value: 'OC1034', name: 'LEITURISTA'},
    { value: 'OC1035', name: 'LETRISTA'},
    { value: 'OC1036', name: 'LEXICOGRAFO/A'},
    { value: 'OC1037', name: 'LEXICOLOGO/A'},
    { value: 'LIFEP', name: 'LIFE PLANNER'},
    { value: 'OC1038', name: 'LINGUISTA'},
    { value: 'OC1039', name: 'LIVREIRO/A'},
    { value: 'OC1040', name: 'LIXEIRO/A'},
    { value: 'OC1041', name: 'LOCUTOR/A'},
    { value: 'OC1042', name: 'LOJISTA'},
    { value: 'OC1043', name: 'LUTADOR PROFISSIONAL'},
    { value: 'OC1044', name: 'LUTHIER'},
    { value: 'OC1045', name: 'MADEIREIRO/A - COMERCIO'},
    { value: 'OC1046', name: 'MAESTRO/A'},
    { value: 'OC1047', name: 'MAGICO/A'},
    { value: 'OC1048', name: 'MAITRE'},
    { value: 'MAJO', name: 'MAJOR'},
    { value: 'OC1050', name: 'MAJOR DA POLICIA MILITAR'},
    { value: 'OC1051', name: 'MAJOR DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'OC1052', name: 'MAJOR-BRIGADEIRO'},
    { value: 'OC1053', name: 'MALABARISTA'},
    { value: 'OC1054', name: 'MANICURE'},
    { value: 'MANI', name: 'MANICURE/PEDICURE'},
    { value: 'OC1055', name: 'MANOBRADOR'},
    { value: 'OC1056', name: 'MANOBRISTA'},
    { value: 'OC1057', name: 'MAQUEIRO HOSPITALAR'},
    { value: 'OC1058', name: 'MAQUIADOR/A'},
    { value: 'OC1059', name: 'MAQUINISTA'},
    { value: 'OC1060', name: 'MARCENEIRO/A'},
    { value: 'OC1061', name: 'MARECHAL'},
    { value: 'OC1062', name: 'MARINHEIRO/A'},
    { value: 'OC1063', name: 'MARIONETISTA'},
    { value: 'MA', name: 'MASSAGISTA'},
    { value: 'OC1065', name: 'MASSOTERAPEUTA'},
    { value: 'OC1066', name: 'MASTOLOGISTA'},
    { value: 'OC1067', name: 'MATEMATICO/A'},
    { value: 'MECH', name: 'MECANICO'},
    { value: 'OC1068', name: 'MECANICO/A'},
    { value: 'OC1069', name: 'MECANICO/A DE AERONAVES'},
    { value: 'OC1070', name: 'MECANICO/A DE AUTOMOVEIS'},
    { value: 'OC1071', name: 'MEDIADOR ECONOMICO'},
    { value: 'SURGEON', name: 'MEDICO - CIRURGIAO'},
    { value: 'DOCTR', name: 'MEDICO/A'},
    { value: 'OC1073', name: 'MEDICO/A ACUPUNTURISTA'},
    { value: 'OC1074', name: 'MEDICO/A ALERGISTA'},
    { value: 'OC1075', name: 'MEDICO/A ALERGISTA E IMUNOLOGISTA'},
    { value: 'OC1076', name: 'MEDICO/A ALOPATA'},
    { value: 'OC1077', name: 'MEDICO/A ANATOMOPATOLOGISTA'},
    { value: 'OC1078', name: 'MEDICO/A ANESTESIOLOGISTA'},
    { value: 'OC1079', name: 'MEDICO/A ANESTESISTA'},
    { value: 'OC1080', name: 'MEDICO/A ANGIOLOGISTA'},
    { value: 'OC1081', name: 'MEDICO/A ANGIORADIOLOGISTA'},
    { value: 'OC1082', name: 'MEDICO/A CANCEROLOGISTA'},
    { value: 'OC1083', name: 'MEDICO/A CARDIOLOGISTA'},
    { value: 'OC1084', name: 'MEDICO/A CIRURGIAO'},
    { value: 'OC1086', name: 'MEDICO/A CIRURGIAO CARDIOVASCULAR'},
    { value: 'OC1087', name: 'MEDICO/A CIRURGIAO DE CABECA E PESCOCO'},
    { value: 'OC1088', name: 'MEDICO/A CIRURGIAO DE MAO'},
    { value: 'OC1089', name: 'MEDICO/A CIRURGIAO DO APARELHO DIGESTIVO'},
    { value: 'OC1090', name: 'MEDICO/A CIRURGIAO GERAL'},
    { value: 'OC1091', name: 'MEDICO/A CIRURGIAO PEDIATRICO'},
    { value: 'OC1092', name: 'MEDICO/A CIRURGIAO PLASTICO'},
    { value: 'OC1093', name: 'MEDICO/A CIRURGIAO TORACICO'},
    { value: 'OC1094', name: 'MEDICO/A CITOPATOLOGISTA'},
    { value: 'OC1095', name: 'MEDICO/A CLINICO'},
    { value: 'OC1096', name: 'MEDICO/A CLINICO GERAL'},
    { value: 'OC1097', name: 'MEDICO/A DA FAMILIA'},
    { value: 'OC1098', name: 'MEDICO/A DE DOENCA INFECCIOSA/PARASITAS'},
    { value: 'OC1099', name: 'MEDICO/A DE FAMILIA'},
    { value: 'OC1100', name: 'MEDICO/A DE MEDICINA ESPORTIVA'},
    { value: 'OC1101', name: 'MEDICO/A DE SAUDE PUBLICA'},
    { value: 'OC1102', name: 'MEDICO/A DENSITOMETRISTA'},
    { value: 'OC1103', name: 'MEDICO/A DERMATOLOGISTA'},
    { value: 'OC1104', name: 'MEDICO/A DO TRABALHO'},
    { value: 'OC1105', name: 'MEDICO/A DO TRAFEGO'},
    { value: 'OC1106', name: 'MEDICO/A EM DIAGNOSTICO POR IMAGEM'},
    { value: 'OC1107', name: 'MEDICO/A EM ELETROENCEFALOGRAFIA'},
    { value: 'OC1108', name: 'MEDICO/A EM ENDOSCOPIA'},
    { value: 'OC1109', name: 'MEDICO/A EM HEMOTERAPIA'},
    { value: 'OC1110', name: 'MEDICO/A EM MEDICINA DE TRAFEGO'},
    { value: 'OC1111', name: 'MEDICO/A EM MEDICINA INTENSIVA'},
    { value: 'OC1112', name: 'MEDICO/A EM MEDICINA INTERNA'},
    { value: 'OC1113', name: 'MEDICO/A EM MEDICINA NUCLEAR'},
    { value: 'OC1114', name: 'MEDICO/A EM RADIOLOGIA E DIAGN DE IMAGEM'},
    { value: 'OC1115', name: 'MEDICO/A EM RADIOTERAPIA'},
    { value: 'OC1116', name: 'MEDICO/A ENDOCRINOLOGISTA'},
    { value: 'OC1117', name: 'MEDICO/A ENDOCRINOLOGISTA/ METABOLOGISTA'},
    { value: 'OC1118', name: 'MEDICO/A ENDOSCOPISTA'},
    { value: 'OC1119', name: 'MEDICO/A EPIDEMIOLOGISTA'},
    { value: 'OC1120', name: 'MEDICO/A ESPECIALISTA EM CLINICA MEDICA'},
    { value: 'OC1121', name: 'MEDICO/A ESPECIALISTA MEDICINA INTERNA'},
    { value: 'OC1122', name: 'MEDICO/A FISIATRA'},
    { value: 'OC1123', name: 'MEDICO/A FONIATRA'},
    { value: 'OC1124', name: 'MEDICO/A GASTROENTEROLOGISTA'},
    { value: 'OC1125', name: 'MEDICO/A GENERALISTA'},
    { value: 'OC1126', name: 'MEDICO/A GENETICISTA'},
    { value: 'OC1127', name: 'MEDICO/A GERIATRA'},
    { value: 'OC1128', name: 'MEDICO/A GINECOLOGISTA'},
    { value: 'OC1129', name: 'MEDICO/A GINECOLOGISTA E OBSTETRA'},
    { value: 'OC1130', name: 'MEDICO/A HEMATOLOGISTA'},
    { value: 'OC1131', name: 'MEDICO/A HEMOTERAPEUTA'},
    { value: 'OC1132', name: 'MEDICO/A HIGIENISTA'},
    { value: 'OC1133', name: 'MEDICO/A HOMEOPATA'},
    { value: 'OC1134', name: 'MEDICO/A IMUNOLOGISTA'},
    { value: 'OC1135', name: 'MEDICO/A INFECTOLOGISTA'},
    { value: 'OC1136', name: 'MEDICO/A INTERNISTA'},
    { value: 'OC1137', name: 'MEDICO/A LABORATORISTA'},
    { value: 'DOCLE', name: 'MEDICO/A LEGISTA'},
    { value: 'OC1139', name: 'MEDICO/A MASTOLOGISTA'},
    { value: 'OC1140', name: 'MEDICO/A METABOLISTA'},
    { value: 'OC1141', name: 'MEDICO/A MILITAR'},
    { value: 'OC1142', name: 'MEDICO/A NEFROLOGISTA'},
    { value: 'OC1143', name: 'MEDICO/A NEUROCIRURGIAO'},
    { value: 'OC1144', name: 'MEDICO/A NEUROCIRURGIAO PEDIATRICO'},
    { value: 'OC1145', name: 'MEDICO/A NEUROFISIOLOGISTA'},
    { value: 'OC1146', name: 'MEDICO/A NEUROLOGISTA'},
    { value: 'OC1147', name: 'MEDICO/A NEUROPEDIATRA'},
    { value: 'OC1148', name: 'MEDICO/A NEURORADIOLOGISTA'},
    { value: 'OC1149', name: 'MEDICO/A NUCLEAR'},
    { value: 'OC1150', name: 'MEDICO/A NUTROLOGISTA'},
    { value: 'OC1151', name: 'MEDICO/A NUTROLOGO'},
    { value: 'OC1152', name: 'MEDICO/A OBSTETRA'},
    { value: 'OC1153', name: 'MEDICO/A OFTALMOLOGISTA'},
    { value: 'OC1154', name: 'MEDICO/A ONCOLOGISTA'},
    { value: 'OC1155', name: 'MEDICO/A ORTOPEDISTA'},
    { value: 'OC1156', name: 'MEDICO/A ORTOPEDISTA E TRAUMATOLOGISTA'},
    { value: 'OC1157', name: 'MEDICO/A OTORRINOLARINGOLOGISTA'},
    { value: 'OC1158', name: 'MEDICO/A PATOLOGISTA'},
    { value: 'OC1159', name: 'MEDICO/A PATOLOGISTA CLINICO'},
    { value: 'OC1160', name: 'MEDICO/A PEDIATRA'},
    { value: 'OC1161', name: 'MEDICO/A PERITO'},
    { value: 'OC1162', name: 'MEDICO/A PNEUMOLOGISTA'},
    { value: 'OC1163', name: 'MEDICO/A PNEUMOTISIOLOGISTA'},
    { value: 'OC1164', name: 'MEDICO/A PROCTOLOGISTA'},
    { value: 'OC1165', name: 'MEDICO/A PSICANALISTA'},
    { value: 'OC1166', name: 'MEDICO/A PSICOTERAPEUTA'},
    { value: 'OC1167', name: 'MEDICO/A PSIQUIATRA'},
    { value: 'OC1168', name: 'MEDICO/A RADIOLOGISTA'},
    { value: 'OC1169', name: 'MEDICO/A RADIOTERAPEUTA'},
    { value: 'OC1170', name: 'MEDICO/A REUMATOLOGISTA'},
    { value: 'OC1171', name: 'MEDICO/A SANITARISTA'},
    { value: 'OC1172', name: 'MEDICO/A TRAUMATOLOGISTA'},
    { value: 'OC1173', name: 'MEDICO/A UROLOGISTA'},
    { value: 'OC1174', name: 'MEDICO/A VETERINARIO'},
    { value: 'OC1175', name: 'MERGULHADOR'},
    { value: 'MERG', name: 'MERGULHADOR PROFISSIONAL'},
    { value: 'OC1176', name: 'MESTRE DE OBRAS'},
    { value: 'OC1178', name: 'METABOLISTA'},
    { value: 'OC1179', name: 'METABOLOGISTA'},
    { value: 'OC1180', name: 'METEOROLOGISTA'},
    { value: 'OC1181', name: 'METROLOGISTA'},
    { value: 'OC1182', name: 'MICROBIOLOGISTA'},
    { value: 'OC1183', name: 'MICROFONISTA'},
    { value: 'OC1184', name: 'MIMICO/A'},
    { value: 'MINER-COAL', name: 'MINEIRADOR DE CARVAO'},
    { value: 'MINER-MIN', name: 'MINEIRADOR DE MINERIOS'},
    { value: 'OC1185', name: 'MINEIRO/A'},
    { value: 'OC1186', name: 'MINERADOR/A'},
    { value: 'OC1187', name: 'MINISTRO DE ESTADO'},
    { value: 'OC1188', name: 'MINISTRO DO SUPERIOR TRIBUNAL DE JUSTICA'},
    { value: 'OC1189', name: 'MINISTRO DO SUPERIOR TRIBUNAL DO TRAB'},
    { value: 'OC1190', name: 'MINISTRO DO SUPERIOR TRIBUNAL MILITAR'},
    { value: 'OC1191', name: 'MINISTRO DO SUPREMO TRIBUNAL FEDERAL'},
    { value: 'OC1192', name: 'MISSIONARIO/A'},
    { value: 'OC1193', name: 'MODELISTA'},
    { value: 'OC1194', name: 'MODELO'},
    { value: 'OC1195', name: 'MODELO FOTOGRAFICO'},
    { value: 'OC1196', name: 'MODELO PUBLICITARIO'},
    { value: 'OC1197', name: 'MONGE'},
    { value: 'OC1198', name: 'MONITOR/A DE ACAMPAMENTO'},
    { value: 'OC1199', name: 'MONITOR/A DE ALUNOS'},
    { value: 'OC1200', name: 'MONITOR/A DE RECREACAO'},
    { value: 'OC1201', name: 'MONITOR/A DE TELEATENDIMENTO'},
    { value: 'OC1202', name: 'MONITOR/A INFANTIL'},
    { value: 'OC1203', name: 'MONTADOR/A CINEMATOGRAFICO'},
    { value: 'OC1204', name: 'MONTADOR/A DE APARELHOS ELETRICOS'},
    { value: 'OC1205', name: 'MONTADOR/A DE APARELHOS ELETRONICOS'},
    { value: 'OC1206', name: 'MONTADOR/A DE AUTOMOVEIS'},
    { value: 'OC1207', name: 'MONTADOR/A DE CENTRAIS ELETRICAS'},
    { value: 'OC1208', name: 'MONTADOR/A DE CENTRAIS TELEFONICAS'},
    { value: 'OC1209', name: 'MONTADOR/A DE COMP E EQUIP AUXILIARES'},
    { value: 'OC1210', name: 'MONTADOR/A DE ELETRODOMESTICOS'},
    { value: 'OC1211', name: 'MONTADOR/A DE EQUIPAMENTOS'},
    { value: 'OC1212', name: 'MONTADOR/A DE MAQUINAS'},
    { value: 'OC1213', name: 'MONTADOR/A DE MOVEIS'},
    { value: 'OC1214', name: 'MONTADOR/A NAVAL'},
    { value: 'OC1215', name: 'MONTADOR/A SOLDADOR'},
    { value: 'OC1216', name: 'MORDOMO'},
    { value: 'MBOY', name: 'MOTOBOY'},
    { value: 'DRIVER', name: 'MOTORISTA'},
    { value: 'OC1218', name: 'MOTORISTA CARRETEIRO'},
    { value: 'OC1219', name: 'MOTORISTA DE AMBULANCIA'},
    { value: 'OC1220', name: 'MOTORISTA DE AUTOMOVEIS'},
    { value: 'DRIVR', name: 'MOTORISTA DE CAMINHAO'},
    { value: 'OC1221', name: 'MOTORISTA DE CAMINHAO - BASCULANTE'},
    { value: 'OC1222', name: 'MOTORISTA DE CAMINHAO - BETONEIRA'},
    { value: 'OC1226', name: 'MOTORISTA DE CAMINHAO - GUINCHO COM MUNK'},
    { value: 'OC1223', name: 'MOTORISTA DE CAMINHAO - GUINCHO LEVE'},
    { value: 'OC1224', name: 'MOTORISTA DE CAMINHAO - GUINCHO MEDIO'},
    { value: 'OC1225', name: 'MOTORISTA DE CAMINHAO - GUINCHO PESADO'},
    { value: 'OC1227', name: 'MOTORISTA DE CAMINHAO - GUINDASTE'},
    { value: 'OC1228', name: 'MOTORISTA DE CAMINHAO - PIPA'},
    { value: 'OC1229', name: 'MOTORISTA DE CAMINHAO - TANQUE'},
    { value: 'OC1230', name: 'MOTORISTA DE CAMINHAO LEVE'},
    { value: 'OC1231', name: 'MOTORISTA DE CARGA A FRETE'},
    { value: 'OC1232', name: 'MOTORISTA DE CARRO FORTE'},
    { value: 'MCFO', name: 'MOTORISTA DE CARRO-FORTE'},
    { value: 'OC1233', name: 'MOTORISTA DE EMPILHADEIRA'},
    { value: 'OC1234', name: 'MOTORISTA DE KOMBI'},
    { value: 'OC1235', name: 'MOTORISTA DE ONIBUS'},
    { value: 'CAB', name: 'MOTORISTA DE TAXI'},
    { value: 'OC1237', name: 'MOTORISTA NO SERVICO DOMESTICO'},
    { value: 'OC1238', name: 'MOTORISTA OPERACIONAL DE GUINCHO'},
    { value: 'OC1239', name: 'MOTORISTA OPERADOR DE BETONEIRA'},
    { value: 'OC1240', name: 'MOTORISTA OPERADOR DE BOMBA DE CONCRETO'},
    { value: 'OC1241', name: 'MOTORISTA OPERADOR DE CAMINHAO/BETONEIRA'},
    { value: 'OC1242', name: 'MOTORISTA PARTICULAR'},
    { value: 'OC1243', name: 'MOTORISTA SEGURANCA'},
    { value: 'OC1244', name: 'MOTORISTA SOCORRISTA'},
    { value: 'OC1245', name: 'MOTORISTA VIGILANTE'},
    { value: 'OC1246', name: 'MOVELEIRO/A'},
    { value: 'OC1247', name: 'MUSEOLOGO/A'},
    { value: 'OC1249', name: 'MUSICO/A'},
    { value: 'OC1250', name: 'MUSICOLOGO/A'},
    { value: 'OC1251', name: 'MUSICOTERAPEUTA'},
    { value: 'OC1252', name: 'NARRADOR/A EM PROGRAMAS DE RADIO/TV'},
    { value: 'OC1253', name: 'NARRADOR/A ESPORTIVO'},
    { value: 'OC1254', name: 'NEFROLOGISTA'},
    { value: 'OC1255', name: 'NEONATOLOGISTA'},
    { value: 'OC1256', name: 'NEUROCIRURGIAO/A'},
    { value: 'OC1257', name: 'NEUROCIRURGIAO/A PEDIATRICO'},
    { value: 'OC1258', name: 'NEUROFISIOLOGISTA'},
    { value: 'OC1259', name: 'NEUROLOGISTA'},
    { value: 'OC1260', name: 'NEUROPEDIATRA'},
    { value: 'OC1261', name: 'NEUROPSICOLOGO/A'},
    { value: 'OC1262', name: 'NEUROPSIQUIATRA'},
    { value: 'OC1263', name: 'NUMEROLOGO/A'},
    { value: 'NUTRI', name: 'NUTRICIONISTA'},
    { value: 'OC1265', name: 'NUTROLOGISTA'},
    { value: 'OC1266', name: 'OBSTETRA'},
    { value: 'OC1267', name: 'OCEANOGRAFO/A'},
    { value: 'OC1268', name: 'OCEANOLOGO/A'},
    { value: 'OC1269', name: 'OCULISTA'},
    { value: 'OC1270', name: 'ODONTOLOGISTA'},
    { value: 'OC1271', name: 'ODONTOLOGO/A'},
    { value: 'OC1272', name: 'ODONTOPEDIATRA'},
    { value: 'OC1273', name: 'ODONTORADIOLOGISTA'},
    { value: 'OC1274', name: 'OFFICEBOY / OFFICEGIRL'},
    { value: 'OC1275', name: 'OFICIAL CARPINTEIRO'},
    { value: 'OC1276', name: 'OFICIAL DA AERONAUTICA'},
    { value: 'OC1277', name: 'OFICIAL DA MARINHA'},
    { value: 'OC1278', name: 'OFICIAL DA MARINHA MERCANTE'},
    { value: 'OJ', name: 'OFICIAL DE JUSTICA'},
    { value: 'POLIC', name: 'OFICIAL DE POLICIA'},
    { value: 'OC1280', name: 'OFICIAL DE REGISTRO'},
    { value: 'OC1281', name: 'OFICIAL DO CORPO DE BOMBEIROS MILITAR'},
    { value: 'OC1282', name: 'OFICIAL DO EXERCITO'},
    { value: 'OC1283', name: 'OFICIAL DO REGISTRO CIVIL DE PESSOAS JUR'},
    { value: 'OC1284', name: 'OFICIAL DO REGISTRO CIVIL DE PESSOAS NAT'},
    { value: 'OC1285', name: 'OFICIAL DO REGISTRO DE DISTRIBUICOES'},
    { value: 'OC1286', name: 'OFICIAL DO REGISTRO DE IMOVEIS'},
    { value: 'OC1287', name: 'OFICIAL DO REGISTRO DE TITULOS E DOC'},
    { value: 'OC1288', name: 'OFICIAL ENCARREGADO DE PAINEL DE ADVERT'},
    { value: 'OC1289', name: 'OFICIAL GENERAL DA AERONAUTICA'},
    { value: 'OC1290', name: 'OFICIAL GENERAL DA MARINHA'},
    { value: 'OC1291', name: 'OFICIAL GENERAL DO EXERCITO'},
    { value: 'OC1292', name: 'OFICIAL JUDICIARIO'},
    { value: 'OC1293', name: 'OFICIAL LEGISLATIVO'},
    { value: 'OC1294', name: 'OFICIAL MAIOR'},
    { value: 'OC1295', name: 'OFICIAL MECANICO DE VEICULOS'},
    { value: 'OC1296', name: 'OFIOLOGISTA'},
    { value: 'OC1297', name: 'OFTALMOLOGISTA'},
    { value: 'OC1298', name: 'OMBUDSMAN'},
    { value: 'OC1299', name: 'ONCOLOGISTA'},
    { value: 'OC1309', name: 'OPERADOR DE BOMBAS TRAT DE AGUA/EFLUENTE'},
    { value: 'OC1300', name: 'OPERADOR/A CINEMATOGRAFICO'},
    { value: 'OC1301', name: 'OPERADOR/A DE BETONEIRA'},
    { value: 'OC1302', name: 'OPERADOR/A DE BINADEIRA'},
    { value: 'OC1303', name: 'OPERADOR/A DE BOBINADEIRA'},
    { value: 'OC1304', name: 'OPERADOR/A DE BOLSA DE MERCADORIAS E FUT'},
    { value: 'OC1305', name: 'OPERADOR/A DE BOLSA PREGAO'},
    { value: 'OC1306', name: 'OPERADOR/A DE BOMBA DE CONCRETO'},
    { value: 'OC1307', name: 'OPERADOR/A DE BOMBA REFINACAO/PETROLEO'},
    { value: 'OC1308', name: 'OPERADOR/A DE BOMBAS/ESTACAO BOMBEAMENTO'},
    { value: 'OC1310', name: 'OPERADOR/A DE BONDE'},
    { value: 'OC1311', name: 'OPERADOR/A DE BRITADEIRA'},
    { value: 'OC1312', name: 'OPERADOR/A DE CABINE CINEMATOGRAFICA'},
    { value: 'OC1313', name: 'OPERADOR/A DE CAIXA'},
    { value: 'OC1314', name: 'OPERADOR/A DE CAIXA LOTERICO'},
    { value: 'OC1315', name: 'OPERADOR/A DE CALDEIRA'},
    { value: 'OC1316', name: 'OPERADOR/A DE CAMBIO/COMERCIO EXTERIOR'},
    { value: 'OC1317', name: 'OPERADOR/A DE CAMERA'},
    { value: 'OC1318', name: 'OPERADOR/A DE EMPILHADEIRA'},
    { value: 'OC1319', name: 'OPERADOR/A DE ESCAVADEIRA'},
    { value: 'OC1320', name: 'OPERADOR/A DE FRESADORA'},
    { value: 'OC1321', name: 'OPERADOR/A DE GRUA'},
    { value: 'OC1322', name: 'OPERADOR/A DE GUINCHO'},
    { value: 'OC1323', name: 'OPERADOR/A DE GUINDASTE'},
    { value: 'OC1324', name: 'OPERADOR/A DE LINHA DE MONTAGEM'},
    { value: 'OC1325', name: 'OPERADOR/A DE MAQUINAS'},
    { value: 'OC1326', name: 'OPERADOR/A DE MERCADORIAS E DERIVATIVOS'},
    { value: 'OC1327', name: 'OPERADOR/A DE MESA - RADIO'},
    { value: 'OC1328', name: 'OPERADOR/A DE MESA BOLSA DE VALORES'},
    { value: 'OC1329', name: 'OPERADOR/A DE MESA TELEFONICA'},
    { value: 'OC1330', name: 'OPERADOR/A DE METRO'},
    { value: 'OC1331', name: 'OPERADOR/A DE MOTOSSERRA'},
    { value: 'OC1332', name: 'OPERADOR/A DE OLEODUTO'},
    { value: 'OC1333', name: 'OPERADOR/A DE PAINEL DE CONTROLE'},
    { value: 'OC1334', name: 'OPERADOR/A DE PLATAFORMA PETROLEO'},
    { value: 'OC1335', name: 'OPERADOR/A DE PRENSA'},
    { value: 'OC1336', name: 'OPERADOR/A DE RADIO'},
    { value: 'OC1337', name: 'OPERADOR/A DE RAIO-X'},
    { value: 'OC1338', name: 'OPERADOR/A DE REATOR NUCLEAR'},
    { value: 'OC1339', name: 'OPERADOR/A DE REDE DE TRANSMISSAO-DADOS'},
    { value: 'OC1340', name: 'OPERADOR/A DE RENDA FIXA'},
    { value: 'OC1341', name: 'OPERADOR/A DE RENDA VARIAVEL'},
    { value: 'OC1342', name: 'OPERADOR/A DE RETROESCAVADEIRA'},
    { value: 'OC1343', name: 'OPERADOR/A DE ROLO COMPACTADOR'},
    { value: 'OC1344', name: 'OPERADOR/A DE ROLO COMPRESSOR'},
    { value: 'OC1345', name: 'OPERADOR/A DE SALA DE CONTROLE/MINERACAO'},
    { value: 'OC1347', name: 'OPERADOR/A DE SALA DE MAQUINA/EMBARCACAO'},
    { value: 'OC1348', name: 'OPERADOR/A DE SERRA'},
    { value: 'OC1356', name: 'OPERADOR/A DE SIST DE EQUIP EM EXIB TV'},
    { value: 'OC1357', name: 'OPERADOR/A DE SIST DE TRANS/RECEP DE TV'},
    { value: 'OC1349', name: 'OPERADOR/A DE SISTEMA DE CLIMATIZACAO'},
    { value: 'OC1350', name: 'OPERADOR/A DE SISTEMA DE COMPUTADOR'},
    { value: 'OC1351', name: 'OPERADOR/A DE SISTEMA DE GAS'},
    { value: 'OC1352', name: 'OPERADOR/A DE SISTEMA DE PRECIPITACAO'},
    { value: 'OC1353', name: 'OPERADOR/A DE SISTEMA DE REFRIGERACAO'},
    { value: 'OC1354', name: 'OPERADOR/A DE SISTEMAS COMPUT EM REDE'},
    { value: 'OC1355', name: 'OPERADOR/A DE SISTEMAS DE AGUA'},
    { value: 'OC1358', name: 'OPERADOR/A DE SONDA'},
    { value: 'OC1359', name: 'OPERADOR/A DE SUPERMERCADOS'},
    { value: 'OC1360', name: 'OPERADOR/A DE TELECOBRANCA'},
    { value: 'OC1361', name: 'OPERADOR/A DE TELEFONIA'},
    { value: 'OC1362', name: 'OPERADOR/A DE TELEMARKETING'},
    { value: 'OC1363', name: 'OPERADOR/A DE TELEPROCESSAMENTO'},
    { value: 'OC1364', name: 'OPERADOR/A DE TORNO'},
    { value: 'OC1365', name: 'OPERADOR/A DE TRAFEGO'},
    { value: 'OC1366', name: 'OPERADOR/A DE TRANSMISSOR'},
    { value: 'OC1367', name: 'OPERADOR/A DE TRANSMISSOR DE RADIO'},
    { value: 'OC1368', name: 'OPERADOR/A DE TRANSMISSOR TV'},
    { value: 'OC1369', name: 'OPERADOR/A DE TRATAMENTO DE AGUA/ESGOTO'},
    { value: 'OC1370', name: 'OPERADOR/A DE TRATAMENTO DE GAS'},
    { value: 'OC1371', name: 'OPERADOR/A DE TRATAMENTO RESIDUAL'},
    { value: 'OC1372', name: 'OPERADOR/A DE TRATAMENTO TERMICO'},
    { value: 'OC1373', name: 'OPERADOR/A DE TRATOR'},
    { value: 'OC1374', name: 'OPERADOR/A DE TREM'},
    { value: 'OC1375', name: 'OPERADOR/A DE TURBINAS'},
    { value: 'OC1376', name: 'OPERADOR/A DE TURISMO'},
    { value: 'OC1377', name: 'OPERADOR/A DE USINA ELETRICA'},
    { value: 'OC1378', name: 'OPERADOR/A DE USINA HIDRELETRICA'},
    { value: 'OC1379', name: 'OPERADOR/A DE USINA HIDROELETRICA'},
    { value: 'OC1380', name: 'OPERADOR/A DE USINA NUCLEAR'},
    { value: 'OC1381', name: 'OPERADOR/A DE USINA TERMICA'},
    { value: 'OC1382', name: 'OPERADOR/A DE USINA TERMOELETRICA'},
    { value: 'OC1383', name: 'OPERADOR/A DE USINA TERMOGAS'},
    { value: 'OC1384', name: 'OPERADOR/A DE USINA TERMONUCLEAR'},
    { value: 'OC1385', name: 'OPERADOR/A DE VENDAS'},
    { value: 'OC1386', name: 'OPERADOR/A DE VIDEO'},
    { value: 'OC1387', name: 'OPERADOR/A ELETROMECANICO'},
    { value: 'OC1388', name: 'OPERADOR/A FINANCEIRO'},
    { value: 'OC1346', name: 'OPERADOR/A SL CONTR/INST QUIM/PETROQUIM'},
    { value: 'OC1389', name: 'OPTICO/A'},
    { value: 'OC1390', name: 'ORGANIZADOR/A DE EVENTOS'},
    { value: 'OC1391', name: 'ORIENTADOR/A DE ENSINO'},
    { value: 'OC1392', name: 'ORIENTADOR/A EDUCACIONAL'},
    { value: 'OC1393', name: 'ORIENTADOR/A ESCOLAR'},
    { value: 'OC1394', name: 'ORIENTADOR/A PROFISSIONAL'},
    { value: 'OC1395', name: 'ORIENTADOR/A SOCIO EDUCATIVO'},
    { value: 'OC1396', name: 'ORIENTADOR/A VOCACIONAL E PROFISSIONAL'},
    { value: 'OC1397', name: 'ORvalueNTADOR/A'},
    { value: 'OC1398', name: 'ORNITOLOGO'},
    { value: 'OC1399', name: 'ORQUESTRADOR'},
    { value: 'OC1400', name: 'ORTODONTISTA'},
    { value: 'OC1401', name: 'ORTODONTOLOGO/A'},
    { value: 'OC1402', name: 'ORTOPEDISTA'},
    { value: 'OC1403', name: 'ORTOPTISTA'},
    { value: 'OC1404', name: 'OTORRINOLARINGOLOGISTA'},
    { value: 'OC1405', name: 'OURIVES'},
    { value: 'OUTROS', name: 'OUTRA'},
    { value: 'OC1406', name: 'OUVIDOR/A'},
    { value: 'OC1407', name: 'PADEIRO/A'},
    { value: 'OC1408', name: 'PADEIRO/A CONFEITEIRO'},
    { value: 'OC1409', name: 'PADRE'},
    { value: 'OC1410', name: 'PAISAGISTA'},
    { value: 'OC1411', name: 'PALEONTOLOGO/A'},
    { value: 'OC1412', name: 'PALHACO/A'},
    { value: 'OC1413', name: 'PANIFICADOR/A'},
    { value: 'PAPI', name: 'PAPILOSCOPISTA'},
    { value: 'OC1414', name: 'PAPILOSCOPISTA POLICIAL'},
    { value: 'OC1415', name: 'PARAPSICOLOGO/A'},
    { value: 'OC1416', name: 'PARTEIRO/A'},
    { value: 'OC1417', name: 'PARTIDOR JUDICIAL'},
    { value: 'OC1418', name: 'PASSADOR/A DE ROUPAS'},
    { value: 'OC1419', name: 'PASTELEIRO/A'},
    { value: 'SHEP', name: 'PASTOR'},
    { value: 'OC1420', name: 'PASTOR/A - PECUARIA'},
    { value: 'OC1421', name: 'PASTOR/A EVANGELICO'},
    { value: 'OC1422', name: 'PATOLOGISTA'},
    { value: 'OC1423', name: 'PATOLOGISTA CLINICO'},
    { value: 'OC1424', name: 'PECUARISTA'},
    { value: 'PEDA', name: 'PEDAGOGO/A'},
    { value: 'OC1426', name: 'PEDIATRA'},
    { value: 'OC1427', name: 'PEDREIRO/A'},
    { value: 'OC1428', name: 'PENALISTA'},
    { value: 'OC1429', name: 'PERFUMISTA'},
    { value: 'OC1430', name: 'PERIODONTISTA'},
    { value: 'PERI', name: 'PERITO CRIMINAL'},
    { value: 'PERL', name: 'PERITO LEGISTA'},
    { value: 'OC1431', name: 'PERITO/A AVALIADOR DE BENS MOVEIS'},
    { value: 'OC1432', name: 'PERITO/A CONTABIL'},
    { value: 'OC1433', name: 'PERITO/A CONTADOR'},
    { value: 'OC1434', name: 'PERITO/A CRIMINAL'},
    { value: 'OC1435', name: 'PERITO/A CRIMINAL FEDERAL'},
    { value: 'OC1436', name: 'PERITO/A CRIMINALISTICO'},
    { value: 'OC1437', name: 'PERITO/A CRIMINALISTICO ENGENHEIRO'},
    { value: 'OC1438', name: 'PERITO/A CRIMINALISTICO QUIMICO'},
    { value: 'OC1439', name: 'PERITO/A DE BALANCO'},
    { value: 'OC1440', name: 'PERITO/A ECONOMICO'},
    { value: 'OC1441', name: 'PERITO/A EM IDENTIFICACAO'},
    { value: 'OC1442', name: 'PERITO/A JUDICIAL CONTABIL'},
    { value: 'OC1443', name: 'PERITO/A LIQUIDADOR CONTADOR'},
    { value: 'OC1444', name: 'PERITO/A MEDICO'},
    { value: 'OC1445', name: 'PERITO/A OFICIAL'},
    { value: 'OC1446', name: 'PERITO/A PAPILOSCOPISTA'},
    { value: 'OC1447', name: 'PERSONAL TRAINNER'},
    { value: 'OC1448', name: 'PESCADOR/A PROFISSIONAL'},
    { value: 'OC1449', name: 'PESQUISADOR/A'},
    { value: 'PILO', name: 'PILOTO'},
    { value: 'OC1450', name: 'PILOTO AGRICOLA'},
    { value: 'OC1451', name: 'PILOTO COMERCIAL'},
    { value: 'OC1452', name: 'PILOTO DE AERONAVES'},
    { value: 'PILOT', name: 'PILOTO DE AVIOES'},
    { value: 'OC1453', name: 'PILOTO DE HELICOPTERO'},
    { value: 'OC1454', name: 'PILOTO DE METRO'},
    { value: 'OC1455', name: 'PILOTO FLUVIAL'},
    { value: 'OC1456', name: 'PINTOR/A DE ARTES VISUAIS'},
    { value: 'OC1457', name: 'PINTOR/A DE PAREDES'},
    { value: 'OC1458', name: 'PINTOR/A INDUSTRIAL'},
    { value: 'OC1459', name: 'PIPOQUEIRO/A'},
    { value: 'OC1460', name: 'PIROTECNICO/A'},
    { value: 'OC1461', name: 'PISCICULTOR/A'},
    { value: 'OC1462', name: 'PIZZAIOLO/A'},
    { value: 'OC1463', name: 'PLATAFORMISTA - PETROLEO'},
    { value: 'OC1464', name: 'PNEUMOLOGISTA'},
    { value: 'OC1465', name: 'PODADOR AGRICOLA'},
    { value: 'OC1466', name: 'PODOLOGO/A'},
    { value: 'OC1467', name: 'POETA'},
    { value: 'POLI', name: 'POLICIAL'},
    { value: 'OC1468', name: 'POLICIAL CIVIL'},
    { value: 'OC1469', name: 'POLICIAL FEDERAL'},
    { value: 'OC1470', name: 'POLICIAL MILITAR'},
    { value: 'OC1471', name: 'POLICIAL RODOVIARIO'},
    { value: 'OC1472', name: 'PORTEIRO/A'},
    { value: 'OC1473', name: 'PRACA DA AERONAUTICA'},
    { value: 'OC1474', name: 'PRACA DA MARINHA'},
    { value: 'OC1475', name: 'PRACA DO EXERCITO'},
    { value: 'OC1476', name: 'PRACAS DAS FORCAS ARMADAS'},
    { value: 'OC1477', name: 'PRATICO/A'},
    { value: 'OC1478', name: 'PREFEITO/A'},
    { value: 'OC1479', name: 'PREGOEIRO/A'},
    { value: 'OC1480', name: 'PREPARADOR/A FISICO'},
    { value: 'PRES', name: 'PRESIDENTE'},
    { value: 'OC1482', name: 'PRIMEIRO-SARGENTO'},
    { value: 'OC1483', name: 'PRIMEIRO-SARGENTO DE POLICIA MILITAR'},
    { value: 'OC1484', name: 'PRIMEIRO-SARGENTO DO CORPO DE BOMBEIROS'},
    { value: 'OC1485', name: 'PRIMEIRO-TENENTE'},
    { value: 'OC1486', name: 'PRIMEIRO-TENENTE DE POLICIA MILITAR'},
    { value: 'OC1487', name: 'PRIMEIRO-TENENTE DO CORPO DE BOMBEIROS'},
    { value: 'OC1488', name: 'PROCTOLOGISTA'},
    { value: 'PR', name: 'PROCURADOR'},
    { value: 'OC1489', name: 'PROCURADOR AUTARQUICO'},
    { value: 'OC1490', name: 'PROCURADOR DA ASSISTENCIA JUDICIARIA'},
    { value: 'OC1491', name: 'PROCURADOR DA FAZENDA NACIONAL'},
    { value: 'OC1492', name: 'PROCURADOR DA REPUBLICA'},
    { value: 'OC1493', name: 'PROCURADOR DE FUNDACAO'},
    { value: 'OC1494', name: 'PROCURADOR DE JUSTICA'},
    { value: 'OC1495', name: 'PROCURADOR DE JUSTICA MILITAR'},
    { value: 'OC1496', name: 'PROCURADOR DISTRITAL'},
    { value: 'OC1497', name: 'PROCURADOR DO ESTADO'},
    { value: 'OC1498', name: 'PROCURADOR DO TRABALHO'},
    { value: 'OC1499', name: 'PROCURADOR FEDERAL'},
    { value: 'OC1500', name: 'PROCURADOR FUNDACIONAL'},
    { value: 'OC1501', name: 'PROCURADOR MUNICIPAL'},
    { value: 'OC1502', name: 'PROCURADOR REGIONAL DA REPUBLICA'},
    { value: 'OC1503', name: 'PROCURADOR REGIONAL DO TRABALHO'},
    { value: 'OC1504', name: 'PRODUTOR AGRICOLA'},
    { value: 'OC1505', name: 'PRODUTOR AGROPECUARIO'},
    { value: 'OC1506', name: 'PRODUTOR CINEMATOGRAFICO'},
    { value: 'OC1507', name: 'PRODUTOR CULTURAL'},
    { value: 'OC1508', name: 'PRODUTOR PECUARIO'},
    { value: 'OC1509', name: 'PRODUTOR RURAL'},
    { value: 'TEACH', name: 'PROFESSOR/A'},
    { value: 'OC1511', name: 'PROFESSOR/A DE ALEMAO'},
    { value: 'OC1512', name: 'PROFESSOR/A DE ARTES'},
    { value: 'OC1513', name: 'PROFESSOR/A DE CIENCIAS'},
    { value: 'OC1514', name: 'PROFESSOR/A DE CRECHE OU PRE-ESCOLA'},
    { value: 'OC1515', name: 'PROFESSOR/A DE DANCA'},
    { value: 'OC1516', name: 'PROFESSOR/A DE EDUCACAO FISICA'},
    { value: 'OC1517', name: 'PROFESSOR/A DE ESPANHOL'},
    { value: 'OC1518', name: 'PROFESSOR/A DE FISICA'},
    { value: 'OC1519', name: 'PROFESSOR/A DE FRANCES'},
    { value: 'OC1520', name: 'PROFESSOR/A DE GEOGRAFIA'},
    { value: 'OC1521', name: 'PROFESSOR/A DE HISTORIA'},
    { value: 'OC1522', name: 'PROFESSOR/A DE IDIOMAS'},
    { value: 'OC1523', name: 'PROFESSOR/A DE INGLES'},
    { value: 'OC1524', name: 'PROFESSOR/A DE ITALIANO'},
    { value: 'OC1525', name: 'PROFESSOR/A DE LITERATURA'},
    { value: 'OC1526', name: 'PROFESSOR/A DE MANDARIM'},
    { value: 'OC1527', name: 'PROFESSOR/A DE MATEMATICA'},
    { value: 'OC1528', name: 'PROFESSOR/A DE PORTUGUES'},
    { value: 'OC1529', name: 'PROFESSOR/A DE QUIMICA'},
    { value: 'OC1530', name: 'PROFESSOR/A DE TEATRO'},
    { value: 'OC1531', name: 'PROFESSOR/A NO ENSINO FUNDAMENTAL'},
    { value: 'OC1532', name: 'PROFESSOR/A NO ENSINO MEDIO'},
    { value: 'OC1533', name: 'PROFESSOR/A NO ENSINO SUPERIOR'},
    { value: 'OC1534', name: 'PROFESSOR/A UNIVERSITARIO'}
];
