import React from 'react';
import FormInput from '../../components/html-elements/form-input/form-input.component';
import { BrlCurrency, PAGE_CONST } from '../../utils/prepare-data';
import { CONTENT } from '../../utils/page-content';
import { ValidationData } from '../../utils/validation-data';
import './new-policy.styles.scss';


class NewPolicy extends React.Component {
    constructor(props) {
        super(props);
        const { currentUser } = this.props;
        this.state = {
            clickedSubmit: false,
            accepted: true,
            checkTerm: false,
            termsError: {
                EN: 'You must declare and agree to continue',
                PT: 'Você deve declarar e concordar em continuar'
            },
            today: new Date(),
            DateOfBirth: (currentUser && currentUser.Owner) ? currentUser.Owner.DateOfBirth : '',
            DateOfDispatch: (currentUser && currentUser.Owner) ? currentUser.Owner.DateOfDispatch : '',
            CPF: (currentUser && currentUser.Owner.CPF) ? currentUser.Owner.CPF : '',
            SSN: (currentUser && currentUser.Owner.SSN) ? currentUser.Owner.SSN : '',
            PostalCode: (currentUser && currentUser.Owner.Contact.PostalCode) ? currentUser.Owner.Contact.PostalCode : '',
            ContactNumber: (currentUser && currentUser.Owner.ContactNumber) ? currentUser.Owner.ContactNumber : '',
            Profession: (currentUser && currentUser.Owner.Profession) ? currentUser.Owner.Profession : '',
            ProfessionList: [],
            ProfessionListF: [[]],
            IssuingAgency: [],
            DropdownItems: {
                Product: { show: false },
                Plan: { show: false },
                SumInsured: { show: false },
                FamilyCombination: { show: false },
                TermOptions: { show: false },
                PayOptions: { show: false },
                Locality: { show: false },
                StaffOptions: { show: false },
                SalesOptions: { show: false },
                ImpairedLifeOptions: { show: false },
                DiabetesOptions: { show: false },
                HyperTensionOptions: { show: false },
                SmartHealthOptions: { show: false },
                IssuingAgency: { show: false },
                Relationship: [{ show: false }],
                Gender: { show: false },
                GenderF: [{ show: false }],
                SmartHealthOptionsF: [{ show: false }],
                // ImpairedLifeOptionsF: [{ show: false }],
                DiabetesOptionsF: [{ show: false }],
                HyperTensionOptionsF: [{ show: false }],
            },
            validateFields: ['Product', 'Plan', 'SumInsured', 'Name', 'DateOfBirth',
                , 'StreetHouseNo', 'Locality', 'PostalCode', 'Profession', 'ContactNumber',
                'Email'],
            errors: ValidationData
        }
    }

    componentDidMount = () => {
        if (this.props && this.props.setPage) {
            this.props.setPage('newPolicy');
        }
    }

    validateFamilyData = () => {
        const { currentUser, formData, FamilyMemberError } = this.props;
        let isError = false;
        if (formData.members > 3 && currentUser.Product === PAGE_CONST.FUNERAL) {
            currentUser.FamilyMembers.map((member, i) => {
                const FMi = FamilyMemberError[i];
                const nameError = this.validateData(FMi.Name, member.Name);
                FMi.Name.isError = nameError;

                const relationshipError = this.validateData(FMi.Relationship, member.Relationship);
                FMi.Relationship.isError = relationshipError;

                const ageError = this.validateData(FMi.Age, member.Age);
                FMi.Age.isError = ageError;

                const genderError = this.validateData(FMi.GenderF, member.GenderF);
                FMi.GenderF.isError = genderError;

                const professionError = this.validateData(FMi.ProfessionF, member.ProfessionF);
                FMi.ProfessionF.isError = professionError;

                if (nameError || relationshipError || ageError || genderError || professionError) {
                    isError = true;
                }
                return member;
            });
        }
        return isError;
    }

    handleFamilyBlur = (event) => {
        const { FamilyMemberError, refreshState } = this.props;
        const { name, value, fi } = event.target;
        const index = (typeof fi !== 'undefined') ? fi : event.target.getAttribute('fi');
        const hasError = this.validateData(FamilyMemberError[index][name], value);
        FamilyMemberError[index][name].isError = hasError;
        refreshState();
        // if (currentUser.FamilyMembers[index].Name !== '' && currentUser.FamilyMembers[index].Relationship !== '' && currentUser.FamilyMembers[index].Age !== '') {
        //     refreshPremium();
        // }
    }

    validateData = (errObj, value) => {
        const { language = 'PT' } = this.props;
        if (!value) {
            return true;
        }
        if ('checkFor' in errObj) {
            let isValid = true;
            if ('test' in errObj.checkFor) {
                if (!errObj.checkFor.test.test(value)) {
                    isValid = false;
                    errObj.msg[language] = errObj.checkFor.msg[language];
                }
            } else if ('ageLimit' in errObj.checkFor || 'maxAge' in errObj.checkFor) {
                let d = [];
                if (value.includes('/')) {
                    d = value.split('/');
                    const date1 = new Date(`${d[2]}-${d[1]}-${d[0]}`);
                    if (Object.prototype.toString.call(date1) === "[object Date]") {
                        if (isNaN(date1.getTime())) {
                            isValid = false;
                            errObj.msg[language] = errObj.checkFor.msg[language];
                        }
                        value = this.state.today.getFullYear() - parseInt(d[2]);
                        // value = new Date(new Date() - new Date(date1)).getFullYear() - 1970;
                    } else {
                        isValid = false;
                        errObj.msg[language] = errObj.checkFor.msg[language];
                    }
                }
                if (isValid) {
                    isValid = this.validateAgeLimit(value, errObj, d, language);
                }
            }
            return !isValid;
        }
        return false;
    }

    validateAgeLimit = (value, errObj, d, language) => {
        const currentDate = this.state.today.getDate();
        const currentMonth = this.state.today.getMonth();
        let res = true;
        if (value < errObj.checkFor.ageLimit
            || (value === errObj.checkFor.ageLimit && parseInt(d[1]) > currentMonth + 1)
            || (value === errObj.checkFor.ageLimit && parseInt(d[1]) === currentMonth + 1 && parseInt(d[0]) > currentDate)) {
            errObj.msg[language] = errObj.checkFor.msg[language];
            res = false;
            return res;
        }
        if (value > errObj.checkFor.maxAge
            || (value === errObj.checkFor.maxAge && parseInt(d[1]) < currentMonth + 1)
            || (value === errObj.checkFor.maxAge && parseInt(d[1]) === currentMonth + 1 && parseInt(d[0]) < currentDate)) {
            errObj.msg[language] = errObj.checkFor.maxAgeMsg[language];
            res = false;
            return res;
        }
        return res;
    }
    onSubmit = () => {
        try {
            this.setState({ clickedSubmit: true, accepted: (!this.state.checkTerm) ? true : this.state.accepted });
            const { currentUser, detailsToShow } = this.props;
            const { errors, validateFields } = this.state;
            let formHasError = false;
            validateFields.map((field) => {
                errors[field].isError = false;
                const { stateValue, level } = errors[field];
                const jsonPositions = stateValue.split(',');
                let fValue = '';
                switch (level) {
                    case 1:
                        fValue = currentUser[jsonPositions[0]];
                        break;
                    case 2:
                        fValue = currentUser[jsonPositions[0]][jsonPositions[1]];
                        break;
                    case 3:
                        fValue = currentUser[jsonPositions[0]][jsonPositions[1]][jsonPositions[2]];
                        break;
                    default:
                        fValue = currentUser[jsonPositions[0]];
                }
                if ((currentUser.Product === PAGE_CONST.TROPICAL && (field === 'Profession' || field === 'Gender'))
                    || (detailsToShow.Product === PAGE_CONST.DD && (field === 'Profession' || field === 'SumInsured'))) {
                    return true;
                }
                const isError = this.validateData(errors[field], fValue);
                errors[field].isError = isError;
                if (isError) {
                    formHasError = isError;
                }
                return true;
            });
            const isFamilyError = this.validateFamilyData();
            if (isFamilyError) {
                formHasError = isFamilyError;
            }
            if (this.state.accepted) {
                this.props.redirectToPayment();
            }
        } catch (Exception) {
            console.error('Error in on submit validation', Exception.message);
        }
    }

    TermsAndCondition = () => {
        this.setState({ accepted: !this.state.accepted });
    }

    handleDropdownClick = (e, ele, act) => {
        try {
            e.preventDefault();
            setTimeout(() => {
                const newState = { ...this.state };
                if (ele.includes('Relationship')) {
                    newState.DropdownItems.Relationship[ele.split('-')[1]].show = act;
                } else if (ele.includes('GenderF')) {
                    newState.DropdownItems.GenderF[ele.split('-')[1]].show = act;
                } else if (ele.includes('SmartHealthOptionsF')) {
                    newState.DropdownItems.SmartHealthOptionsF[ele.split('-')[1]].show = act;
                } else if (ele.includes('DiabetesOptionsF')) {
                    newState.DropdownItems.DiabetesOptionsF[ele.split('-')[1]].show = act;
                } else if (ele.includes('HyperTensionOptionsF')) {
                    newState.DropdownItems.HyperTensionOptionsF[ele.split('-')[1]].show = act;
                } else {
                    newState.DropdownItems[ele].show = act;
                }
                this.setState(newState);
            }, 200);
        } catch (e) {
            console.log('Error in dropdown select ', e.message);
        }

    }

    handleValueSelect = (e, name, value, selectedIndex) => {
        const dummyObj = {
            target: {
                name,
                value,
                selectedIndex
            }
        };
        const additionalObj = {
            jsonvalue: e.target.getAttribute('jsonvalue'),
            level: e.target.getAttribute('level'),
            index: e.target.getAttribute('order')
        }
        this.handleDropdownClick(e, name, false);
        if (name.includes('Relationship') || name.includes('GenderF') || name.includes('SmartHealthOptionsF') || name.includes('DiabetesOptionsF') || name.includes('HyperTensionOptionsF')) {
            const nameExtract = name.split('-');
            dummyObj.target.fi = nameExtract[1];
            dummyObj.target.name = nameExtract[0];
            this.handleFamilyBlur(dummyObj);
        } else {
            this.handleBlur(dummyObj);
        }
        this.props.handleClick(dummyObj, additionalObj);
        const { currentUser, detailsToShow } = this.props;
        if (name.includes('Gender') && (currentUser.Product === PAGE_CONST.FUNERAL || detailsToShow.Product === PAGE_CONST.DD)) {
            const memberIndex = (name.includes('-') ? name.split('-')[1] : '');
            this.props.getFuneralQuote(memberIndex);
        }
    }

    handleClick = (event) => {
        this.handleBlur(event);
        this.props.handleClick(event);
    }

    handleBlur = event => {
        const { name, value } = event.target;
        const newState = { ...this.state };
        const errorJson = newState.errors[name];
        const { currentUser, language = 'PT', detailsToShow } = this.props;

        if ((currentUser.Product === PAGE_CONST.FUNERAL || currentUser.Product === PAGE_CONST.DD) && name === 'DateOfBirth') {
            errorJson.checkFor.maxAge = 65;
            errorJson.checkFor.maxAgeMsg[language] = errorJson.checkFor.maxAgeMsg[language].replace('99', '65');
        }
        const hasError = this.validateData(errorJson, value);
        newState.errors[name].isError = hasError;
        let dateHasError = false;
        if (!hasError && (name === 'DateOfBirth' || name === 'DateOfDispatch')) {
            dateHasError = this.validateDate(value);
            newState.errors[name].isError = dateHasError;
        }
        if (!dateHasError && (name === 'DateOfBirth' || name === 'Profession')
            && (currentUser.Product === PAGE_CONST.FUNERAL || detailsToShow.Product === PAGE_CONST.DD)) {
            setTimeout(() => {
                this.props.getFuneralQuote();
            }, 500);
        }
        this.setState(newState);
    }

    handleCpfCheck = event => {
        let { value } = event.target;
        let cpfVal = value;
        if (value.length === 3 || value.length === 7) {
            cpfVal = `${cpfVal}.`;
        } else if (value.length === 11) {
            cpfVal = `${cpfVal}-`;
        }
        if ((value.length === 4 && !value.includes('.')) || (value.length === 8 && (value.match(/\./g) || []).length < 2)) {
            cpfVal = '';
            for (const a of value) {
                cpfVal = `${cpfVal}${a}${((cpfVal.length === 2 && !value.includes('.')) || cpfVal.length === 6) ? '.' : ''}`
            }
        }
        if (value.length === 12 && !value.includes('-')) {
            cpfVal = '';
            for (const a of value) {
                cpfVal = `${cpfVal}${a}${(cpfVal.length === 10) ? '-' : ''}`
            }
        }
        value = cpfVal;
        this.setState({ CPF: value });
        this.props.handleClick(event);
    }

    handleCpfKeyUp = event => {
        const { keyCode } = event;
        let { CPF } = this.state;
        if (keyCode === 8) {
            if (CPF.length === 4 || CPF.length === 8) {
                CPF = CPF.replace(/.([^.]*)$/, '$1');
            } else if (CPF.length === 12) {
                CPF = CPF.replace('-', '');
            }
            this.setState({ CPF });
        }
    }

    handleSsnCheck = event => {
        let { value } = event.target;
        let ssnVal = value;
        if (value.length === 2 || value.length === 6) {
            ssnVal = `${ssnVal}.`;
        } else if (value.length === 10) {
            ssnVal = `${ssnVal}-`;
        }
        if ((value.length === 3 && !value.includes('.')) || (value.length === 7 && (value.match(/\./g) || []).length < 2)) {
            ssnVal = '';
            for (const a of value) {
                ssnVal = `${ssnVal}${a}${((ssnVal.length === 1 && !value.includes('.')) || ssnVal.length === 5) ? '.' : ''}`
            }
        }
        if (value.length === 11 && !value.includes('-')) {
            ssnVal = '';
            for (const a of value) {
                ssnVal = `${ssnVal}${a}${(ssnVal.length === 9) ? '-' : ''}`
            }
        }
        value = ssnVal;
        this.setState({ SSN: value });
        this.props.handleClick(event);
    }

    handleSsnKeyUp = event => {
        const { keyCode } = event;
        let { SSN } = this.state;
        if (keyCode === 8) {
            if (SSN.length === 3 || SSN.length === 7) {
                SSN = SSN.replace(/.([^.]*)$/, '$1');
            } else if (SSN.length === 11) {
                SSN = SSN.replace('-', '');
            }
            this.setState({ SSN });
        }
    }

    handlePostalCheck = event => {
        let { value } = event.target;
        let postVal = value;
        if (value.length === 5) {
            postVal = `${postVal}-`;
        }
        if (value.length === 6 && !value.includes('-')) {
            postVal = '';
            for (const a of value) {
                postVal = `${postVal}${a}${(postVal.length === 4) ? '-' : ''}`
            }
        }
        value = postVal;
        this.setState({ PostalCode: value });
        this.props.handleClick(event);
    }

    handlePostalKeyUp = event => {
        const { keyCode } = event;
        let { PostalCode } = this.state;
        if (keyCode === 8) {
            if (PostalCode.length === 6) {
                PostalCode = PostalCode.replace('-', '');
            }
            this.setState({ PostalCode });
        }
    }

    handleContactNoCheck = event => {
        try {
            let { value } = event.target;
            let contactVal = value;
            if (value.length === 2) {
                contactVal = `(${contactVal})`;
            } else if (value.length === 8) {
                contactVal = `${contactVal}-`;
            } else if (value.length === 14) {
                const c1 = contactVal.replace('-', '');
                const cArr = c1.split(')');
                let cVal = `${cArr[0]})`;
                cArr[1].split('').forEach((v, i) => {
                    cVal = `${cVal}${v}${((i === 4) ? '-' : '')}`
                });
                contactVal = cVal;
            }

            if (value.length === 3 && !value.includes('(')) {
                contactVal = '(';
                for (const a of value) {
                    contactVal = `${contactVal}${a}${(contactVal.length === 2) ? ')' : ''}`;
                }
            }
            if (value.length === 9 && !value.includes('-')) {
                contactVal = '';
                for (const a of value) {
                    contactVal = `${contactVal}${a}${(contactVal.length === 7) ? '-' : ''}`;
                }
            }
            value = contactVal;

            this.setState({ ContactNumber: value });
            this.props.handleClick(event);
        } catch (e) {
            console.log('error in contact number validation ', e.message);
        }
    }

    handleContactNoKeyUp = event => {
        const { keyCode } = event;
        let { ContactNumber } = this.state;
        if (keyCode === 8) {
            if (ContactNumber.length === 9) {
                ContactNumber = ContactNumber.replace('-', '');
            } else if (ContactNumber.length === 4) {
                ContactNumber = ContactNumber.replace(')', '').replace('(', '');
            } else if (ContactNumber.length === 13) {
                const c1 = ContactNumber.replace('-', '');
                const cArr = c1.split(')');
                let cVal = `${cArr[0]})`;
                cArr[1].split('').forEach((v, i) => {
                    cVal = `${cVal}${v}${((i === 3) ? '-' : '')}`
                });
                ContactNumber = cVal;
            }
            this.setState({ ContactNumber });
        }
    }

    handleBirthDateCheck = event => {
        let { value } = event.target;
        let dtVal = value;
        if (value.length === 2 || value.length === 5) {
            dtVal = `${dtVal}/`;
        }
        if ((value.length === 3 && !value.includes('/')) || (value.length === 6 && (value.match(/\//g) || []).length < 2)) {
            dtVal = '';
            for (const a of value) {
                dtVal = `${dtVal}${a}${((dtVal.length === 1 && !value.includes('/')) || dtVal.length === 4) ? '/' : ''}`
            }
        }
        value = dtVal;
        this.setState({ DateOfBirth: value });
        this.props.handleClick(event);
    }

    handleBirthDateKeyUp = event => {
        const { keyCode } = event;
        let { DateOfBirth } = this.state;
        if (keyCode === 8) {
            if (DateOfBirth.length === 6 || DateOfBirth.length === 3) {
                DateOfBirth = DateOfBirth.replace(/\/([^/]*)$/, '$1');
            }
            this.setState({ DateOfBirth });
        }
    }

    handleDispatchDateCheck = event => {
        let { value } = event.target;
        let dtVal = value;
        if (value.length === 2 || value.length === 5) {
            dtVal = `${dtVal}/`;
        }
        if ((value.length === 3 && !value.includes('/')) || (value.length === 6 && (value.match(/\//g) || []).length < 2)) {
            dtVal = '';
            for (const a of value) {
                dtVal = `${dtVal}${a}${((dtVal.length === 1 && !value.includes('/')) || dtVal.length === 4) ? '/' : ''}`
            }
        }
        value = dtVal;
        this.setState({ DateOfDispatch: value });
        this.props.handleClick(event);
    }

    handleDispatchDateKeyUp = event => {
        const { keyCode } = event;
        let { DateOfDispatch } = this.state;
        if (keyCode === 8) {
            if (DateOfDispatch.length === 6 || DateOfDispatch.length === 3) {
                DateOfDispatch = DateOfDispatch.replace(/\/([^/]*)$/, '$1');
            }
            this.setState({ DateOfDispatch });
        }
    }

    validateDate = date => {
        const d = date.split('/');
        const date1 = new Date(`${d[2]}-${d[1]}-${d[0]}`);
        if (Object.prototype.toString.call(date1) === "[object Date]") {
            if (isNaN(date1.getTime())) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    handleDispatchDateChange = (date) => {
        const newState = { ...this.state };
        newState.DateOfDispatch = date;
        newState.errors.DateOfDispatch.isError = this.validateData(newState.errors.DateOfDispatch, date);
        this.setState(newState);
        this.props.handleDateChange(date, 'DateOfDispatch');
    }

    handleBirthDateChange = date => {
        const newState = { ...this.state };
        newState.DateOfBirth = date;
        newState.errors.DateOfBirth.isError = this.validateData(newState.errors.DateOfBirth, date);
        this.setState(newState);
        this.props.handleDateChange(date, 'DateOfBirth');
    }

    handleAutoComplete = (e, eObj, fi) => {
        if (typeof e === 'object') {
            const { value } = e.target;
            this.props.handleClick(e);
            const pIndex = e.target.getAttribute('fi');
            const updateProfessionList = this.props.formData.ProfessionList.filter(pr => pr.name.toLowerCase().includes(value.toLowerCase()));
            if (typeof pIndex !== "undefined" && pIndex !== null) {
                const newState = { ...this.state };
                newState.ProfessionListF[pIndex] = updateProfessionList;
                this.setState(newState);
            } else {
                this.setState({ ProfessionList: updateProfessionList });
            }
        } else {
            const additionalObj = {
                jsonvalue: (typeof fi !== "undefined" && fi !== null) ? `FamilyMembers,${fi},ProfessionF` : 'Owner,Profession',
                level: (typeof fi !== "undefined" && fi !== null) ? '3' : '2',
            }
            if (typeof fi !== "undefined" && fi !== null) {
                const newState = { ...this.state };
                newState.ProfessionListF[fi] = [];
                this.setState(newState);
            } else {
                this.setState({ ProfessionList: [] });
            }
            this.props.handleClick(eObj, additionalObj);
        }
    }

    handleAgencyAutoComplete = (e, eObj) => {
        if (typeof e === 'object') {
            const { value } = e.target;
            this.props.handleClick(e);
            const updateIssuingAgency = this.props.formData.StateList.filter(pr => pr.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({ IssuingAgency: updateIssuingAgency });
        } else {
            const additionalObj = {
                jsonvalue: 'Owner,IssuingAgency',
                level: '2'
            }
            this.setState({ IssuingAgency: [] });
            this.props.handleClick(eObj, additionalObj);
        }
    }

    addMember = () => {
        const newState = { ...this.state };
        newState.DropdownItems.Relationship.push({ show: false });
        newState.DropdownItems.GenderF.push({ show: false });
        newState.DropdownItems.SmartHealthOptionsF.push({ show: false });
        // newState.DropdownItems.ImpairedLifeOptionsF.push({ show: false });
        newState.DropdownItems.DiabetesOptionsF.push({ show: false });
        newState.DropdownItems.HyperTensionOptionsF.push({ show: false });
        newState.ProfessionListF.push([]);
        this.setState(newState);
        this.props.addMember();
    }

    render() {
        const { formData = {}, currentUser = {}, handleClick, removeMember, detailsToShow = {}, FamilyMemberError, language } = this.props;
        console.log("This is formData", formData)
        const { errors, ProfessionList, ProfessionListF, IssuingAgency } = this.state;
        const _LNG = CONTENT[language] || {};
        const defaultMsg = detailsToShow ? detailsToShow.default : ''
        let totalPremium = (detailsToShow && detailsToShow.Premium) ? `${BrlCurrency(detailsToShow.Premium)} ${_LNG.PER_MONTH}` : defaultMsg;
        if ((currentUser.Product === PAGE_CONST.FUNERAL) && (detailsToShow.AD.value)) {
            const fMsg = (detailsToShow && detailsToShow.Premium) ? `${totalPremium}<br /> + <br />` : '';
            totalPremium = `${fMsg}Accidental death and disability - ${detailsToShow.AD.Desc}`;
        }
        const OwnerInfo = (currentUser.Owner) ? currentUser.Owner : {};
        const OwnerContact = (OwnerInfo.Contact) ? OwnerInfo.Contact : {}
        return (
            <div className="new-policy-form">
                <div className="container-center">
                    <div className="vertical-div">
                        <div className="panel-heading">
                            <h2 className="page-title">{_LNG.HEADER_TITLE}</h2>
                        </div>
                        <div className="panel panel-default form-panel">
                            <div className="panel-body">
                                <form className="form-horizontal ">

                                    <FormInput type="select" id="Product" name="Product" label={_LNG.PRODUCT} values={formData.products}
                                        handleChange={this.handleValueSelect} value={detailsToShow.Product} jsonvalue="Product" level="1" error={errors.Product}
                                        handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                    <FormInput type="select" id="Plan" name="Plan" label={_LNG.PLAN} values={formData.plans}
                                        handleChange={this.handleValueSelect} value={detailsToShow.Plan} jsonvalue="Plan" level="1" error={errors.Plan}
                                        handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                    {
                                        detailsToShow.Product !== PAGE_CONST.DD && (
                                            <FormInput type="select" id="SumInsured" name="SumInsured" label={_LNG.SUM_INSURED}
                                                values={formData.faceAmount} value={BrlCurrency(currentUser.SumInsured)} handleChange={this.handleValueSelect}
                                                jsonvalue="SumInsured" level="1" error={errors.SumInsured}
                                                handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />
                                        )
                                    }

                                    {
                                        detailsToShow.Product === PAGE_CONST.DD && (
                                            <FormInput type="text" id="SumInsured" name="SumInsured" placeholder="Enter the amount here"
                                                label={_LNG.SUM_INSURED} value={currentUser.SumInsured} handleChange={handleClick}
                                                jsonvalue="SumInsured" level="1" error={errors.SumInsured} language={language} />
                                        )
                                    }

                                    {
                                        (detailsToShow.Product === PAGE_CONST.BUPA || detailsToShow.Product === PAGE_CONST.RIA) && (
                                            <React.Fragment>
                                                <FormInput type="select" id="FamilyCombination" name="FamilyCombination" label={_LNG.FAMILY_COMBINATION} values={formData.FamilyCombination}
                                                    handleChange={this.handleValueSelect} value={currentUser.FamilyCombination} jsonvalue="FamilyCombination" level="1" error={errors.FamilyCombination}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="TermOptions" name="TermOptions" label="Premium Term" values={formData.TermOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.TermOptions} jsonvalue="TermOptions" level="1" error={errors.TermOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="PayOptions" name="PayOptions" label="Premium Payment Mode" values={formData.PayOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.PayOptions} jsonvalue="PayOptions" level="1" error={errors.PayOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="StaffOptions" name="StaffOptions" label="Staff Discount" values={formData.StaffOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.StaffOptions} jsonvalue="StaffOptions" level="1" error={errors.StaffOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="SalesOptions" name="SalesOptions" label="Sale Channel" values={formData.SalesOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.SalesOptions} jsonvalue="SalesOptions" level="1" error={errors.SalesOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="Locality" name="Locality" label="City" values={formData.CityOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.CityOptions} jsonvalue="CityOptions" level="1" error={errors.CityOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                            </React.Fragment>
                                        )
                                    }
                                    {/* <FormInput label={_LNG.PREMIUM} source="review" reviewvalue={totalPremium} /> */}

                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="vertical-div">
                        <div className="panel panel-default form-panel">
                            <div className="panel-heading">
                                <h3 className="panel-title">{_LNG.PRIMARY_INSURED}</h3>
                            </div>
                            <div className="panel-body no-top-space">
                                <form className="form-horizontal ">

                                    <FormInput type="text" id="Name" name="Name" placeholder={_LNG.FULL_NAME_P} handleChange={handleClick}
                                        label={_LNG.FULL_NAME} jsonvalue="Owner,Name" level="2" error={errors.Name} onBlur={this.handleBlur}
                                        value={OwnerInfo.Name} language={language} />

                                    <FormInput type="text" id="GivenName" name="GivenName" placeholder={_LNG.FULL_NAME_P_L} handleChange={handleClick}
                                        label={_LNG.FULL_NAME_L} jsonvalue="Owner,GivenName" level="2" error={errors.GivenName} onBlur={this.handleBlur}
                                        value={OwnerInfo.GivenName} language={language} />

                                    <FormInput type="text" id="DateOfBirth" name="DateOfBirth" placeholder={_LNG.DATE_OF_BIRTH_P} label={_LNG.DATE_OF_BIRTH}
                                        value={this.state.DateOfBirth} onKeyUp={this.handleBirthDateKeyUp} handleChange={this.handleBirthDateCheck}
                                        jsonvalue="Owner,DateOfBirth" level="2" error={errors.DateOfBirth} onBlur={this.handleBlur} maxLength="10" language={language} />

                                    {
                                        (detailsToShow.Product !== PAGE_CONST.BUPA && detailsToShow.Product !== PAGE_CONST.RIA) && (
                                            <FormInput type="text" id="CPF" name="CPF" placeholder={_LNG.CPF_P} label={_LNG.CPF} value={this.state.CPF}
                                                onKeyUp={this.handleCpfKeyUp} handleChange={this.handleCpfCheck} jsonvalue="Owner,CPF" level="2" error={errors.CPF}
                                                onBlur={this.handleBlur} maxLength="14" language={language} />
                                        )
                                    }

                                    <FormInput type="select" id="SmartHealthOptions" name="SmartHealthOptions" label="Smart Health" values={formData.SmartHealthOptions}
                                        handleChange={this.handleValueSelect} value={currentUser.SmartHealthOptions} jsonvalue="SmartHealthOptions" level="1" error={errors.SmartHealthOptions}
                                        handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                    {
                                        currentUser.SmartHealthOptions === 'Y' && (
                                            <React.Fragment>
                                                <FormInput type="number" id="ImpairedLifeOptions" name="ImpairedLifeOptions" placeholder="e.g. 0% - 350%" handleChange={handleClick}
                                                    label="Impaired Life" jsonvalue="ImpairedLifeOptions" level="1" error={errors.ImpairedLifeOptions} onBlur={this.handleBlur}
                                                    value={currentUser.ImpairedLifeOptions} language={language} />

                                                {/* <FormInput type="select" id="ImpairedLifeOptions" name="ImpairedLifeOptions" label="Impaired Life" values={formData.ImpairedLifeOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.ImpairedLifeOptions} jsonvalue="ImpairedLifeOptions" level="1" error={errors.ImpairedLifeOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} /> */}

                                                <FormInput type="select" id="DiabetesOptions" name="DiabetesOptions" label="Diabetes" values={formData.DiabetesOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.DiabetesOptions} jsonvalue="DiabetesOptions" level="1" error={errors.DiabetesOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />

                                                <FormInput type="select" id="HyperTensionOptions" name="HyperTensionOptions" label="Hyper Tension" values={formData.HyperTensionOptions}
                                                    handleChange={this.handleValueSelect} value={currentUser.HyperTensionOptions} jsonvalue="HyperTensionOptions" level="1" error={errors.HyperTensionOptions}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />
                                            </React.Fragment>
                                        )
                                    }

                                    <FormInput type="text" id="Profession" name="Profession" placeholder={_LNG.PROFESSION_P} handleChange={handleClick}
                                        label={_LNG.PROFESSION} jsonvalue="Owner,Profession" level="2" error={errors.Profession} onBlur={this.handleBlur}
                                        value={OwnerInfo.Profession} language={language} />

                                    {
                                        (currentUser.Product === PAGE_CONST.FUNERAL || detailsToShow.Product === PAGE_CONST.DD) && (
                                            <React.Fragment>
                                                <FormInput type="select" id="Gender" name="Gender" label={_LNG.GENDER} placeholder={_LNG.GENDER_P}
                                                    values={formData.Gender} value={OwnerInfo.Gender} handleChange={this.handleValueSelect}
                                                    jsonvalue="Owner,Gender" level="2" error={errors.Gender}
                                                    handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems} language={language} />
                                                {
                                                    (currentUser.Product === PAGE_CONST.FUNERAL) && (
                                                        <FormInput type="autocomplete" id="Profession" name="Profession" placeholder={_LNG.PROFESSION_P}
                                                            label={_LNG.PROFESSION} handleChange={this.handleAutoComplete} onBlur={this.handleBlur} value={OwnerInfo.Profession}
                                                            jsonvalue="Owner,Profession" level="2" error={errors.Profession} values={ProfessionList} language={language} />
                                                    )
                                                }

                                            </React.Fragment>
                                        )
                                    }

                                    {
                                        (detailsToShow.Product !== PAGE_CONST.BUPA && detailsToShow.Product !== PAGE_CONST.RIA) && (
                                            <React.Fragment>
                                                <FormInput type="text" id="SSN" name="SSN" placeholder={_LNG.RG_P} label={_LNG.RG} value={this.state.SSN}
                                                    jsonvalue="Owner,SSN" level="2" error={errors.SSN} onBlur={this.handleBlur}
                                                    onKeyUp={this.handleSsnKeyUp} handleChange={this.handleSsnCheck} maxLength="12" language={language} />

                                                <FormInput type="text" id="DateOfDispatch" name="DateOfDispatch" placeholder={_LNG.DATE_OF_ISSUE_P} label={_LNG.DATE_OF_ISSUE}
                                                    value={this.state.DateOfDispatch} onKeyUp={this.handleDispatchDateKeyUp} handleChange={this.handleDispatchDateCheck}
                                                    jsonvalue="Owner,DateOfDispatch" level="2" error={errors.DateOfDispatch} onBlur={this.handleBlur} maxLength="10" language={language} />

                                                <FormInput type="autocomplete" id="IssuingAgency" name="IssuingAgency" label={_LNG.ISSUING_BODY} placeholder={_LNG.ISSUING_BODY_P}
                                                    values={IssuingAgency} handleChange={this.handleAgencyAutoComplete} onBlur={this.handleBlur} value={OwnerInfo.IssuingAgency}
                                                    jsonvalue="Owner,IssuingAgency" level="2" error={errors.IssuingAgency} language={language} />
                                            </React.Fragment>
                                        )
                                    }

                                    {/* <FormInput type="text" id="Locality" name="Locality" placeholder={_LNG.CITY_P} label={_LNG.CITY} onBlur={this.handleBlur}
                                        value={OwnerContact.Locality} handleChange={handleClick} jsonvalue="Owner,Contact,Locality"
                                        level="3" error={errors.Locality} language={language} /> */}
                                </form>

                                <form className="form-horizontal">
                                    {
                                        ((formData.members === 4 && currentUser.Product === PAGE_CONST.FUNERAL) || (formData.members > 1 && (currentUser.Product === PAGE_CONST.BUPA || currentUser.Product === PAGE_CONST.RIA))) && (
                                            <React.Fragment>
                                                <div className="form-group form-section">
                                                    <label className="col-sm-12 control-label">{_LNG.ADDRESS}</label>
                                                </div>
                                                <FormInput type="text" id="PostalCode" name="PostalCode" placeholder={_LNG.CEP_P}
                                                    label={_LNG.CEP} jsonvalue="Owner,Contact,PostalCode" level="3"
                                                    error={errors.PostalCode} onBlur={this.handleBlur} value={this.state.PostalCode}
                                                    handleChange={this.handlePostalCheck} maxLength="9" language={language} />

                                                {/* <FormInput type="text" id="Locality" name="Locality" placeholder={_LNG.CITY_P} label={_LNG.CITY} onBlur={this.handleBlur}
                                                    value={OwnerContact.Locality} handleChange={handleClick} jsonvalue="Owner,Contact,Locality"
                                                    level="3" error={errors.Locality} language={language} /> */}

                                                <FormInput type="text" id="StreetHouseNo" name="StreetHouseNo" placeholder={_LNG.STREET_AND_NUMBER_P}
                                                    label={_LNG.STREET_AND_NUMBER} handleChange={handleClick} onBlur={this.handleBlur}
                                                    value={OwnerContact.StreetHouseNo} jsonvalue="Owner,Contact,StreetHouseNo"
                                                    level="3" error={errors.StreetHouseNo} language={language} />

                                                <FormInput type="text" id="ContactNumber" name="ContactNumber" placeholder={_LNG.CONTACT_PHONE_P}
                                                    label={_LNG.CONTACT_PHONE} jsonvalue="Owner,ContactNumber" level="2"
                                                    error={errors.ContactNumber} onBlur={this.handleBlur} maxLength="14"
                                                    onKeyUp={this.handleContactNoKeyUp} handleChange={this.handleContactNoCheck}
                                                    value={this.state.ContactNumber} language={language} />

                                                <FormInput type="email" id="Email" name="Email" placeholder={_LNG.EMAIL_P} label={_LNG.EMAIL}
                                                    handleChange={handleClick} jsonvalue="Owner,Email" level="2"
                                                    value={OwnerInfo.Email} error={errors.Email} onBlur={this.handleBlur} language={language} />
                                                <div className="form-group form-info ">
                                                    <div className="col-sm-12 info-label">
                                                        <label>{_LNG.POLICY_PREMIUM}</label>
                                                        {(detailsToShow.basePremium) ? `${BrlCurrency(detailsToShow.basePremium)} ${_LNG.PER_MONTH}` : detailsToShow.default}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>

                    {
                        (!(formData.members === 4 && currentUser.Product === PAGE_CONST.FUNERAL) && !(formData.members > 1 && (currentUser.Product === PAGE_CONST.BUPA || currentUser.Product === PAGE_CONST.RIA))) && (
                            <div className="vertical-div">
                                <div className="panel panel-default form-panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">{_LNG.ADDRESS}</h3>
                                    </div>
                                    <div className="panel-body no-top-space">
                                        <form className="form-horizontal ">
                                            <FormInput type="text" id="PostalCode" name="PostalCode" placeholder={_LNG.CEP_P}
                                                label={_LNG.CEP} jsonvalue="Owner,Contact,PostalCode" level="3"
                                                error={errors.PostalCode} onBlur={this.handleBlur} value={this.state.PostalCode}
                                                handleChange={this.handlePostalCheck} maxLength="9" language={language} />

                                            {/*// onKeyUp={this.handlePostalKeyUp} handleChange={this.handlePostalCheck} maxLength="9" language={language} />

                                             <FormInput type="text" id="Locality" name="Locality" placeholder={_LNG.CITY_P} label={_LNG.CITY} onBlur={this.handleBlur}
                                                value={OwnerContact.Locality} handleChange={handleClick} jsonvalue="Owner,Contact,Locality"
                                                level="3" error={errors.Locality} language={language} /> */}

                                            <FormInput type="text" id="StreetHouseNo" name="StreetHouseNo" placeholder={_LNG.STREET_AND_NUMBER_P}
                                                label={_LNG.STREET_AND_NUMBER} handleChange={handleClick} onBlur={this.handleBlur}
                                                value={OwnerContact.StreetHouseNo} jsonvalue="Owner,Contact,StreetHouseNo"
                                                level="3" error={errors.StreetHouseNo} language={language} />

                                            <FormInput type="text" id="ContactNumber" name="ContactNumber" placeholder={_LNG.CONTACT_PHONE_P}
                                                label={_LNG.CONTACT_PHONE} jsonvalue="Owner,ContactNumber" level="2"
                                                error={errors.ContactNumber} onBlur={this.handleBlur} maxLength="14"
                                                onKeyUp={this.handleContactNoKeyUp} handleChange={this.handleContactNoCheck}
                                                value={this.state.ContactNumber} language={language} />

                                            <FormInput type="email" id="Email" name="Email" placeholder={_LNG.EMAIL_P} label={_LNG.EMAIL}
                                                handleChange={handleClick} jsonvalue="Owner,Email" level="2"
                                                value={OwnerInfo.Email} error={errors.Email} onBlur={this.handleBlur} language={language} />

                                            <div className="form-group form-info ">
                                                <div className="col-sm-12 info-label">
                                                    <label>{_LNG.POLICY_PREMIUM}</label>
                                                    {(detailsToShow.basePremium) ? `${BrlCurrency(detailsToShow.basePremium)} ${_LNG.PER_MONTH}` : detailsToShow.default}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        ((formData.members === 4 && currentUser.Product === PAGE_CONST.FUNERAL) || (formData.members > 1 && (currentUser.Product === PAGE_CONST.BUPA || currentUser.Product === PAGE_CONST.RIA))) && (
                            <div className="vertical-div">
                                <div className="panel panel-default form-panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">{_LNG.FAMILY_MEMBERS}</h3>
                                    </div>
                                    <div className="panel-body">
                                        <form className="form-horizontal">
                                            {
                                                currentUser.FamilyMembers.map((member, i) => {
                                                    return (
                                                        <div key={member.index}>
                                                            <div className="form-group form-section">
                                                                <label className="col-sm-6 control-label">{_LNG.MEMBER} {i + 2}</label>
                                                            </div>
                                                            <FormInput type="text" id={`Name-${i}`} name="Name" handleChange={handleClick}
                                                                placeholder="Full Name" label={_LNG.FULL_NAME} jsonvalue={`FamilyMembers,${i},Name`} level="3"
                                                                value={currentUser.FamilyMembers[i].Name} error={FamilyMemberError[i].Name}
                                                                onBlur={this.handleFamilyBlur} fi={i} language={language} />

                                                            <FormInput type="select" id={`Relationship-${i}`} name={`Relationship`} label={_LNG.RELATIONSHIP}
                                                                placeholder={_LNG.RELATIONSHIP_P} values={formData.FamilyRelations} jsonvalue={`FamilyMembers,${i},Relationship`}
                                                                level="3" handleChange={this.handleValueSelect} value={currentUser.FamilyMembers[i].Relationship}
                                                                handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems.Relationship}
                                                                error={FamilyMemberError[i].Relationship} fi={i} language={language} />


                                                            <FormInput type="text" id={`Age-${i}`} name="Age" label={_LNG.AGE} handleChange={handleClick}
                                                                placeholder="Age" jsonvalue={`FamilyMembers,${i},Age`} level="3" error={FamilyMemberError[i].Age}
                                                                onBlur={this.handleFamilyBlur} fi={i} value={currentUser.FamilyMembers[i].Age} language={language} />

                                                            <FormInput type="select" id={`GenderF-${i}`} name="GenderF" label={_LNG.GENDER} placeholder={_LNG.GENDER_P}
                                                                values={formData.Gender} value={currentUser.FamilyMembers[i].GenderF} handleChange={this.handleValueSelect}
                                                                jsonvalue={`FamilyMembers,${i},GenderF`} level="3" error={FamilyMemberError[i].GenderF}
                                                                handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems.GenderF} fi={i}
                                                                language={language} />

                                                            <FormInput type="autocomplete" id={`ProfessionF-${i}`} name="ProfessionF" placeholder={_LNG.PROFESSION_P}
                                                                label={_LNG.PROFESSION} handleChange={this.handleAutoComplete} onBlur={this.handleFamilyBlur}
                                                                value={currentUser.FamilyMembers[i].ProfessionF} jsonvalue={`FamilyMembers,${i},ProfessionF`} level="3"
                                                                error={FamilyMemberError[i].ProfessionF} values={ProfessionListF[i]} fi={i} language={language} />

                                                            <FormInput type="select" id={`SmartHealthOptionsF-${i}`} name="SmartHealthOptionsF" label="Smart Health"
                                                                values={formData.SmartHealthOptions} value={currentUser.FamilyMembers[i].SmartHealthOptionsF} handleChange={this.handleValueSelect}
                                                                jsonvalue={`FamilyMembers,${i},SmartHealthOptionsF`} level="3" error={FamilyMemberError[i].SmartHealthOptionsF}
                                                                handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems.SmartHealthOptionsF} fi={i}
                                                                language={language} />

                                                            {
                                                                currentUser.FamilyMembers[i].SmartHealthOptionsF === 'Y' && (
                                                                    <React.Fragment>
                                                                        <FormInput type="text" id={`ImpairedLifeOptionsF-${i}`} name="ImpairedLifeOptionsF" placeholder="e.g. 0% - 350%" handleChange={handleClick}
                                                                            label="Impaired Life" jsonvalue={`FamilyMembers,${i},ImpairedLifeOptionsF`} level="3" error={FamilyMemberError[i].ImpairedLifeOptionsF} onBlur={this.handleFamilyBlur}
                                                                            value={currentUser.FamilyMembers[i].ImpairedLifeOptionsF} fi={i} language={language} />

                                                                    
                                                                        <FormInput type="select" id={`DiabetesOptionsF-${i}`} name="DiabetesOptionsF" label="Diabetes"
                                                                            values={formData.DiabetesOptions} value={currentUser.FamilyMembers[i].DiabetesOptionsF} handleChange={this.handleValueSelect}
                                                                            jsonvalue={`FamilyMembers,${i},DiabetesOptionsF`} level="3" error={FamilyMemberError[i].DiabetesOptionsF}
                                                                            handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems.DiabetesOptionsF} fi={i} language={language} />

                                                                        <FormInput type="select" id={`HyperTensionOptionsF-${i}`} name="HyperTensionOptionsF" label="Hyper Tension"
                                                                            values={formData.HyperTensionOptions} value={currentUser.FamilyMembers[i].HyperTensionOptionsF} handleChange={this.handleValueSelect}
                                                                            jsonvalue={`FamilyMembers,${i},HyperTensionOptionsF`} level="3" error={FamilyMemberError[i].HyperTensionOptionsF}
                                                                            handlefocus={this.handleDropdownClick} dropdownitemstatus={this.state.DropdownItems.HyperTensionOptionsF} fi={i} language={language} />
                                                                    </React.Fragment>
                                                                )
                                                            }


                                                            <div className="form-group form-info">
                                                                <span className="col-sm-12 info-label">
                                                                    <label>{_LNG.PREMIUM} ({_LNG.MEMBER} {i + 2})</label>
                                                                    <label className="premium-info">
                                                                        {(member.Premium) ? `${BrlCurrency(member.Premium)} ${_LNG.PER_MONTH}` : detailsToShow.default}
                                                                    </label>
                                                                </span>
                                                            </div>

                                                            <div className="form-group form-section add-remove-buttons">
                                                                {
                                                                    (currentUser.FamilyMembers.length > 1) &&
                                                                    <div className="col-md-12">
                                                                        <button type="button" className="btn btn-default btn-block btn-common btn-secondary"
                                                                            onClick={() => removeMember(i)} button-index={i}>
                                                                            {_LNG.REMOVE}
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    ((i === currentUser.FamilyMembers.length - 1) && (i < (formData.members - 2))) &&
                                                                    <div className="col-md-12 add-button-container">
                                                                        <button type="button" className="btn btn-default btn-block btn-common btn-secondary" onClick={this.addMember}>
                                                                            {_LNG.ADD_MEMBER}
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {/* <div className="vertical-div">
                    <div className="panel panel-default form-panel">
                        <div className="panel-heading">
                            <h3 className="panel-title">{_LNG.TERMS_AND_CONDITIONS}</h3>
                        </div>
                        <div className="panel-body">
                            <p className="check-area">
                                <input type="checkbox" id="declaration-3" name="declaration-3" value="" />
                                <label htmlFor="declaration-3" onClick={this.TermsAndCondition} className={(!this.state.accepted && this.state.clickedSubmit) ? 'error' : ''}>
                                    {_LNG.TERMS} <br />
                                </label>
                            </p>
                            <div className="term-link">
                                <Link className='option' to={{
                                    pathname: '/declaration',
                                    search: `?lang=${language}`
                                }}>{_LNG.TERMS_AND_CONDITIONS}</Link>
                            </div>
                        </div>
                    </div>
                    {
                        (!this.state.accepted && this.state.clickedSubmit) && (
                            <div className="panel error-panel">
                                <div className="">
                                    <p>{this.state.termsError[language]}</p>
                                </div>
                            </div>
                        )
                    }
                </div> */}
                </div>


                <div className="row footer-button">
                    <div className="button-style">
                        <button type="button" className="btn btn-block btn-next" onClick={this.onSubmit}>{_LNG.CONTINUE}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewPolicy;
