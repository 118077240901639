import React from 'react';
import { Link } from "react-router-dom";
import FormInput from '../../components/html-elements/form-input/form-input.component';
import { BrlCurrency, CurrentDateTime, DDValues } from '../../utils/prepare-data';
import { CONTENT } from '../../utils/page-content';
import './policy-review.styles.scss';


class PolicyReview extends React.Component {
    constructor(props) {
        super(props);
        const { currentUser, detailsToShow, formData, policyId } = props
        this.state = {
            showSuccess: true,
            currentUser,
            detailsToShow,
            formData,
            policyId
        }
    }

    componentDidMount = () => {
        this.props.setPage('policyReview');
    }

    onDismiss = () => {
        this.setState({ showSuccess: false });
    }

    render() {
        const { currentUser, detailsToShow, policyId } = this.state;
        const _LNG = CONTENT[this.props.language];
        const ddAmount = DDValues[currentUser.PayFreq][detailsToShow.AD.value] || 0;
        return (
            <div className="policy-review-form">
                <div className="container-center">
                    {
                        (this.state.showSuccess) && (
                            <div className="row success-msg success-msg-mobile">
                                <div className="background-success">
                                    <div className="message-box">
                                        <span className="glyphicon glyphicon-ok-circle success-icon"></span>
                                        <span className="message">
                                            {_LNG.SUCCESS_MSG_MOBILE}
                                        </span>
                                    </div>
                                    <div className="dismiss-box">
                                        <span className="payment-timestamp">
                                            {CurrentDateTime()}
                                        </span>
                                        <span className="dismiss" onClick={this.onDismiss}>
                                            Dismiss
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="vertical-div">
                        <div className="panel panel-default form-panel">
                            <div className="panel-body payment-reference-container">
                                <div className="row review-row">
                                    <div className="col-md-6 text-bold">
                                        {detailsToShow.Product}
                                    </div>
                                    <div className="col-md-6 second-col">
                                        {detailsToShow.Plan}
                                    </div>
                                </div>

                                <div className="row review-row">
                                    <div className="col-sm-6">
                                        <FormInput label={_LNG.SUM_INSURED} source="review" reviewvalue={`${BrlCurrency(currentUser.SumInsured)}`} />
                                    </div>
                                    <div className="col-sm-6 second-col">
                                        <FormInput label={_LNG.PREMIUM} source="review" reviewvalue={`${BrlCurrency(detailsToShow.headPremium)} ${_LNG.PREMIUM}`} />
                                    </div>
                                </div>

                                <div className="row review-row">
                                    <div className="col-md-6">
                                        <FormInput label={_LNG.PER_FREQUENCY} 
                                        source="review" 
                                        reviewvalue={(currentUser.PayFreq === '12') ? _LNG.MONTHLY_1 : ((currentUser.PayFreq === '1') ? _LNG.ANNUAL_1 : _LNG.SINGLE_PREMIUM)} />
                                    </div>
                                    {
                                        (detailsToShow.Product === 'Accidental and Funeral bundle') && (
                                            <div className="col-sm-6 second-col">
                                                <FormInput label="DD Charges" source="review" reviewvalue={`${BrlCurrency(ddAmount)}`} />
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    (currentUser.PaymentInfo.Bank.BankName && currentUser.PayFreq === '12') && (
                                        <div className="row review-row">
                                            <div className="col-md-6">
                                                <FormInput label={_LNG.PAYMENT_DAY1} source="review" reviewvalue={`${currentUser.PaymentInfo.Bank.PaymentDay} ${_LNG.PAYMENT_DAY2}`} />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="row review-row">
                                    <div className="col-sm-6">
                                        <FormInput label={_LNG.PAYMENT_CONFIRMATION} source="review" reviewvalue={_LNG.CYBERSOURCE} />
                                    </div>
                                    <div className="col-sm-6 second-col">
                                        <FormInput label={_LNG.PAYMENT_REFERENCE} source="review" reviewvalue={currentUser.PaymentReferenceNo} />
                                    </div>
                                </div>

                                <div className="row review-row">
                                    <div className="col-sm-6">
                                        <FormInput label={_LNG.APPLICATION_ID} source="review" reviewvalue={policyId} />
                                    </div>
                                    <div className="col-sm-6 second-col">
                                        <FormInput label={_LNG.CHANNEL_NAME} source="review" reviewvalue="000000000000" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {
                        (this.state.showSuccess) && (
                            <div className="row success-msg success-msg-desktop">
                                <div className="background-success">
                                    <span className="glyphicon glyphicon-ok-circle success-icon"></span>
                                    <span className="message">
                                        {_LNG.POLICY_SUCCESS_MESSAGE}
                                    </span>
                                    <div className="dismiss-box">
                                        <span className="payment-timestamp">
                                            {CurrentDateTime()}
                                        </span>
                                        <span className="dismiss" onClick={this.onDismiss}>
                                            Dismiss
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="row footer-button">
                    <div className="button-style">
                        <button type="button" className="btn btn-block btn-save" onClick={() => window.print()}>{_LNG.SAVE_AS_SHARE}</button>
                    </div>
                    <div className="button-style new-policy-button">
                        <Link className='option' to={{
                            pathname: '/home',
                            search: `?lang=${this.props.language}`
                        }}>
                            <button type="button" className="btn btn-block btn-next">{_LNG.MAKE_NEW_HIRE}</button>
                        </Link>
                    </div>
                </div>

            </div>
        );
    }
}

export default PolicyReview;
