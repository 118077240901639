const PT_DATE_FORMAT = 'DD/MM/AAAA';
const EN_DATE_FORMAT = 'DD/MM/YYYY';
const JP_DATE_FORMAT = 'DD/MM/YYYY'
export const CONTENT = {
    EN: {
        HEADER_TITLE: 'Proposal Details',
        PRODUCT: 'Product',
        PLAN: 'Plan',
        FAMILY_COMBINATION: 'Family Combination',
        PREMIUM: 'Premium',
        POLICY_SUCCESS_MESSAGE: 'The insurance policy application was submitted for subscription for approval on',
        SUCCESS_MSG_MOBILE: 'New insurance claim has been submitted',
        DEFAULT_MESSAGE: 'Complete the data to obtain the value',
        PER_MONTH: 'per month',
        SUM_INSURED: 'Sum Insured',
        INSURED: 'Insured',
        FULL_NAME: 'First Name',
        FULL_NAME_P: 'e.g. John',
        FULL_NAME_L: 'Last Name',
        FULL_NAME_P_L: 'e.g. Smith',
        DATE_OF_BIRTH: 'Date of birth',
        DATE_OF_BIRTH_P: EN_DATE_FORMAT,
        CPF: 'CPF xxx.xxx.xxx-xx',
        CPF_P: 'CPF of the insured',
        RG: 'RG xx.xxx.xxx-x',
        RG_P: 'Policyholder ID',
        DATE_OF_ISSUE: 'Date of issue',
        DATE_OF_ISSUE_P: EN_DATE_FORMAT,
        ISSUING_BODY: 'Issuing body',
        ISSUING_BODY_P: 'Issuing body',
        ADDRESS: 'Address',
        CEP: 'Postcode',
        CEP_P: 'Postcode',
        CITY: 'City',
        CITY_P: 'Enter City first',
        STREET_AND_NUMBER: 'Area, Locality & Street',
        STREET_AND_NUMBER_P: 'Enter Address`',
        PROFESSION: 'Occupation',
        PROFESSION_P: 'e.g Doctor',
        CONTACT_PHONE: 'Contact phone (xx)xxxx-xxxx',
        CONTACT_PHONE_P: '(xx)xxxx-xxxx',
        EMAIL: 'E-mail',
        EMAIL_P: 'Insured email-id ',
        POLICY_PREMIUM: 'Policy premium',
        FAMILY_MEMBERS: 'Insured family members',
        MEMBER: 'Member',
        RELATIONSHIP: 'Relationship',
        RELATIONSHIP_P: 'Relationship',
        AGE: 'Age',
        ADD_MEMBER: 'Add member',
        REMOVE: 'Remove',
        TERMS_AND_CONDITIONS: 'Terms and conditions',
        TERMS: 'I declare that I have read and agree with the',
        CONFIRM_ORDER: 'Confirm order',
        DECLARATION: 'Declaration',
        HEREBY_DECLARE: 'I HEREBY DECLARE AND AGREE THAT',
        BACK_TO_APPLICATION: 'Back to application',
        PAYMENT_INFORMATION: 'Payment Information',
        FORM_OF_PAYMENT: 'Form of payment',
        AUTOMATIC_DEBIT: 'Automatic debit',
        CREDIT_CARD: 'Credit card',
        BANK: 'Bank Name',
        BANK_P: 'Bank Name',
        BANK_AGENCY: 'Bank Agency',
        BANK_AGENCY_P: 'Bank Agency',
        CHECKING_ACCOUNT: 'Account Number',
        CHECKING_ACCOUNT_P: 'Account Number',
        PAYMENT_DAY: 'Payment Day',
        PAYMENT_DAY_P: 'Payment Day',
        MONTHLY: '/month',
        ANNUAL: '/year',
        CARD_NUMBER: 'Card Number',
        CARD_NUMBER_P: 'Card Number',
        VALIDITY: 'Expiration of card MM/YYYY',
        VALIDITY_P: 'MM/YYYY',
        FREQUENCY: 'Freqency',
        CONFIRM_PLACE_ORDER: 'Confirm and place order',
        PLEASE_SELECT_FREQ: 'Please select payment frequency',
        APPLICATION_ID: 'Application ID',
        PRIMARY_INSURED: 'Primary insured',
        SAVE_AS_SHARE: 'Save / share',
        MAKE_NEW_HIRE: 'Create a new Contract',
        TOTAL_PREMIUM: 'Total Premium',
        CONTINUE: 'Continue',
        PAYMENT_CONFIRMATION: 'Payment confirmation',
        GENDER: 'Gender',
        GENDER_P: 'Gender',
        PER_FREQUENCY: 'Frequency',
        PAYMENT_DAY1: 'Payment day',
        CYBERSOURCE: 'Cybersource',
        PAYMENT_REFERENCE: 'Payment reference',
        CHANNEL_NAME: 'Channel name',
        PAYMENT_DAY2: 'of each month',
        SINGLE_PREMIUM: 'Single Premium',
        MONTHLY_1: 'Monthly',
        ANNUAL_1: 'Annual',
        PLEASE_SELECT_PAYMODE: 'Please choose a payment mode'
    },
    PT: {
        HEADER_TITLE: 'Dados do(s) segurado(s)',
        PRODUCT: 'Produto',
        PLAN: 'Plano',
        FAMILY_COMBINATION: 'Family Combination',
        PREMIUM: 'prêmio',
        POLICY_SUCCESS_MESSAGE: 'O pedido de apólice de seguro foi enviado para subscrição para aprovação em',
        SUCCESS_MSG_MOBILE: 'Novo pedido de seguro foi submetido',
        DEFAULT_MESSAGE: 'Complete os dados para obter a valor',
        PER_MONTH: 'por mês',
        SUM_INSURED: 'Capital Segurado',
        INSURED: 'Segurado',
        FULL_NAME: 'Nome completo do segurado',
        FULL_NAME_P: 'e.g. John',
        FULL_NAME_L: 'Full name of the insured',
        FULL_NAME_L_P: 'e.g. John',
        DATE_OF_BIRTH: 'Data de nascimento',
        DATE_OF_BIRTH_P: PT_DATE_FORMAT,
        CPF: 'CPF xxx.xxx.xxx-xx',
        CPF_P: 'CPF do segurado',
        RG: 'RG xx.xxx.xxx-x',
        RG_P: 'RG do segurado',
        DATE_OF_ISSUE: 'Data de emissão',
        DATE_OF_ISSUE_P: PT_DATE_FORMAT,
        ISSUING_BODY: 'Orgão Emissor ',
        ISSUING_BODY_P: 'Orgão Emissor',
        ADDRESS: 'Endereço',
        CEP: 'CEP xxxxx-xxx',
        CEP_P: 'CEP do segurado',
        CITY: 'Cidade',
        CITY_P: 'Insira a cidade primeiro',
        STREET_AND_NUMBER: 'Rua e número',
        STREET_AND_NUMBER_P: 'Insira o CEP primeiro',
        PROFESSION: 'Profissão',
        PROFESSION_P: 'Taxista',
        CONTACT_PHONE: 'Telefone de contato (xx)xxxx-xxxx',
        CONTACT_PHONE_P: '(xx)xxxx-xxxx',
        EMAIL: 'E-mail',
        EMAIL_P: 'Email-do segurado',
        POLICY_PREMIUM: 'Prêmio da apólice',
        FAMILY_MEMBERS: 'Membros da família segurados',
        MEMBER: 'Membro',
        RELATIONSHIP: 'Parentesco',
        RELATIONSHIP_P: 'Parentesco',
        AGE: 'Idade',
        ADD_MEMBER: 'Adicionar membro',
        REMOVE: 'Remove',
        TERMS_AND_CONDITIONS: 'Termos e condições',
        TERMS: 'Declaro que li e concordo com os',
        CONFIRM_ORDER: 'Confirmar pedido',
        HEREBY_DECLARE: 'DECLARO E CONCORDO QUE',
        BACK_TO_APPLICATION: 'Voltar ao aplicativo',
        PAYMENT_INFORMATION: 'Informação de pagamento',
        FORM_OF_PAYMENT: 'Forma de pagamento',
        AUTOMATIC_DEBIT: 'Débito automático',
        CREDIT_CARD: 'Cartão de crédito',
        BANK: 'Nome do banco',
        BANK_P: 'Nome do banco',
        BANK_AGENCY: 'Agencia do Banco',
        BANK_AGENCY_P: 'Agencia do Banco',
        CHECKING_ACCOUNT: 'Conta corrente',
        CHECKING_ACCOUNT_P: 'Conta Corrente',
        PAYMENT_DAY: 'Dia de pagamento',
        PAYMENT_DAY_P: 'Dia de pagamento',
        MONTHLY: '/mês',
        ANNUAL: '/ano',
        CARD_NUMBER: 'Número do cartão',
        CARD_NUMBER_P: 'Número do cartão',
        VALIDITY: 'Vencimento do cartão',
        VALIDITY_P: 'MM/AAAA',
        FREQUENCY: 'Frequência',
        CONFIRM_PLACE_ORDER: 'Confirme e faça o pedido',
        PLEASE_SELECT_FREQ: 'Selecione a frequência de pagamento',
        APPLICATION_ID: 'ID do aplicativo',
        PRIMARY_INSURED: 'Segurado primário',
        SAVE_AS_SHARE: 'Salvar / compartilhar',
        MAKE_NEW_HIRE: 'Fazer nova contratação',
        TOTAL_PREMIUM: 'Prêmio Total',
        CONTINUE: 'Continuar',
        PAYMENT_CONFIRMATION: 'Confirmação de pagamento',
        GENDER: 'Gênero',
        GENDER_P: 'Gênero',
        PER_FREQUENCY: 'Periodicidade',
        PAYMENT_DAY1: 'Dia para pagamento',
        CYBERSOURCE: 'Cybersource',
        PAYMENT_REFERENCE: 'referência de pagamento',
        CHANNEL_NAME: 'Nome do canal',
        PAYMENT_DAY2: 'de cada mês',
        SINGLE_PREMIUM: 'prêmio único',
        MONTHLY_1: 'Por mês',
        ANNUAL_1: 'Anual',
        PLEASE_SELECT_PAYMODE: 'Escolha um modo de pagamento'
    },
    JP: {
        HEADER_TITLE: '被保険者の情報',
        PRODUCT: '商品',
        PLAN: 'プラン',
        FAMILY_COMBINATION: 'Family Combination',
        PREMIUM: '保険料',
        POLICY_SUCCESS_MESSAGE: '保険契約申込書が提出されました',
        SUCCESS_MSG_MOBILE: '新たな保険金請求が行われました',
        DEFAULT_MESSAGE: '情報を入力してください',
        PER_MONTH: '1ヶ月あたり',
        SUM_INSURED: '保険金額',
        INSURED: '被保険者',
        FULL_NAME: '被保険者の姓名',
        FULL_NAME_P: '例：ジョン・スミス',
        DATE_OF_BIRTH: '生年月日',
        DATE_OF_BIRTH_P: JP_DATE_FORMAT,
        CPF: 'CPF xxx.xxx.xxx-xx',
        CPF_P: '被保険者のCPF(納税者番号)',
        RG: 'RG xx.xxx.xxx-x',
        RG_P: '契約者ID',
        DATE_OF_ISSUE: '発行年月日',
        DATE_OF_ISSUE_P: JP_DATE_FORMAT,
        ISSUING_BODY: '発行者',
        ISSUING_BODY_P: '発行者',
        ADDRESS: '住所',
        CEP: '郵便番号 xxxxx-xxx',
        CEP_P: '被保険者の郵便番号',
        CITY: '都市',
        CITY_P: '最初に都市を入力',
        STREET_AND_NUMBER: '町名と番地',
        STREET_AND_NUMBER_P: '最初に郵便番号を入力',
        PROFESSION: '職業',
        PROFESSION_P: '例：医者',
        CONTACT_PHONE: '連絡先電話番号 (xx)xxxx-xxxx',
        CONTACT_PHONE_P: '(xx)xxxx-xxxx',
        EMAIL: 'Eメール',
        EMAIL_P: '被保険者のメールアドレス ',
        POLICY_PREMIUM: '保険料',
        FAMILY_MEMBERS: '被保険者の家族',
        MEMBER: '家族メンバー',
        RELATIONSHIP: '続柄',
        RELATIONSHIP_P: '続柄',
        AGE: '年齢',
        ADD_MEMBER: '家族を追加',
        REMOVE: '削除',
        TERMS_AND_CONDITIONS: '利用規約',
        TERMS: '私は内容を読んだうえで、同意します',
        CONFIRM_ORDER: '確定する',
        DECLARATION: '宣言',
        HEREBY_DECLARE: '私はここに以下を宣言し、同意します',
        BACK_TO_APPLICATION: '申込書に戻る',
        PAYMENT_INFORMATION: '支払い情報',
        FORM_OF_PAYMENT: '支払い方法',
        AUTOMATIC_DEBIT: '口座自動振替',
        CREDIT_CARD: 'クレジットカード',
        BANK: '金融機関名',
        BANK_P: '金融機関名',
        BANK_AGENCY: '支店',
        BANK_AGENCY_P: '支店',
        CHECKING_ACCOUNT: '口座番号',
        CHECKING_ACCOUNT_P: '口座番号',
        PAYMENT_DAY: '支払い日',
        PAYMENT_DAY_P: '支払い日',
        MONTHLY: '/月',
        ANNUAL: '/年',
        CARD_NUMBER: 'カード番号',
        CARD_NUMBER_P: 'カード番号',
        VALIDITY: '使用期限 MM/YYYY',
        VALIDITY_P: 'MM/YYYY',
        FREQUENCY: '契約プラン',
        CONFIRM_PLACE_ORDER: '入力内容の確認と確定',
        PLEASE_SELECT_FREQ: '契約プランを選択',
        APPLICATION_ID: '申込書ID',
        PRIMARY_INSURED: '主な被保険者',
        SAVE_AS_SHARE: '保存 / 共有',
        MAKE_NEW_HIRE: '新しく採用する',
        TOTAL_PREMIUM: '保険料合計',
        CONTINUE: '続行',
        PAYMENT_CONFIRMATION: '支払いの確認',
        GENDER: '性別',
        GENDER_P: '性別',
        PER_FREQUENCY: '契約プラン',
        PAYMENT_DAY1: '支払い日',
        CYBERSOURCE: 'Cybersource',
        PAYMENT_REFERENCE: '支払い参照コード',
        CHANNEL_NAME: 'チャネル名',
        PAYMENT_DAY2: '毎月',
        SINGLE_PREMIUM: '一時払保険料',
        MONTHLY_1: '月額',
        ANNUAL_1: '年額',
        PLEASE_SELECT_PAYMODE: '支払い方法を選択してください'
    }
}
