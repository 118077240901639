import React from 'react';
import Logo from '../../assets/images/logo.svg';
import { BrlCurrency } from '../../utils/prepare-data';
import { CONTENT } from '../../utils/page-content';
import './header.styles.scss';


const Header = ({ language, currentUser, pageName, currentUserData }) => (
    <header>
        <div className="logoheader">
            <div className="container-fluid header-wrapper container-center">
                <div className="row">
                    <div className="col-md-3 full-logo">
                        <div className="brand-name"><img src={Logo} width="150" alt="Prudential" /></div>
                    </div>
                    <div className="col-md-12 mobile-header-logo">
                        <div className="brand-name">
                            <img src={Logo} width="150" alt="Prudential" />
                            {/* <div className="text-right borker-mobile">
                                <ul className="list-unstyled list-inline mini-btn">
                                    {
                                        (pageName !== 'Home') && (
                                            <li className="btn">
                                                <span>
                                                    {(currentUserData.Broker) ? currentUserData.Broker : `CA`}
                                                </span> <br />
                                                <span>
                                                    {currentUserData.BrokerType}
                                                </span>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="text-right broker-desktop">
                        <ul className="list-unstyled list-inline mini-btn">
                            {
                                (pageName !== 'Home') && (
                                    <li className="btn">
                                        <span>
                                            {(currentUserData.Broker) ? currentUserData.Broker : `CA`}
                                        </span> <br />
                                        <span>
                                            {currentUserData.BrokerType}
                                        </span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-inverse navbar-background">
            <div className="background-image"></div>
            <div className="container-fluid header-wrapper container-center">
                <div className="navbar-header">
                    {
                        (pageName !== 'Home' && pageName !== 'Login') && (
                            <div className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                <ul className="nav navbar-nav product-info">
                                    <li>
                                        <div className="header-content">
                                            <span className="header-product">
                                                {(currentUser.Product) ? currentUser.Product : currentUser.default}
                                            </span>
                                            &nbsp;{currentUser.Plan}
                                        </div>
                                    </li>
                                    <li>
                                        {/* <span>{(currentUser.Plan) ? currentUser.Plan : 'Plan'}</span> */}
                                    </li>
                                </ul>
                                <ul className="nav navbar-nav premium-info">
                                    <li>
                                        <div className="header-content">
                                            <span>{CONTENT[language].SUM_INSURED}&nbsp;</span>
                                            <span className="header-product">
                                                {(currentUser.SumInsured) ? BrlCurrency(currentUser.SumInsured) : '-'}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="last">
                                        <div className="header-content">
                                            <span>{CONTENT[language].PREMIUM}&nbsp;</span>
                                            <span className="header-product">
                                                {
                                                    (currentUser.headPremium) ? `${BrlCurrency(currentUser.headPremium)} ${(currentUser.PayFreq === '12') ? CONTENT[language].MONTHLY : ((currentUser.PayFreq === '1') ? CONTENT[language].ANNUAL : CONTENT[language].SINGLE_PREMIUM)}` : '-'
                                                }
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                </div>


                <div className="collapse navbar-collapse" id="myNavbar">
                    {
                        (pageName !== 'Home' && pageName !== 'Login') && (
                            <ul className="nav navbar-nav product-details">
                                <li>
                                    <div className="header-content">
                                        <span>{CONTENT[language].PRODUCT}</span><br />
                                        <span className="header-product">
                                            {(currentUser.Product) ? currentUser.Product : currentUser.default}
                                        </span>
                                        &nbsp;{currentUser.Plan}
                                    </div>
                                </li>
                                <li>
                                    <div className="header-content">
                                        <span>{CONTENT[language].SUM_INSURED}</span><br />
                                        <span className="header-product">{(currentUser.SumInsured) ? BrlCurrency(currentUser.SumInsured) : currentUser.default}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="header-content">
                                        <span>{CONTENT[language].PREMIUM} </span><br />
                                        <span className="header-product">
                                            {
                                                BrlCurrency(currentUser.headPremium)
                                            }
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        )
                    }
                </div>
            </div>
        </nav>
    </header>
);

export default Header;
