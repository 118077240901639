
export const ValidationData = {
    Product: {
        stateValue: 'Product',
        level: 1,
        msg: {
            EN: 'Please select product',
            PT: 'Selecione o produto',
            JP: '製品を選択してください'
        },
        isError: false
    },
    Plan: {
        stateValue: 'Plan',
        level: 1,
        msg: {
            EN: 'Please select plan',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    SumInsured: {
        stateValue: 'SumInsured',
        level: 1,
        msg: {
            EN: 'Please select sum insured',
            PT: 'Selecione o valor segurado',
            JP: '被保険者の合計を選択してください'
        },
        isError: false
    },
    FamilyCombination: {
        stateValue: 'FamilyCombination',
        level: 1,
        msg: {
            EN: 'Please select family combination',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    TermOptions: {
        stateValue: 'TermOptions',
        level: 1,
        msg: {
            EN: 'Please select premium term',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    PayOptions: {
        stateValue: 'PayOptions',
        level: 1,
        msg: {
            EN: 'Please select premium payment mode',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    StaffOptions: {
        stateValue: 'StaffOptions',
        level: 1,
        msg: {
            EN: 'Please select staff discount',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    SalesOptions: {
        stateValue: 'SalesOptions',
        level: 1,
        msg: {
            EN: 'Please select sales channel',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    ImpairedLifeOptions: {
        stateValue: 'ImpairedLifeOptions',
        level: 1,
        msg: {
            EN: 'Please select impaired-life indicator',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    DiabetesOptions: {
        stateValue: 'DiabetesOptions',
        level: 1,
        msg: {
            EN: 'Please select diabetes indicator',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    HyperTensionOptions: {
        stateValue: 'HyperTensionOptions',
        level: 1,
        msg: {
            EN: 'Please select Hyper-tension indicator',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    SmartHealthOptions: {
        stateValue: 'SmartHealthOptions',
        level: 1,
        msg: {
            EN: 'Please select smart health indicator',
            PT: 'Selecione o plano',
            JP: 'プランを選択してください'
        },
        isError: false
    },
    Name: {
        stateValue: 'Owner,Name',
        level: 2,
        msg: {
            EN: 'Please enter first name',
            PT: 'Por favor insira o nome completo',
            JP: 'フルネームを入力してください'
        },
        isError: false
    },
    GivenName: {
        stateValue: 'Owner,GivenName',
        level: 2,
        msg: {
            EN: 'Please enter last name',
            PT: 'Por favor insira o nome completo',
            JP: 'フルネームを入力してください'
        },
        isError: false
    },
    DateOfBirth: {
        stateValue: 'Owner,DateOfBirth',
        level: 2,
        msg: {
            EN: 'Please select the date of birth',
            PT: 'Selecione a data de nascimento',
            JP: '生年月日を選択してください'
        },
        checkFor: {
            ageLimit: 14,
            msg: {
                EN: 'Age should be 14 Yrs or above',
                PT: 'A idade deve ser 14 anos ou mais',
                JP: '年齢は14歳以上である必要があります'
            },
            maxAge: 99,
            maxAgeMsg: {
                EN: 'Age should be 99 Yrs or less',
                PT: 'A idade deve ser 99 anos ou menos',
                JP: '年齢は99歳以下である必要があります'
            }
        },
        isError: false
    },
    CPF: {
        stateValue: 'Owner,CPF',
        level: 2,
        msg: {
            EN: 'Please enter CPF number',
            PT: 'Insira o número do CPF',
            JP: 'CPF番号を入力してください'
        },
        checkFor: {
            test: /\d\d\d.\d\d\d.\d\d\d-\d\d/,
            msg: {
                EN: 'Enter a valid CPF number',
                PT: 'Insira um número válido de CPF',
                JP: '有効なCPF番号を入力してください'
            }
        },
        isError: false
    },
    SSN: {
        stateValue: 'Owner,SSN',
        level: 2,
        msg: {
            EN: 'Please enter RG number',
            PT: 'Insira o número RG',
            JP: 'RG番号を入力してください'
        },
        checkFor: {
            test: /\d\d.\d\d\d.\d\d\d-\d/,
            msg: {
                EN: 'Enter a valid RG number',
                PT: 'Insira um número RG válido',
                JP: '有効なRG番号を入力してください'
            }
        },
        isError: false
    },
    DateOfDispatch: {
        stateValue: 'Owner,DateOfDispatch',
        level: 2,
        msg: {
            EN: 'Please provide the date of dispatch',
            PT: 'Forneça a data de envio',
            JP: '発送日をお知らせください'
        },
        checkFor: {
            ageLimit: 0,
            msg: {
                EN: 'Date of issue cannot be a future date',
                PT: 'A data de emissão não pode ser uma data futura',
                JP: '発行日を将来の日付にすることはできません'
            }
            // maxAge: 25,
            // maxAgeMsg: {
            //     EN: 'Maximum number of years should be 25 Yrs or less',
            //     PT: 'O número máximo de anos deve ser 25 anos ou menos'
            // }
        },
        isError: false
    },
    IssuingAgency: {
        stateValue: 'Owner,IssuingAgency',
        level: 2,
        msg: {
            EN: 'Please provide the name of Issuing Agency',
            PT: 'Forneça o nome da agência emissora',
            JP: '発行機関の名前を入力してください'
        },
        isError: false
    },
    StreetHouseNo: {
        stateValue: 'Owner,Contact,StreetHouseNo',
        level: 3,
        msg: {
            EN: 'Please enter your address',
            PT: 'Por favor insira o seu endereço',
            JP: '住所を入力してください'
        },
        isError: false
    },
    Locality: {
        stateValue: 'Owner,Contact,Locality',
        level: 3,
        msg: {
            EN: 'Please enter city name',
            PT: 'Por favor insira o nome da cidade',
            JP: '都市名を入力してください'
        },
        isError: false
    },
    PostalCode: {
        stateValue: 'Owner,Contact,PostalCode',
        level: 3,
        msg: {
            EN: 'Please enter Postcode',
            PT: 'Insira o CEP',
            JP: 'CEPを入力してください'
        },
        // checkFor: {
        //     test: /\d\d\d\d\d-\d\d\d/,
        //     msg: {
        //         EN: 'Enter a valid Postcode number',
        //         PT: 'Insira um número CEP válido',
        //         JP: '有効なCEP番号を入力してください'
        //     }
        // },
        isError: false
    },
    Profession: {
        stateValue: 'Owner,Profession',
        level: 2,
        msg: {
            EN: 'Please enter your profession',
            PT: 'Por favor, digite sua profissão',
            JP: 'あなたの職業を入力してください',
        },
        isError: false
    },
    Gender: {
        stateValue: 'Owner,Gender',
        level: 2,
        msg: {
            EN: 'Please select gender',
            PT: 'Por favor selecione um gênero',
            JP: '性別を選択してください'
        },
        isError: false
    },
    ContactNumber: {
        stateValue: 'Owner,ContactNumber',
        level: 2,
        msg: {
            EN: 'Please enter your contact phone',
            PT: 'Por favor insira seu telefone de contato',
            JP: '連絡先の電話番号を入力してください'
        },
        checkFor: {
            test: /(\(\d\d\)\d\d\d\d-\d\d\d\d|\(\d\d\)\d\d\d\d\d-\d\d\d\d)/,
            msg: {
                EN: 'Enter a valid contact number',
                PT: 'Insira um número de contato válido',
                JP: '有効な連絡先番号を入力してください'
            }
        },
        isError: false
    },
    Email: {
        stateValue: 'Owner,Email',
        level: 2,
        msg: {
            EN: 'Please enter your email',
            PT: 'Por favor introduza o seu e-mail',
            JP: 'あなたのメールアドレスを入力してください'
        },
        checkFor: {
            test: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/,
            msg: {
                EN: 'Please enter a valid email',
                PT: 'Por favor digite um email válido',
                JP: '正しいメールアドレスを入力してください'
            }
        },
        isError: false
    },
    FamilyMembers: {
        Name: {
            stateValue: 'FamilyMembers,Name',
            level: 2,
            msg: {
                EN: 'Please enter family member\'s name',
                PT: 'Insira o nome do membro da família',
                JP: '家族の名前を入力してください'
            },
            isError: false
        },
        Relationship: {
            stateValue: 'FamilyMembers,Relationship',
            level: 2,
            msg: {
                EN: 'Please select relationship',
                PT: 'Por favor, insira o relacionamento',
                JP: '関係を選択してください'
            },
            isError: false
        },
        Age: {
            stateValue: 'FamilyMembers,Age',
            level: 2,
            msg: {
                EN: 'Please enter age',
                PT: 'Por favor insira a idade',
                JP: '年齢を入力してください'
            },
            checkFor: {
                ageLimit: 14,
                msg: {
                    EN: 'Age should be 14 years or above',
                    PT: 'A idade deve ser 14 anos ou mais',
                    JP: '年齢は14歳以上である必要があります'
                }
            },
            isError: false
        },
        GenderF: {
            stateValue: 'FamilyMembers,GenderF',
            level: 2,
            msg: {
                EN: 'Please select gender',
                PT: 'Por favor selecione um gênero',
                JP: '性別を選択してください'
            },
            isError: false
        },
        ProfessionF: {
            stateValue: 'FamilyMembers,ProfessionF',
            level: 2,
            msg: {
                EN: 'Please enter profession',
                PT: 'Por favor, digite a profissão',
                JP: '職業を入力してください'
            },
            isError: false
        },
        SmartHealthOptionsF: {
            stateValue: 'FamilyMembers,SmartHealthOptionsF',
            level: 2,
            msg: {
                EN: 'Please select smart health indicator',
                PT: 'Por favor selecione um gênero',
                JP: '性別を選択してください'
            },
            isError: false
        },
        ImpairedLifeOptionsF: {
            stateValue: 'FamilyMembers,ImpairedLifeOptionsF',
            level: 2,
            msg: {
                EN: 'Please select impaired life indicator',
                PT: 'Por favor selecione um gênero',
                JP: '性別を選択してください'
            },
            isError: false
        },
        DiabetesOptionsF: {
            stateValue: 'FamilyMembers,DiabetesOptionsF',
            level: 2,
            msg: {
                EN: 'Please select diabetes indicator',
                PT: 'Por favor selecione um gênero',
                JP: '性別を選択してください'
            },
            isError: false
        },
        HyperTensionOptionsF: {
            stateValue: 'FamilyMembers,HyperTensionOptionsF',
            level: 2,
            msg: {
                EN: 'Please select hyper-tension indicator',
                PT: 'Por favor selecione um gênero',
                JP: '性別を選択してください'
            },
            isError: false
        },
    }
};

export const PaymentValidationData = {
    Bank: {
        BankName: {
            stateValue: 'PaymentInfo,Bank,BankName',
            level: 3,
            msg: {
                EN: 'Please enter bank name',
                PT: 'Por favor insira o nome do banco',
                JP: 'P銀行名を入力してください'
            },
            isError: false
        },
        BankAgnecy: {
            stateValue: 'PaymentInfo,Bank,BankAgency',
            level: 3,
            msg: {
                EN: 'Please enter bank agency',
                PT: 'Por favor, insira a agência bancária',
                JP: '銀行代理店にご入場ください'
            },
            checkFor: {
                numberOnly: 'numberOnly',
                msg: {
                    EN: 'Bank agency can be only numbers',
                    PT: 'Agência bancária pode ser apenas números',
                    JP: '銀行代理店は数字のみにすることができます'
                }
            },
            isError: false
        },
        CheckingAccount: {
            stateValue: 'PaymentInfo,Bank,CheckingAccount',
            level: 3,
            msg: {
                EN: 'Please enter an account number',
                PT: 'Por favor insira um número de conta',
                JP: 'アカウント番号を入力してください'
            },
            checkFor: {
                numberOnly: 'numberOnly',
                msg: {
                    EN: 'Account number can be only numbers',
                    PT: 'O número da conta pode ser apenas números',
                    JP: 'アカウント番号は番号のみにすることができます'
                }
            },
            isError: false
        },
        PaymentDay: {
            stateValue: 'PaymentInfo,Bank,PaymentDay',
            level: 3,
            msg: {
                EN: 'Please select payment day',
                PT: 'Selecione o dia de pagamento',
                JP: 'お支払い日をお選びください'
            },
            isError: false
        }
    },
    creditCard: {
        CardNumber: {
            stateValue: 'PaymentInfo,creditCard,CardNumber',
            level: 3,
            msg: {
                EN: 'Please enter card number',
                PT: 'Por favor insira o número do cartão',
                JP: 'カード番号を入力してください'
            },
            checkFor: {
                test: /\d\d\d\d-\d\d\d\d-\d\d\d\d-\d\d\d\d/,
                msg: {
                    EN: 'Enter a valid credit card number',
                    PT: 'Insira um número de cartão de crédito válido',
                    JP: '有効なクレジットカード番号を入力してください'
                }
            },
            isError: false
        },
        Validity: {
            stateValue: 'PaymentInfo,creditCard,Validity',
            level: 3,
            msg: {
                EN: 'Please enter validity',
                PT: 'Por favor, insira a validade',
                JP: '有効性を入力してください',
            },
            checkFor: {
                test: /\d\d\/\d\d\d\d/,
                msg: {
                    EN: 'Enter the valid format of expiration',
                    PT: 'Insira o formato válido de expiração',
                    JP: '有効な有効期限の形式を入力してください'
                }
            },
            checkMonthYear: {
                msg: {
                    EN: 'Enter a valid month and year',
                    PT: 'Insira um mês e ano válidos',
                    JP: '有効な月と年を入力してください'
                }
            },
            checkExpiry: {
                msg: {
                    EN: 'Your card is expired',
                    PT: 'Seu cartão expirou',
                    JP: 'カードの有効期限が切れています'
                }
            },
            isError: false
        }
    }
};
