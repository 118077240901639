import { ProfessionList } from './profession-list';
import { StateList } from './state-list';
import { CONTENT } from './page-content';
import { ValidationData } from './validation-data';

export const preparePayload = (method, functionName, data) => {
    return {
        "jsonrpc": "",
        "method": method,
        "params": {
            "type": 0,
            "chaincodeID": {
                "name": ""
            },
            "ctorMsg": {
                "function": functionName,
                "args": data //Portugese
            },
            "secureContext": ""
        },
        "id": 0
    }
}

const GetCardType = (CardNumber) => {
    let re = new RegExp("^4");
    if (CardNumber.match(re) !== null) {
        return "Visa";
    }

    if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            CardNumber
        )
    ) {
        return "MasterCard";
    }

    re = new RegExp("^3[47]");
    if (CardNumber.match(re) != null) {
        return "AmericanExpress";
    }

    return "unknown";
};

export const newPolicyData = (user, premiumData) => {
    const RiskMembers = [];
    const memberData = {
        "CoverType": user.CoverType,
        "Risk_Comm": (new Date()).toISOString(),
        "RiskBody": {
            "BasePremium": 0,
            "Channel": "Broker",
            "CoverType": user.CoverType,
            "EventDate": (new Date()).toISOString(),
            "InsuredParty": {
                "Age": getNumberOfYears(user.Owner.DateOfBirth),
                "Dob": getDateformat(user.Owner.DateOfBirth),
                "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                "Id": {
                    "IDType": "CPF",
                    "IDNumber": user.Owner.CPF,
                    "IssueDate": "",
                    "ExpiryDate": "",
                    "IssuingAgency": "",
                },
                "Name": user.Owner.Name,
                "Occupation": ""
            },
            "Options": user.Option,
            "Payment_Frequency": user.PayFreq,
            "Plan": user.Plan,
            "Region": "",
            "Risk_Comm": (new Date()).toISOString(),
            "Riskclass": "PA",
            "RiskId": "1",
            "RiskType": "PA",
            "Sum_Insured": parseFloat(user.SumInsured),
            "Term": (user.Product === 'Funeral:Funeral_v3' || user.Product === 'DD:DD') ? 5 : 2,
            "TermUnits": "years"
        },
        "Riskclass": "PA",
        "RiskId": "1",
        "RiskType": "PA"
    };

    if (user.Product === 'Funeral:Funeral_v3' || user.Product === 'DD:DD') {
        memberData.RiskBody.InsuredParty.MortalityClass = "Standard";
    }

    if (user.Product !== 'Funeral:Funeral_v3' || user.Product === 'DD:DD') {
        memberData.RiskBody.InsuredParty.Documents = [
            {
                "AttachDate": "2020-10-14T09:40:36.940647435Z",
                "AttachNote": {
                    "Dte": "2020-10-14T09:40:36.940647435Z",
                    "Text": "photoid",
                    "User": "Hello"
                },
                "Descr": "photoid",
                "Url": "www.docstore.com"
            },
            {
                "AttachDate": "2020-10-14T09:40:36.940647435Z",
                "AttachNote": {
                    "Dte": "2020-10-14T09:40:36.940647435Z",
                    "Text": "photoid",
                    "User": "Hello"
                },
                "Descr": "photo",
                "Url": "www.docstore.com"
            }
        ];

        memberData.RiskBody.InsuredParty.OtherPolicyInfo = [
            {
                "Company": "FWD",
                "PolicyExpiryDate": "2020-10-14T09:40:36.940647435Z",
                "PolicyID": "FWD128937",
                "PolicyIssuanceDate": "2020-10-14T09:40:36.940647435Z",
                "Remark": "",
                "Status": "In-Force",
                "SumInsured": parseFloat(user.SumInsured),
            }
        ];
    }

    const DDdata = {
        "RiskType": "PA",
        "RiskId": "2",
        "Riskclass": "PA",
        "CoverType": "Funeral:AccidentalDD",
        "Risk_Comm": (new Date()).toISOString(),
        "RiskBody": {
            "RiskType": "PA",
            "RiskId": "2",
            "Riskclass": "PA",
            "Plan": `${premiumData.AD.value}Monthly`,
            "CoverType": "Funeral:AccidentalDD",
            "Sum_Insured": 1000000,
            "InsuredParty": {
                "Name": user.Owner.Name,
                "Dob": getDateformat(user.Owner.DateOfBirth),
                "Age": getNumberOfYears(user.Owner.DateOfBirth),
                "MortalityClass": "Standard",
                "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                "Id": {
                    "ExpiryDate": "",
                    "IDNumber": user.Owner.SSN,
                    "IDType": "RG",
                    "IssueDate": getDateformat(user.Owner.DateOfDispatch),
                    "IssuingAgency": user.Owner.IssuingAgency
                },
                "Occupation": "Doctor"
            },
            "TermUnits": "years",
            "Term": 5,
            "Region": "",
            "Channel": "PRU",
            "Payment_Frequency": user.PayFreq,
            "BasePremium": premiumData.Premium, // change
            "EventDate": (new Date()).toISOString(),
            "Risk_Comm": (new Date()).toISOString(),
            "Options": [
                {
                    "Option": "AD1",// change
                    "Included": "Y",
                    "SumInsured": 1000000
                }
            ]
        }
    }
    RiskMembers.push(memberData);
    if (user.Product === 'Funeral:Funeral_v3') {
        RiskMembers.push(DDdata);
    }
    user.FamilyMembers.map((member, i) => {
        if (!member.Name) {
            return false;
        }
        const dummyMember = JSON.parse(JSON.stringify(memberData));
        dummyMember.RiskId = `${(i * 2) + 3}`;
        dummyMember.RiskBody.RiskId = `${(i * 2) + 3}`;
        dummyMember.RiskBody.InsuredParty.Name = member.Name;
        dummyMember.RiskBody.InsuredParty.Age = parseInt(member.Age);
        dummyMember.RiskBody.InsuredParty.Dob = "01-01-1999";
        dummyMember.RiskBody.InsuredParty.Kinship = member.Relationship;
        dummyMember.RiskBody.InsuredParty.Occupation = member.ProfessionF;
        dummyMember.RiskBody.InsuredParty.Gender = member.GenderF;
        dummyMember.RiskBody.InsuredParty.Id = {
            "IDType": "",
            "IDNumber": "",
            "IssueDate": "",
            "ExpiryDate": "",
            "IssuingAgency": ""
        };
        RiskMembers.push(dummyMember);

        const dummyDD = JSON.parse(JSON.stringify(DDdata));
        dummyDD.RiskId = `${(i * 2) + 4}`;
        dummyDD.RiskBody.RiskId = `${(i * 2) + 4}`;
        dummyDD.RiskBody.InsuredParty.Name = member.Name;
        dummyDD.RiskBody.InsuredParty.Age = parseInt(member.Age);
        dummyDD.RiskBody.InsuredParty.Dob = "01-01-1999";
        dummyDD.RiskBody.InsuredParty.Kinship = member.Relationship;
        dummyDD.RiskBody.InsuredParty.Occupation = member.ProfessionF;
        dummyDD.RiskBody.InsuredParty.Gender = member.GenderF;
        dummyDD.RiskBody.InsuredParty.Id = {
            "IDType": "",
            "IDNumber": "",
            "IssueDate": "",
            "ExpiryDate": "",
            "IssuingAgency": ""
        };
        RiskMembers.push(dummyDD);
        return dummyMember;
    });
    const { expMonth, expYear } = getCreditCardExpiry(user.PaymentInfo.creditCard.Validity);

    let firstName = "";
    let lastName = "";
    if (user.Owner.Name) {
        const name = user.Owner.Name.split(' ');
        firstName = name[0];
        lastName = name[1];
    }

    let HeaderData;

    HeaderData = {
        "Product": user.Product,
        "email": user.Owner.Email,
        "Term": (user.Product === 'TropicalDisease:TropicalDisease_v2') ? 2 : 5,
        "Channel": "Broker",
        "Agent": user.Broker || "7878787",
        "InstallmentPremium": premiumData.headPremium,
        "CPF": user.Owner.CPF,
        "RG": user.Owner.SSN,
        "Mandate": {
            "BankAccount": {
                "AccountName": user.Owner.Name,
                "AccountNo": user.PaymentInfo.Bank.CheckingAccount,
                "AccountType": "Savings",
                "BankBranch": user.PaymentInfo.Bank.BankAgency,
                "BankName": user.PaymentInfo.Bank.BankName,
                "OtherRef": user.PaymentReferenceNo
            },
            "CreditCard": {
                "BankRefNum": user.PaymentReferenceNo,
                "CardNumber": user.PaymentInfo.creditCard.CardNumber,
                "CardType": GetCardType(user.PaymentInfo.creditCard.CardNumber.replace(/-/g, '')),//5&4
                "ExpiryMonth": `${expMonth}`,
                "ExpityYear": `${expYear}`,
            },
            "MandateRefNo": user.PaymentReferenceNo, // CyberSource refernce number
            "MandateStatus": "Active",
            "BillDay": parseInt(user.PaymentInfo.Bank.PaymentDay) || 0,
        },
        "Owner": {
            "Contact": {
                "Add": {
                    "Add1": user.Owner.Contact.StreetHouseNo,
                    "Add2": user.Owner.Contact.Locality,
                    "Add3": "",
                    "Add4": "",
                    "City": user.Owner.Contact.Locality,
                    "Country": "Brazil",
                    "Pcode": user.Owner.Contact.PostalCode
                },
                "Email": user.Owner.Email,
                "FbId": "",
                "Phone": user.Owner.ContactNumber,
            },
            "Dob": getDateformat(user.Owner.DateOfBirth),
            "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
            "ID": [
                {
                    "ExpiryDate": "",
                    "IDNumber": user.Owner.CPF,
                    "IDType": "CPF",
                    "IssueDate": "",
                    "IssuingAgency": ""
                },
                {
                    "ExpiryDate": "",
                    "IDNumber": user.Owner.SSN,
                    "IDType": "RG",
                    "IssueDate": getDateformat(user.Owner.DateOfDispatch),
                    "IssuingAgency": user.Owner.IssuingAgency
                }
            ],
            "Name": firstName,
            "GivenName": lastName || firstName,
            "Nationality": "",
            "Occupation": "",
            "Race": "",
            "Smoker": ""
        },
        "PaymentFrequency": parseInt(user.PayFreq),
        "PaymentMethod": "CC", // if fail ==> NCC (NO CREDIT CARD)
        "StartDate": (new Date()).toISOString()
    }

    if (user.Product === 'DD:DD') {
        HeaderData = {
            "Product": user.Product,
            "Term": 5,
            "Channel": "Brokers",
            "Email": user.Owner.Email,
            "Agent": user.Broker || "7878787",
            "StartDate": (new Date()).toISOString(),
            "PaymentMethod": "CC",
            "PaymentFrequency": 1,
            "InstallmentPremium": premiumData.headPremium,
            "Mandate": {
                "MandateRefNo": user.PaymentReferenceNo,
                "MandateStatus": "Active",
                "BankAccount": {
                    "BankName": "SCB Thailand",
                    "BankBranch": "SCB00123",
                    "AccountType": "Savings",
                    "AccountName": "Custome name",
                    "AccountNo": "123987123987",
                    "OtherRef": "SCB10101"
                },
                "CreditCard": {
                    "BankRefNum": user.PaymentReferenceNo,
                    "CardNumber": user.PaymentInfo.creditCard.CardNumber,
                    "CardType": GetCardType(user.PaymentInfo.creditCard.CardNumber.replace(/-/g, '')),//5&4
                    "ExpiryMonth": `${expMonth}`,
                    "ExpityYear": `${expYear}`,
                }
            },
            "Owner": {
                "Name": `${firstName} ${lastName}`,
                "Dob": getDateformat(user.Owner.DateOfBirth),
                "Smoker": "S",
                "Gender":  (user.Owner.Gender) ? user.Owner.Gender : "M",
                "Nationality": "Singaporean",
                "Race": "Chinese",
                "Occupation": "Doctor",
                "ID": [
                    {
                        "IDType": "tax",
                        "IDNumber": user.Owner.CPF,
                        "IssueDate": "2020-01-20",
                        "ExpiryDate": "2025-02-08",
                        "IssuingAgency": "abcd"
                    },
                    {
                        "ExpiryDate": "",
                        "IDNumber": user.Owner.SSN,
                        "IDType": "RG",
                        "IssueDate": getDateformat(user.Owner.DateOfDispatch),
                        "IssuingAgency": user.Owner.IssuingAgency
                    }
                ],
                "Contact": {
                    "Email": user.Owner.Email,
                    "Phone": user.Owner.ContactNumber,
                    "FbId": "",
                    "Add": {
                        "Add1": user.Owner.Contact.StreetHouseNo,
                        "Add2": user.Owner.Contact.Locality,
                        "Add3": "",
                        "Add4": "",
                        "City": user.Owner.Contact.Locality,
                        "Country": "Brazil",
                        "Pcode": user.Owner.Contact.PostalCode
                    }
                }
            }
        }
    }

    return {
        "Header": HeaderData,
        "Risks": RiskMembers
    };
}

export const newPolicyDataBupa = (user, premiumData, product = 'Bupa_ReAssure') => {
    const { expMonth, expYear } = getCreditCardExpiry(user.PaymentInfo.creditCard.Validity);
    
    let HeaderData = {
        "Product": `coherent:${product}`,
        "Term": user.TermOptions,
        "Channel": user.SalesOptions,
        "Agent": `${product}-123`,
        "AgentName": `${product} Agent 2`,
        "Email": user.Owner.Email,
        "StartDate": (new Date()).toISOString(),
        "EndDate": "2023-06-17T00:00:36.940641599Z",
        "PaymentMethod": "CC",
        "PaymentFrequency": user.PayOptions,
        "InstallmentPremium": premiumData.headPremium,
        "PaymentReference": "",
        "PreferredLanguage": "English",
        "PromoCode": "save10",
        "ReferenceCode": "",
        "Currency": {
            "Code": "INR"
        },
        "EmailPreferance": "e-policy",
        "OldPolicyID": "",
        "LevyType": "IA",
        "Plan": user.Product,
        "Mandate": {
            "MandateRefNo": "000546",
            "MandateStatus": "Pending",
            "CreditCard": {
                "BankRefNum": user.PaymentReferenceNo,
                "CardNumber": user.PaymentInfo.creditCard.CardNumber,
                "CardType": GetCardType(user.PaymentInfo.creditCard.CardNumber.replace(/-/g, '')),
                "ExpiryMonth": `${expMonth}`,
                "ExpityYear": `${expYear}`
            }
        },
        "Owner": {
            "Occupation": user.Owner.Profession,
            "Nationality": "India",
            "Race": "India",
            "Data": null,
            "Title": "",
            "Name": user.Owner.Name,
            "GivenName": user.Owner.GivenName,
            "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
            "Dob": getDateformat(user.Owner.DateOfBirth),
            "Smoker": "S",
            "ID": [
                {
                    "IDType": "National ID",
                    "IDNumber": user.Owner.CPF || "AMRSU9974E",
                    "IssueDate": "",
                    "ExpiryDate": "",
                    "IssuingAgency": ""
                }
            ],
            "Contact": {
                "Email": user.Owner.Email,
                "Phone": user.Owner.ContactNumber,
                "FbId": "",
                "Add": {
                    "Add1": user.Owner.Contact.StreetHouseNo,
                    "Add2": user.Owner.Contact.Locality,
                    "Add3": "",
                    "Add4": "",
                    "City": user.Owner.Contact.Locality,
                    "Country": "India",
                    "Pcode": user.Owner.Contact.PostalCode
                }
            }
        }        
    }

    const CoInsuredList = [];

    const CoInsuredObj =  {
        "Age": 18,
        "MortalityClass": "S",
        "Occupation": user.Owner.Profession,
        "Title": "",
        "GivenName": "",
        "Kinship": "",
        "Name": user.Owner.Name,
        "Dob": "",
        "Gender": "M",
        "Id": {
            "IDType": "National ID",
            "IDNumber": "AMRSU9974E",
            "IssueDate": "",
            "ExpiryDate": "",
            "IssuingAgency": ""
        },
        "Contact": {
            "Email": "",
            "Phone": "",
            "FbId": "",
            "Add": {
                "Add1": "",
                "Add2": "",
                "Add3": "",
                "Add4": "",
                "City": "",
                "Country": "",
                "Pcode": ""
            }
        },
        "Documents": [
            {
                "Url": "",
                "Descr": "",
                "AttachDate": "2022-06-14T11:26:17.234937Z",
                "AttachNote": {
                    "Dte": "2022-06-14T11:26:17.234937Z",
                    "User": "Hello",
                    "Text": ""
                }
            }
        ],
        "Data": [
            {
                "ExtraField1": "Extra Insured Data",
                "ExtraField2": "Extra Insured Data"
            }
        ]
    };
    // CoInsuredList.push(CoInsuredObj); // inserted first 

    user.FamilyMembers.map((member, i) => {
        if (member.Name) {
            const dummyInsured = JSON.parse(JSON.stringify(CoInsuredObj));
            dummyInsured.Name = member.Name;
            dummyInsured.Kinship = member.Relationship;
            dummyInsured.Age = parseInt(member.Age);
            dummyInsured.Gender = member.GenderF;
            dummyInsured.Occupation = member.ProfessionF;
            CoInsuredList.push(dummyInsured); // inserted first 
        }
    });

    const RiskMembers = [
        {
            "RiskType": "Health",
            "RiskId": "1",
            "Riskclass": "Health",
            "CoverType": `coherent:${product}`,
            "Risk_Comm": (new Date()).toISOString(),
            "RiskBody": {
                "RiskType": "Health",
                "RiskId": "1",
                "Riskclass": "Health",
                "Plan": user.Plan,
                "CoverType": `coherent:${product}`,
                "Sum_Insured": parseFloat(user.SumInsured),
                "Ratings": [
                    {
                        "RatingFactor": "Staff",
                        "Value": user.StaffOptions
                    },
                    {
                        "RatingFactor": "Standing_Instruction",
                        "Value": "Y"
                    },
                    {
                        "RatingFactor": "Location",
                        "Value": user.CityOptions
                    },
                    {
                        "RatingFactor": "LiveHealthyBenefitPoints",
                        "Value": "2500"
                    },
                    {
                        "RatingFactor": "Renewal",
                        "Value": "N"
                    },
                    {
                        "RatingFactor": "FamilyCombination",
                        "Value": user.FamilyCombination
                    },
                    {
                        "RatingFactor": "PaymentMethod",
                        "Value": "CreditCard"
                    },
                    {
                        "RatingFactor": "PA Cover",
                        "Value": "N" // PA flag value
                    }
                ],
                "InsuredParty": {
                    "Age": 18,
                    "MortalityClass": "S",
                    "Occupation": user.Owner.Profession,
                    "Title": "",
                    "GivenName": user.Owner.GivenName,
                    "Kinship": "self",
                    "Name": user.Owner.Name,
                    "Dob": getDateformat(user.Owner.DateOfBirth),
                    "Gender": "M",
                    "Id": {
                        "IDType": "National ID",
                        "IDNumber": user.Owner.CPF || "AMRSU9974E",
                        "IssueDate": "",
                        "ExpiryDate": "",
                        "IssuingAgency": ""
                    },
                    "Contact": {
                        "Email": user.Owner.Email,
                        "Phone": user.Owner.ContactNumber,
                        "FbId": "",
                        "Add": {
                            "Add1": user.Owner.Contact.StreetHouseNo,
                            "Add2": user.Owner.Contact.Locality,
                            "Add3": "",
                            "Add4": "",
                            "City": user.Owner.Contact.Locality,
                            "Country": "India",
                            "Pcode": user.Owner.Contact.PostalCode
                        }
                    },
                    "Documents": [
                        {
                            "Url": "",
                            "Descr": "",
                            "AttachDate": "2022-06-14T11:26:17.234937Z",
                            "AttachNote": {
                                "Dte": "2022-06-14T11:26:17.234937Z",
                                "User": "Hello",
                                "Text": ""
                            }
                        }
                    ],
                    "Data": [
                        {
                            "ExtraField1": "Extra Insured Data",
                            "ExtraField2": "Extra Insured Data"
                        }
                    ]
                }, 
                "CoInsured": CoInsuredList,
                "TermUnits": "years",
                "Term": 1,
                "Region": "Asia",
                "Channel": "Direct Sales",
                "Payment_Frequency": "1",
                "BasePremium": 0,
                "EventDate": (new Date()).toISOString(),
                "Risk_Comm": (new Date()).toISOString(),
                "Risk_End": "2023-06-17T00:00:36.940641599Z",
                "Options": [
                    {

                        "Option": "PA",

                        "Descr": "",

                        "Included": "N",

                        "SumInsured": 1500000,

                        "Plan": ""

                    },

                    {

                        "Option": "HC",

                        "Descr": "",

                        "Included": "N",

                        "SumInsured": 0,

                        "Plan": ""

                    },

                    {

                        "Option": "SG",

                        "Descr": "",

                        "Included": "N",

                        "SumInsured": 0,

                        "Plan": ""

                    }
                ],
                "Beneficiaries": [
                    {
                        "PersonalDets": {
                            "SurName": "Brown",
                            "Name": "William",
                            "Dob": "1998-09-03",
                            "MaritalStatus": "Single",
                            "Gender": "M",
                            "Id": "6756897698",
                            "LegalRep": {
                                "RepresentativeId": "RepresentativeId",
                                "RepDets": {
                                    "GivenName": "GivenName",
                                    "Name": "Name",
                                    "Dob": "08-10-1986",
                                    "MaritalStatus": "Married",
                                    "Gender": "M",
                                    "Id": "IDNumber"
                                },
                                "Relationship": "Relationship"
                            },
                            "Nationality": "Singaporean",
                            "Occupation": "Job",
                            "Contact": {
                                "Email": "arvind.kushwaha@coherent.global",
                                "Phone": "5765 8779",
                                "FbId": "",
                                "Add": {
                                    "Add1": "1 Thailand St.",
                                    "Add2": "Street 2 of Bangkok",
                                    "Add3": "District 3",
                                    "Add4": "",
                                    "City": "Mumbai",
                                    "Country": "India",
                                    "Pcode": "411045"
                                }
                            }
                        },
                        "BenefitAmt": 1000.65,
                        "Payee": {
                            "AccountName": "AcountName",
                            "Bank": "BankName",
                            "Branch": "BankBranch",
                            "Account": "AccountNo",
                            "IBAN": "IBAN",
                            "Address": {
                                "Add1": "1 Thailand St.",
                                "Add2": "Street 2 of Bangkok",
                                "Add3": "District 3",
                                "Add4": "",
                                "City": "Mumbai",
                                "Country": "India",
                                "Pcode": "411045"
                            }
                        }
                    }
                ],
                "Data": [
                    {
                        "DM Rider Discount_1": 0.1,
                        "DM Rider Discount_2": 0.1,
                        "DM Rider Discount_3": 0.1,
                        "PA_Member": 3, // here PA member dropdown value
                        "SM_1_Flag": user.SmartHealthOptions,
                        "Acute_Rider_1": "Best_Care",
                        "Acute_Rider_Benefit_1": 5000,
                        "DM_Riders_1": "DM_Gold",
                        "WP_Waiver_1": 2,
                        "SM_2_Flag": "N",
                        "Acute_Rider_2": "Best_Care",
                        "Acute_Rider_Benefit_2": 5000,
                        "DM_Riders_2": "DM_Gold",
                        "WP_Waiver_2": 4,
                        "SM_3_Flag": "N",
                        "Acute_Rider_3": "Best_Care",
                        "Acute_Rider_Benefit_3": 5000,
                        "DM_Riders_3": "DM_Gold",
                        "WP_Waiver_3": 4,
                        "SM_4_Flag": "N",
                        "Acute_Rider_4": "Best_Care",
                        "Acute_Rider_Benefit_4": 5000,
                        "DM_Riders_4": "DM_Gold",
                        "WP_Waiver_4": 4,
                        "SM_5_Flag": "N",
                        "Acute_Rider_5": "Best_Care",
                        "Acute_Rider_Benefit_5": 5000,
                        "DM_Riders_5": "DM_Gold",
                        "WP_Waiver_5": 4,
                        "SM_6_Flag": "N",
                        "Acute_Rider_6": "Best_Care",
                        "Acute_Rider_Benefit_6": 5000,
                        "DM_Riders_6": "DM_Gold",
                        "WP_Waiver_6": 4,
                        "SM_7_Flag": "N",
                        "Acute_Rider_7": "Best_Care",
                        "Acute_Rider_Benefit_7": 5000,
                        "DM_Riders_7": "DM_Gold",
                        "WP_Waiver_7": 4,
                        "SM_1_DiabetesLoading": user.DiabetesOptions,
                        "SM_1_HyperTensionLoading": user.HyperTensionOptions,
                        "SM_1_ImpairedLifeLoading": parseFloat(user.ImpairedLifeOptions) / 100,
                        "SM_2_DiabetesLoading": "N",
                        "SM_2_HyperTensionLoading": "N",
                        "SM_2_ImpairedLifeLoading": 0,
                        "SM_3_DiabetesLoading": "N",
                        "SM_3_HyperTensionLoading": "N",
                        "SM_3_ImpairedLifeLoading": 0,
                        "SM_4_DiabetesLoading": "N",
                        "SM_4_HyperTensionLoading": "N",
                        "SM_4_ImpairedLifeLoading": 0,
                        "SM_5_DiabetesLoading": "N",
                        "SM_5_HyperTensionLoading": "N",
                        "SM_5_ImpairedLifeLoading": 0,
                        "SM_6_DiabetesLoading": "N",
                        "SM_6_HyperTensionLoading": "N",
                        "SM_6_ImpairedLifeLoading": 0,
                        "SM_7_DiabetesLoading": "N",
                        "SM_7_HyperTensionLoading": "N",
                        "SM_7_ImpairedLifeLoading": 0,
                        "Im_Ind_1": "N",
                        "Im_Ind_2": "N",
                        "Im_Ind_3": "N",
                        "Im_Ind_4": "N",
                        "Im_Ind_5": "N",
                        "Im_Ind_6": "N",
                        "Im_Ind_7": "N"
                    }
                ]
            },
            "Data": [
                {
                    "ExtraField1": "Extra Risk Data",
                    "ExtraField2": "Extra Risk Data"
                },
                {
                    "ExtraField1": "Extra Risk Data",
                    "ExtraField2": "Extra Risk Data"
                }
            ]
        }
    ];

    return {
        "Header": HeaderData,
        "Risks": RiskMembers
    };
}

export const quoteData = (user) => {
    let requestData = {};
    if (user.Product === 'Funeral:Funeral_v3' || user.Product === 'DD:DD') {
        requestData = {
            "Product": user.Product,
            "ContID": "",
            "Status": "",
            "Term": 5,
            "Channel": "Broker",
            "StartDate": (new Date()).toISOString(),
            "EndDate": getPolicyEndDate(),
            "PaymentFrequency": parseInt(user.PayFreq),
            "InstallmentPremium": "",
            "Owner": {
                "Name": "Sylvia Tan",
                "Dob": getDateformat(user.Owner.DateOfBirth),
                "Smoker": "",
                "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                "Id": "31231423456",
                "Contact": {
                    "Email": "sylvia.tan@coherent.com.hk",
                    "Phone": "2222",
                    "FbId": "",
                    "Add": {
                        "Add1": "1227 Dama de Noche St.",
                        "Add2": "Brgy. Don Bosco",
                        "Add3": "District 2",
                        "Add4": "",
                        "City": "Manila",
                        "Country": "Philippines",
                        "Pcode": "1016"
                    }
                }
            },
            "Email": "sylvia.tan@coherent.com.hk",
            "Agent": "7878787",
            "Risks": [
                {
                    "RiskType": "PA",
                    "PolicyId": "454545",
                    "RiskId": "1",
                    "Riskclass": "PA",
                    "CoverType": user.CoverType,
                    "Risk_Comm": (new Date()).toISOString(),
                    "Risk_End": getPolicyEndDate(),
                    "RiskBody": {
                        "RiskType": "PA",
                        "PolicyId": "454545",
                        "RiskId": "1",
                        "Riskclass": "PA",
                        "Plan": user.Plan,
                        "CoverType": user.CoverType,
                        "Sum_Insured": 0,
                        "EventDate": (new Date()).toISOString(),
                        "InsuredParty": {
                            "Name": "Sylvia Tan",
                            "Dob": getDateformat(user.Owner.DateOfBirth),
                            "Age": getNumberOfYears(user.Owner.DateOfBirth),
                            "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                            "Id": "",
                            "Occupation": "OC0939",
                            "MortalityClass": "Standard"
                        },
                        "TermUnits": "years",
                        "Term": 1,
                        "Region": "Asia",
                        "Payment_Frequency": user.PayFreq.toString(),
                        "BasePremium": 0,
                        "OptionalPremium": 0,
                        "PremiumLevy": 0,
                        "NetPremium": 0,
                        "Installment_Premium": 0,
                        "TotalPremium": 0,
                        "Risk_Comm": (new Date()).toISOString(),
                        "Risk_End": getPolicyEndDate(),
                        "StampDuty": 0,
                        "Gst": 0,
                        "Status": "N",
                        "UWStatus": "N",
                        "Engine": "",
                        "Options": user.Option
                    }
                }
            ]
        }
    } else {
        requestData = {
            "Product": user.Product,
            "ContID": "",
            "Status": "",
            "Term": 1,
            "Channel": "Broker",
            "StartDate": (new Date()).toISOString(),
            "EndDate": "2021-07-28T09:40:36.940641775Z",
            "PaymentFrequency": parseInt(user.PayFreq),
            "InstallmentPremium": " ",
            "Owner": {
                "Name": "Ernesto Tan",
                "Dob": getDateformat(user.Owner.DateOfBirth),
                "Smoker": "",
                "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                "Id": "31231423456",//what is the use of this
                "Contact": {
                    "Email": "ernesto.tan@coherent.com.hk",
                    "Phone": "2222",
                    "FbId": "",
                    "Add": {
                        "Add1": "1227 Dama de Noche St.",
                        "Add2": "Brgy. Don Bosco",
                        "Add3": "District 2",
                        "Add4": "",
                        "City": "Manila",
                        "Country": "Philippines",
                        "Pcode": "1016"
                    }
                }
            },
            "Email": "ernesto.tan@coherent.com.hk",
            "Agent": "7878787",
            "Risks": [
                {
                    "RiskType": "PA",
                    "PolicyId": "454545",
                    "RiskId": "1",
                    "Riskclass": "PA",
                    "CoverType": user.CoverType,
                    "Risk_Comm": "2020-07-28T09:40:36.940647435Z",
                    "Risk_End": "2021-07-28T09:40:36.940647508Z",
                    "RiskBody": {
                        "RiskType": "PA",
                        "PolicyId": "454545",
                        "RiskId": "1",
                        "Riskclass": "PA",
                        "Plan": user.Plan,
                        "CoverType": user.CoverType,
                        "Sum_Insured": 0,
                        "InsuredParty": {
                            "Name": "Ernesto Tan",
                            "Dob": getDateformat(user.Owner.DateOfBirth),
                            "Age": 22,
                            "Gender": (user.Owner.Gender) ? user.Owner.Gender : "M",
                            "Id": "",
                            "Occupation": "OC0939"
                        },
                        "TermUnits": "years",
                        "Term": 1,
                        "Region": "Asia",
                        "Payment_Frequency": user.PayFreq.toString(),
                        "BasePremium": 0,
                        "OptionalPremium": 0,
                        "PremiumLevy": 0,
                        "NetPremium": 0,
                        "Installment_Premium": 0,
                        "TotalPremium": 0,
                        "Risk_Comm": "2020-04-01T12:27:41+08:00",
                        "Risk_End": "2020-04-17T12:27:41+08:00",
                        "StampDuty": 0,
                        "Gst": 0,
                        "Status": "N",
                        "UWStatus": "N",
                        "Engine": "",
                        "Options": user.Option
                    }
                }
            ]
        }
    }
    return requestData;
}

export const HompePageData = {
    "Funeral:Funeral_v3": {
        title: "Accidental Funeral Bundle",
        Individual: {
            title: "Individual Funeral Assistance: ",
            description: "Funeral Assistance service or reimbursement of the insured person's funeral expenses. The provision of the Funeral " +
                "Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and " +
                "+55 (11) 2664 4015 for calls to be collected from abroad."
        },
        Family1: {
            title: 'Family Funeral Assistance I: ',
            description: `Funeral Assistance service or reimbursement of funeral expenses of the insured and his spouse. It is equivalent to the spouse:
                        the insured's partner if, at the time of the contract, the insured person was separated judicially, or if he / she was
                        already de facto separated. The provision of the Funeral Assistance service must be requested to the 24-hour Assistance
                        Center by calling 0800 775 3423 and +55 (11) 2664 4015 for calls to be collected from abroad.`
        },
        Family2: {
            title: 'Family Funeral Assistance II: ',
            description: `Funeral Assistance service or reimbursement of funeral expenses for the insured, his / her spouse and children under
                        24 years of age. It is equivalent to the spouse: the insured's partner if, at the time of the contract, the insured person was
                        separated judicially, or if he / she was already de facto separated. Minors dependent on the insured and the insured's
                        stepchildren are treated as children, provided the affiliation of the insured's spouse or partner is duly proven. The provision
                        of the Funeral Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and
                        +55 (11) 2664 4015 for calls to be collected from abroad.`
        },
        FamilyOpen: {
            title: 'Family Funeral Assistance III: ',
            description: `Funeral Assistance service or reimbursement of funeral expenses for the insured, his / her spouse and children under
                        24 years of age. It is equivalent to the spouse: the insured's partner if, at the time of the contract, the insured person was
                        separated judicially, or if he / she was already de facto separated. Minors dependent on the insured and the insured's
                        stepchildren are treated as children, provided the affiliation of the insured's spouse or partner is duly proven. The provision
                        of the Funeral Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and
                        +55 (11) 2664 4015 for calls to be collected from abroad.`
        }
    },
    "TropicalDisease:TropicalDisease_v2": {
        title: "Topical Disease",
        Plan1: {
            title: 'Individual Tropical Disease Assistance: ',
            description: "Tropical Disease Assistance service or reimbursement of the insured person's funeral expenses. The provision of the Funeral " +
                "Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and " +
                "+55 (11) 2664 4015 for calls to be collected from abroad."
        },
        Plan2: {
            title: 'Family Tropical Disease Assistance I: ',
            description: `Tropical Disease service or reimbursement of funeral expenses of the insured and his spouse. It is equivalent to the spouse:
                    the insured's partner if, at the time of the contract, the insured person was separated judicially, or if he / she was
                    already de facto separated. The provision of the Funeral Assistance service must be requested to the 24-hour Assistance
                    Center by calling 0800 775 3423 and +55 (11) 2664 4015 for calls to be collected from abroad.`
        },
        Plan3: {
            title: 'Family Tropical Disease Assistance II: ',
            description: `Tropical Disease service or reimbursement of funeral expenses for the insured, his / her spouse and children under
                    24 years of age. It is equivalent to the spouse: the insured's partner if, at the time of the contract, the insured person was
                    separated judicially, or if he / she was already de facto separated. Minors dependent on the insured and the insured's
                    stepchildren are treated as children, provided the affiliation of the insured's spouse or partner is duly proven. The provision
                    of the Funeral Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and
                    +55 (11) 2664 4015 for calls to be collected from abroad.`
        }
    },
    "Funeral:AccidentalDD": {
        title: "Accidental Death u0026 Dismemberment",
        '25Monthly': {
            title: 'Individual Tropical Disease Assistance: ',
            description: "Tropical Disease Assistance service or reimbursement of the insured person's funeral expenses. The provision of the Funeral " +
                "Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and " +
                "+55 (11) 2664 4015 for calls to be collected from abroad."
        },
        '50Monthly': {
            title: 'Family Tropical Disease Assistance I: ',
            description: `Tropical Disease service or reimbursement of funeral expenses of the insured and his spouse. It is equivalent to the spouse:
                    the insured's partner if, at the time of the contract, the insured person was separated judicially, or if he / she was
                    already de facto separated. The provision of the Funeral Assistance service must be requested to the 24-hour Assistance
                    Center by calling 0800 775 3423 and +55 (11) 2664 4015 for calls to be collected from abroad.`
        },
        '75Monthly': {
            title: 'Family Tropical Disease Assistance II: ',
            description: `Tropical Disease service or reimbursement of funeral expenses for the insured, his / her spouse and children under
                    24 years of age. It is equivalent to the spouse: the insured's partner if, at the time of the contract, the insured person was
                    separated judicially, or if he / she was already de facto separated. Minors dependent on the insured and the insured's
                    stepchildren are treated as children, provided the affiliation of the insured's spouse or partner is duly proven. The provision
                    of the Funeral Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and
                    +55 (11) 2664 4015 for calls to be collected from abroad.`
        },
        '100Monthly': {
            title: 'Family Tropical Disease Assistance II: ',
            description: `Tropical Disease service or reimbursement of funeral expenses for the insured, his / her spouse and children under
                    24 years of age. It is equivalent to the spouse: the insured's partner if, at the time of the contract, the insured person was
                    separated judicially, or if he / she was already de facto separated. Minors dependent on the insured and the insured's
                    stepchildren are treated as children, provided the affiliation of the insured's spouse or partner is duly proven. The provision
                    of the Funeral Assistance service must be requested to the 24-hour Assistance Center by calling 0800 775 3423 and
                    +55 (11) 2664 4015 for calls to be collected from abroad.`
        }
    }
};

export const ProudctCallData = [
    {
        "RiskType": "PA",
        "Plan": [
            {
                "plan": "Plan1",
                "eng": "Individual"
            },
            {
                "plan": "Plan2",
                "eng": "Family up to 2"
            },
            {
                "plan": "Plan3",
                "eng": "Family up to 5"
            }
        ],
        "Channel": [
            "Broker",
            "Agent",
            "Direct"
        ],
        "CoverType": "TropicalDisease:TropicalDisease_v2",
        "Options": [
            {
                "Option": "TD1",
                "Included": "Y",
                "SumInsured": 1500,
                "Description": "Tropical Disease"
            },
            {
                "Option": "TD2",
                "Included": "Y",
                "SumInsured": 3000,
                "Description": "Tropical Disease"
            },
            {
                "Option": "TD3",
                "Included": "Y",
                "SumInsured": 4500,
                "Description": "Tropical Disease"
            }
        ]
    },
    {
        "RiskType": "PA",
        "Plan": [
            {
                "plan": "Plan1",
                "eng": "Individual"
            },
            {
                "plan": "Plan2",
                "eng": "Couple"
            },
            {
                "plan": "Plan3",
                "eng": "Family"
            },
            {
                "plan": "Plan4",
                "eng": "Familyopen"
            }
        ],
        "MortalityClass": [
            "Standard"
            // "A",
            // "B",
            // "C",
            // "D",
            // "E",
            // "F",
            // "G",
            // "H"

        ],
        "CoverType": "Funeral:Funeral_v3",
        "Channel": [
            "Broker",
            "Agent",
            "Direct"
        ],
        "Options": [
            {
                "Option": "FB1",
                "Included": "Y",
                "SumInsured": 12000,
                "Description": "Funeral Benefit"
            }
            // {
            //     "Option": "FB2",
            //     "Included": "Y",
            //     "SumInsured": 12000,
            //     "Description": "Funeral Benefit"
            // },
            // {
            //     "Option": "FB3",
            //     "Included": "Y",
            //     "SumInsured": 12000,
            //     "Description": "Funeral Benefit"
            // },
            // {
            //     "Option": "FB4",
            //     "Included": "Y",
            //     "SumInsured": 12000,
            //     "Description": "Funeral Benefit"
            // },
            // {
            //     "Option": "FB5",
            //     "Included": "Y",
            //     "SumInsured": 12000,
            //     "Description": "Funeral Benefit"
            // }
        ]
    }
];

export const TopicalQuoteData = {
    "Product": "TropicalDisease:TropicalDisease_v2",
    "ContID": "",
    "Term": 1,
    "StartDate": (new Date()).toISOString(),
    "EndDate": "2021-07-28T09:40:36.940641775Z",
    "Status": "",
    "PaymentFrequency": 12,
    "PaymentMethod": "",
    "Mandate": {
        "MandateRefNo": "",
        "MandateStatus": "",
        "BankName": "",
        "MandateDate": "0001-01-01T00:00:00Z",
        "Remarks": ""
    },
    "Underwriting": {
        "UWdate": "0001-01-01T00:00:00Z",
        "UWUser": "",
        "UWstatus": ""
    },
    "Owner": {
        "Name": "Ernesto Tan",
        "Gender": "M",
        "Dob": "2000-07-28",
        "Smoker": "",
        "Id": "31231423456",
        "Contact": {
            "Email": "ernesto.tan@coherent.com.hk",
            "Phone": "2222",
            "FbId": "",
            "Add": {
                "Add1": "1227 Dama de Noche St.",
                "Add2": "Brgy. Don Bosco",
                "Add3": "District 2",
                "Add4": "",
                "City": "Manila",
                "State": "",
                "Country": "Philippines",
                "Pcode": "1016"
            }
        },
        "Occupation": "",
        "Data": null
    },
    "Email": "ernesto.tan@coherent.com.hk",
    "UWstatus": "",
    "Agent": "7878787",
    "Channel": "Broker",
    "PremiumTotals": {
        "GrossAnnualPrem": 472.5,
        "NetAnnualPremium": 472.5,
        "ModalPremium": 472.5,
        "Gst": 0
    },
    "BilledPremium": {
        "GrossAnnualPrem": 0,
        "NetAnnualPremium": 0,
        "ModalPremium": 0,
        "Gst": 0
    },
    "CoinsurancePremium": {
        "GrossAnnualPrem": 0,
        "NetAnnualPremium": 0,
        "ModalPremium": 0,
        "Gst": 0
    },
    "PremiumReceipts": null,
    "PremiumCollection": null,
    "Risks": {
        "1": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "1",
            "Riskclass": "PA",
            "CoverType": "TropicalDisease:TropicalDisease_v2",
            "Risk_Comm": "2020-07-28T09:40:36.940647435Z",
            "Risk_End": "2021-07-28T09:40:36.940647508Z",
            "RiskBody": {
                "RiskType": "PA",
                "PolicyId": "454545",
                "RiskId": "1",
                "Plan": "Plan1",
                "Riskclass": "PA",
                "CoverType": "TropicalDisease:TropicalDisease_v2",
                "EventDate": "0001-01-01T00:00:00Z",
                "InsuredParty": {
                    "GivenName": "",
                    "Name": "Ernesto Tan",
                    "Dob": "2000-07-28",
                    "Age": 22,
                    "Gender": "M",
                    "Id": "",
                    "Occupation": "OC0939",
                    "MortalityClass": "Standard"
                },
                "TermUnits": "years",
                "Term": 1,
                "Payment_Frequency": "1",
                "AdditionalCover": null,
                "BasePremium": 450,
                "OptionalPremium": 0,
                "PremiumLevy": 0,
                "NetPremium": 450,
                "Installment_Premium": 472.5,
                "TotalPremium": 472.5,
                "AppliedPremium": null,
                "Risk_Comm": "2020-04-01T12:27:41+08:00",
                "Risk_End": "2020-04-17T12:27:41+08:00",
                "StampDuty": 0,
                "Gst": 0,
                "Status": "N",
                "UWStatus": "N",
                "Options": [
                    {
                        "Option": "TD1",
                        "Included": "Y",
                        "SumInsured": 1500
                    }
                ],
                "Engine": "",
                "Benefit_Limits": [
                    {
                        "Cover": "",
                        "Benefit": "TD1",
                        "Descr": "Tropical Disease",
                        "ClaimLimit": 1500,
                        "PolicyLimit": 1500
                    }
                ],
                "ClaimList": null,
                "Data": null
            }
        }
    },
    "ClaimList": null,
    "Data": null,
    "Notes": null
};

export const CurrentDateTime = () => {
    const d = new Date(),
        minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes(),
        hours = d.getHours().toString().length === 1 ? '0' + d.getHours() : d.getHours(),
        months = d.getMonth() + 1,
        day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate(),
        monthToApply = (months < 10) ? `0${months}` : months;
    return `${hours}:${minutes} ${day}/${monthToApply}/${d.getFullYear()}`;
}

export const PaymentDayData = () => {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
        '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
        '25', '26', '27', '28', '29', '30'].map(val => {
            return { value: val, name: val };
        });
}

export const getDateForCalendar = (dVal) => {
    try {
        let dateToReturn;
        if (dVal) {
            const dValues = dVal.split('-');
            dateToReturn = new Date(dValues[0], dValues[1] - 1, dValues[2]);
        }
        return dateToReturn;
    } catch (e) {
        console.log('Error in date covnersion', e.message);
    }
    return '';
}

export const getDateToShow = (dVal) => {
    let dateToReturn = '';
    if (dVal) {
        const dValues = dVal.split('-');
        dateToReturn = `${dValues[1]}/${dValues[2]}/${dValues[0]}`;
    }
    return dateToReturn;
}

const getCreditCardExpiry = (expVal) => {
    let expMonth = 0;
    let expYear = 0;
    if (expVal) {
        const expArr = expVal.split('/');
        expMonth = parseInt(expArr[0]);
        expYear = parseInt(expArr[1].substr(2, 2));
    }
    return { expMonth, expYear };
}

const getNumberOfYears = (date) => {
    const age = new Date(new Date() - new Date(getDateformat(date))).getFullYear() - 1970;
    return age || 16;
}

const getDateformat = (dob) => {
    let dateToReturn = '';
    if (dob) {
        const dValues = dob.split('/');
        dateToReturn = `${dValues[2]}-${dValues[1]}-${dValues[0]}`;
    }
    return dateToReturn;
}

const getPolicyEndDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 2);
    return today.toISOString();
}

const initialData = JSON.parse(JSON.stringify(ValidationData.FamilyMembers));

export const getInitialState = (lng) => {
    if (!(lng in CONTENT)) {
        lng = 'PT';
    }
    return {
        language: lng,
        currentUser: {
            CoverType: '',
            Product: '',
            Plan: '',
            SumInsured: '',
            Owner: {
                CPF: '',
                Name: 'Arvind',
                GivenName: 'Kushwaha',
                Email: 'arvind.kushwaha@coherent.global',
                ContactNumber: '',
                DateOfBirth: '17/05/2000',
                SSN: '',
                DateOfDispatch: '',
                IssuingAgency: '',
                Profession: 'Doctor',
                Gender: '',
                Contact: {
                    StreetHouseNo: 'A-1408, Mount Unique Residences, Baner',
                    Locality: 'Pune',
                    PostalCode: '411045'
                }
            },
            Option: [],
            FamilyMembers: [
                {
                    index: 1,
                    Name: '',
                    Relationship: '',
                    Age: '',
                    GenderF: '',
                    ProfessionF: '',
                    Premium: '',
                    AnnualPremium: '',
                    ImpairedLifeOptionsF: '',
                    DiabetesOptionsF: '',
                    HyperTensionOptionsF: '',
                    SmartHealthOptionsF: ''
                }
            ],
            PolicyId: '',
            Broker: '',
            BrokerType: '',
            PayFreq: '12',
            PaymentMethod: '',
            PaymentReferenceNo: '',
            PaymentInfo: {
                Bank: {
                    BankName: '',
                    BankAgency: '',
                    CheckingAccount: '',
                    PaymentDay: ''
                },
                creditCard: {
                    CardNumber: '',
                    Validity: ''
                }
            }
        },
        formData: {
            products: [],
            plans: [],
            faceAmount: [],
            members: 0,
            options: [],
            PaymentDay: PaymentDayData(),
            ProfessionList: ProfessionList,
            StateList: StateList,
            FamilyRelationsList: [
                { value: 'Spouse', name: 'Spouse', allowed: 'FamilyOpen' },
                { value: 'Parents', name: 'Parents', allowed: 'FamilyOpen' },
                { value: 'Grandparents', name: 'Grandparents', allowed: 'FamilyOpen' },
                { value: 'Daughter', name: 'Daughter', allowed: 'FamilyOpen' },
                { value: 'Son', name: 'Son', allowed: 'FamilyOpen' }
            ],
            Gender: [
                { value: 'F', name: 'Female' },
                { value: 'M', name: 'Male' }
            ],
            FamilyRelations: [],
            productsAllowed: ['TropicalDisease', 'AccidentalFuneral', 'DD'],
            AllProducts: [],
            ADmsg: []
        },
        currentUserDisplay: {
            default: CONTENT[lng].DEFAULT_MESSAGE,
            Product: '',
            Plan: '',
            SumInsured: '',
            Premium: '',
            basePremium: '',
            AnnualPremium: '',
            baseAnnualPremium: '',
            headPremium: '',
            SinglePremium: '',
            AD: {
                Desc: '',
                value: ''
            },
            PayFreq: 0
        },
        RequestHeader: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        pageName: '',
        FamilyMemberError: [initialData],
        loading: false,
        cyberSourceCommonHeader: {
            'Authorization': 'Basic Auth ' + btoa('cCXhI7czvA0mBRTY0eQdFl73V5pV7myM : BGZFfZ41GXOktJC5GRLtVZtTbARieZmX4hXR8eMCQJkjnfN2HuqGwP1X81Rl94EXibWSzsMyMvXuRosLf5Sqnb'),
        },
        JwtTokenHeader: {
            'Authorization': 'Basic Auth ' + btoa('cCXhI7czvA0mBRTY0eQdFl73V5pV7myM : BGZFfZ41GXOktJC5GRLtVZtTbARieZmX4hXR8eMCQJkjnfN2HuqGwP1X81Rl94EXibWSzsMyMvXuRosLf5Sqnb'),
            'X-POB-Application': 'coherent',
            'X-POB-UserId': 'carga'
        },
        AccessTokenHeader: {
            'Authorization': 'Basic Auth ' + btoa('cCXhI7czvA0mBRTY0eQdFl73V5pV7myM : BGZFfZ41GXOktJC5GRLtVZtTbARieZmX4hXR8eMCQJkjnfN2HuqGwP1X81Rl94EXibWSzsMyMvXuRosLf5Sqnb'),
            'X-POB-JWT': ''
        }
    };
}

export const TEXT_FILLED_CLASS = 'text-filled';

export const BrlCurrency = (val) => {
    if (val && !isNaN(val)) {
        return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'INR' });
    }
    return '';
}

export const getPlanPremium = (product) => {
    const PlanList = {
        Accidental: {
            "Options": [
                {
                    "Option": "FB1",
                    "Included": "Y",
                    "SumInsured": 10000,
                    "Description": "Funeral Benefit"
                },
                {
                    "Option": "FB2",
                    "Included": "Y",
                    "SumInsured": 20000,
                    "Description": "Funeral Benefit"
                },
                {
                    "Option": "FB3",
                    "Included": "Y",
                    "SumInsured": 30000,
                    "Description": "Funeral Benefit"
                },
                {
                    "Option": "FB4",
                    "Included": "Y",
                    "SumInsured": 40000,
                    "Description": "Funeral Benefit"
                }
            ]
        },
        Funeral: {
            "Options": [
                {
                    "id": 1,
                    "Option": "FB1",
                    "Included": "Y",
                    "SumInsured": 12000,
                    "Description": "Funeral Benefit"
                },
                {
                    "id": 2,
                    "Option": "FB1",
                    "Included": "Y",
                    "SumInsured": 12000,
                    "Description": "Funeral Benefit"
                },
                {
                    "id": 3,
                    "Option": "FB1",
                    "Included": "Y",
                    "SumInsured": 12000,
                    "Description": "Funeral Benefit"
                },
                {
                    "id": 4,
                    "Option": "FB1",
                    "Included": "Y",
                    "SumInsured": 12000,
                    "Description": "Funeral Benefit"
                }
            ]
        },
        TropicalDisease: {
            "Options": [
                {
                    "Option": "TD1",
                    "Included": "Y",
                    "SumInsured": 1500,
                    "Description": "Tropical Disease"
                },
                {
                    "Option": "TD1",
                    "Included": "Y",
                    "SumInsured": 3000,
                    "Description": "Tropical Disease"
                },
                {
                    "Option": "TD1",
                    "Included": "Y",
                    "SumInsured": 4500,
                    "Description": "Tropical Disease"
                }
            ]
        },
        AccidentalFuneral: {
            "Options": [
                {
                    "Option": "AD1",
                    "Included": "Y",
                    "SumInsured": 12000,
                    "Description": "Funeral Benefit"
                }
            ]
        },
        DD: {
            "Options": [
                {
                    "Option": "DD1",
                    "Included": "Y",
                    "SumInsured": 150000,
                    "Description": "Individual"
                }
            ]
        }
    }

    return PlanList[product];
}

export const prepareProductData = (res) => {
    const customRes = {};
    for (const key in res) {
        if (key === 'Funeral') {
            customRes['AccidentalFuneral'] = JSON.parse(JSON.stringify(res[key]));
            customRes['AccidentalFuneral']['Options.BenefitOptions'][0].Desc = 'Accidental and Funeral bundle';
            if (customRes['AccidentalFuneral']['Options.PlanOptions'].length < 4) {
                customRes['AccidentalFuneral']['Options.PlanOptions'].push({
                    "Option": "FamilyOpen",
                    "Desc": "Family Open"
                })
            }
            customRes['AccidentalFuneral']['Options.PlanPremiumOptions'] = [
                {
                    "plan": "Individual",
                    "Option": "25Monthly",
                    "Desc": "R$25 Monthly",
                    "value": "25"
                },
                {
                    "plan": "Family1",
                    "Option": "50Monthly",
                    "Desc": "R$50 Monthly",
                    "value": "50"
                },
                {
                    "plan": "Family2",
                    "Option": "75Monthly",
                    "Desc": "R$75 Monthly",
                    "value": "75"
                },
                {
                    "plan": "FamilyOpen",
                    "Option": "100Monthly",
                    "Desc": "R$100 Monthly",
                    "value": "100"
                }
            ]
        }
        customRes[key] = res[key];
    }
    return customRes;
}

export const PAGE_CONST = {
    FUNERAL: 'Funeral:Funeral_v3',
    TROPICAL: 'TropicalDisease:TropicalDisease_v2',
    DD: "Dread Disease - DD",
    BUPA: "Bupa_ReAssure",
    RIA: "RIA"
}

export const DDValues = {
    12: {
        25: "25",
        50: "50",
        75: "75",
        100: "100",
    },
    1: {
        25: "277.78",
        50: "555.56",
        75: "833.33",
        100: "1111.11",
    },
    0: {
        25: "1388.90",
        50: "2777.80",
        75: "4166.65",
        100: "5555.55",
    },
};

export const getChargeData = (productData) => {
    return [
        {
          "clientReferenceInformation" : "38731194118",
          "requestID" : "",
          "operations" : "PAYMENT",
          "paymentDetails" : {
            "currency" : null,
            "totalAmount" : productData.Header.InstallmentPremium,
            "paymentsInstallmentInformation" : 0
          },
          "card" : {
            "type" : "002",
            "expirationMonth" : productData.Header.Mandate.CreditCard.ExpiryMonth,
            "expirationYear" : `20${productData.Header.Mandate.CreditCard.ExpityYear}`,
            "token" : "7030000000007016793",
            "merchantDescriptor" : "PRP046990F"
          },
          "systemValues" : {
            "systemSpecificValues" : {
              "nr_tokencartaocred" : "7030000000007016793",
              "dt_venctocobr" : "2021-05-28"
            },
            "sourceSystem" : "SONIC"
          }
        }]
}

export const getMemberCount = (plan) => {
    const mType = plan.split("+");
    let Total = 0;
    mType.forEach(element => {
        const cnt = element.substr(0, 1);
        Total += Number(cnt);
    });
    return Total;
}

export const getBupaPayload = (UserData) => {
    return {
        "Inputs": {
            "AllocatePremium": null,
            "BillPeriodEnd": "2023-06-17",
            "BillPeriodStart": (new Date()).toISOString().split('T')[0],
            "BrokerID": 'Bupa123',
            "CancelAllocatedPremium": 1000,
            "CancellationDate": "2022-06-19",
            "CancelRef": null,
            "Channel": UserData.SalesOptions,
            "ClaimAmount": [
                {
                    "Sno": 1,
                    "Benefit": "IN1",
                    "EventDesc": "K8501",
                    "Amount": 1000,
                    "EventStart": "2022-10-28",
                    "EventEnd": "2022-10-29",
                    "EventType": "DET",
                    "Exgratia": "N",
                    "Claimant": "A1010101"
                },
                {
                    "Sno": 2,
                    "Benefit": null,
                    "EventDesc": null,
                    "Amount": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "EventType": null,
                    "Exgratia": null,
                    "Claimant": null
                },
                {
                    "Sno": 3,
                    "Benefit": null,
                    "EventDesc": null,
                    "Amount": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "EventType": null,
                    "Exgratia": null,
                    "Claimant": null
                }
            ],
            "ClaimApproveAmount": null,
            "ClaimHistory": [
                {
                    "BenefitClaimed": "IN1",
                    "ICDcode": "K8508",
                    "ClaimDate": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "Amount": 10000,
                    "Claimant": null
                }
            ],
            "ClaimRef": "CLM001",
            "ClaimRejectAmount": null,
            "ClaimStatus": "Initiate",
            "ClaimToDate": [],
            "ClmStatus": null,
            "Cover_End_Date": null,
            "Cover_Start_Date": (new Date()).toISOString().split('T')[0],
            "Data": [
                {
                    "DM Rider Discount_1": 0.1,
                        "DM Rider Discount_2": 0.1,
                        "DM Rider Discount_3": 0.1,
                        "PA_Member": 3,
                        "SM_1_Flag": UserData.SmartHealthOptions,
                        "Acute_Rider_1": "Best_Care",
                        "Acute_Rider_Benefit_1": 5000,
                        "DM_Riders_1": "DM_Gold",
                        "WP_Waiver_1": 2,
                        "SM_2_Flag": "N",
                        "Acute_Rider_2": "Best_Care",
                        "Acute_Rider_Benefit_2": 5000,
                        "DM_Riders_2": "DM_Gold",
                        "WP_Waiver_2": 4,
                        "SM_3_Flag": "N",
                        "Acute_Rider_3": "Best_Care",
                        "Acute_Rider_Benefit_3": 5000,
                        "DM_Riders_3": "DM_Gold",
                        "WP_Waiver_3": 4,
                        "SM_4_Flag": "N",
                        "Acute_Rider_4": "Best_Care",
                        "Acute_Rider_Benefit_4": 5000,
                        "DM_Riders_4": "DM_Gold",
                        "WP_Waiver_4": 4,
                        "SM_5_Flag": "N",
                        "Acute_Rider_5": "Best_Care",
                        "Acute_Rider_Benefit_5": 5000,
                        "DM_Riders_5": "DM_Gold",
                        "WP_Waiver_5": 4,
                        "SM_6_Flag": "N",
                        "Acute_Rider_6": "Best_Care",
                        "Acute_Rider_Benefit_6": 5000,
                        "DM_Riders_6": "DM_Gold",
                        "WP_Waiver_6": 4,
                        "SM_7_Flag": "N",
                        "Acute_Rider_7": "Best_Care",
                        "Acute_Rider_Benefit_7": 5000,
                        "DM_Riders_7": "DM_Gold",
                        "WP_Waiver_7": 4,
                        "SM_1_DiabetesLoading": UserData.DiabetesOptions,
                        "SM_1_HyperTensionLoading": UserData.HyperTensionOptions,
                        "SM_1_ImpairedLifeLoading": parseFloat(UserData.ImpairedLifeOptions) / 100,
                        "SM_2_DiabetesLoading": "N",
                        "SM_2_HyperTensionLoading": "N",
                        "SM_2_ImpairedLifeLoading": 0,
                        "SM_3_DiabetesLoading": "N",
                        "SM_3_HyperTensionLoading": "N",
                        "SM_3_ImpairedLifeLoading": 0,
                        "SM_4_DiabetesLoading": "N",
                        "SM_4_HyperTensionLoading": "N",
                        "SM_4_ImpairedLifeLoading": 0,
                        "SM_5_DiabetesLoading": "N",
                        "SM_5_HyperTensionLoading": "N",
                        "SM_5_ImpairedLifeLoading": 0,
                        "SM_6_DiabetesLoading": "N",
                        "SM_6_HyperTensionLoading": "N",
                        "SM_6_ImpairedLifeLoading": 0,
                        "SM_7_DiabetesLoading": "N",
                        "SM_7_HyperTensionLoading": "N",
                        "SM_7_ImpairedLifeLoading": 0,
                        "Im_Ind_1": "N",
                        "Im_Ind_2": "N",
                        "Im_Ind_3": "N",
                        "Im_Ind_4": "N",
                        "Im_Ind_5": "N",
                        "Im_Ind_6": "N",
                        "Im_Ind_7": "N"
                }
            ],
            "Deductable": null,
            "DOB": getDateformat(UserData.Owner.DateOfBirth),
            "EventDate": (new Date()).toISOString().split('T')[0],
            "Gender": "U",
            "InsuredParties": [
                {
                    "Kinship": "self",
                    "Dob": "2001-10-21",
                    "Gender": "M",
                    "Id": "A1010101"
                }
            ],
            "Loadings": [
                {
                    "RiskId": "1",
                    "Type": "Y",
                    "Loading": 10,
                    "LoadInd": "P",
                    "LoadTerm": null,
                    "LoadAge": 60,
                    "Date": null
                },
                {
                    "RiskId": "2",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "3",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "4",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "5",
                    "Type": null,
                    "Loading": 10,
                    "LoadInd": "P",
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "6",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "7",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                }
            ],
            "MortalityClass": null,
            "NumberOfCovers": 1,
            "Occupation": UserData.Owner.Profession,
            "Optional_Benefits": [
                {
                    "Option": "PA",
                    "Desc": "Personal Accident Cover",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": 1500000
                },
                {
                    "Option": "HC",
                    "Desc": "Hospital Cash",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": null
                },
                {
                    "Option": "SG",
                    "Desc": "Safeguard",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": null
                }
            ],
            "Original_Start_Date": (new Date()).toISOString().split('T')[0],
            "OtherPolicyInfo": [],
            "PaymentFrequency": UserData.PayOptions,
            "Plan": UserData.Plan,
            "Policydata": [],
            "PolicyNo": "BUPA-TP-22-909090-",
            "PolicyStatus": null,
            "PremiumBilled": null,
            "PreviousClaimHistory": [],
            "PromoCode": null,
            "Puchase_Date": (new Date()).toISOString().split('T')[0],
            "Ratings": [
                {
                    "RatingFactor": "Staff",
                    "Value": UserData.StaffOptions
                },
                {
                    "RatingFactor": "Standing_Instruction",
                    "Value": "Y"
                },
                {
                    "RatingFactor": "Location",
                    "Value": UserData.CityOptions
                },
                {
                    "RatingFactor": "LiveHealthyBenefitPoints",
                    "Value": 2500
                },
                {
                    "RatingFactor": "Renewal",
                    "Value": "N"
                },
                {
                    "RatingFactor": "FamilyCombination",
                    "Value": UserData.FamilyCombination
                },
                {
                    "RatingFactor": "PaymentMethod",
                    "Value": "CreditCard"
                },
                {
                    "RatingFactor": "PA Cover",
                    "Value": "N"
                }
            ],
            "Reference2": null,
            "SumInsured": UserData.SumInsured,
            "Term": UserData.TermOptions
        },
        "TransactionDate": (new Date()).toISOString(),
        "EngineType": "Type3",
        "Images": [],
        "Reports": [],
        "Solves": [],
        "Revision": "1.2.0",
        "ServiceName": "Bupa_ReAssure",
        "Version": "582af67a-1d07-465e-acf1-54b533836415",
        "Description": "",
        "RequestedOutPut": "",
        "EndPoint": "https://excelengine.demo.sonic.dev.coherent.global/api/v1/product/coherent/engines/Execute/Bupa_ReAssure",
        "IsRichJson": false,
        "ServiceCategories": [
            "Options",
            "Claims"
        ],
        "RequestedServiceCategory": "",
        "ShouldExecuteInputs": false,
        "shouldLogExecutionHistory": true,
        "CallPurpose": "Spark - API Tester"
    }
}

export const getBupaPayloadRIA = (UserData) => {
    return {
        "Inputs": {
            "AllocatePremium": null,
            "BillPeriodEnd": "2023-06-17",
            "BillPeriodStart": (new Date()).toISOString().split('T')[0],
            "BrokerID": 'Bupa123',
            "CancelAllocatedPremium": 1000,
            "CancellationDate": "2022-06-19",
            "CancelRef": null,
            "Channel": UserData.SalesOptions,
            "ClaimAmount": [
                {
                    "Sno": 1,
                    "Benefit": "IN1",
                    "EventDesc": "K8501",
                    "Amount": 1000,
                    "EventStart": "2022-10-28",
                    "EventEnd": "2022-10-29",
                    "EventType": "DET",
                    "Exgratia": "N",
                    "Claimant": "A1010101"
                },
                {
                    "Sno": 2,
                    "Benefit": null,
                    "EventDesc": null,
                    "Amount": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "EventType": null,
                    "Exgratia": null,
                    "Claimant": null
                },
                {
                    "Sno": 3,
                    "Benefit": null,
                    "EventDesc": null,
                    "Amount": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "EventType": null,
                    "Exgratia": null,
                    "Claimant": null
                }
            ],
            "ClaimApproveAmount": null,
            "ClaimHistory": [
                {
                    "BenefitClaimed": "IN1",
                    "ICDcode": "K8508",
                    "ClaimDate": null,
                    "EventStart": null,
                    "EventEnd": null,
                    "Amount": 10000,
                    "Claimant": null
                }
            ],
            "ClaimRef": "CLM001",
            "ClaimRejectAmount": null,
            "ClaimStatus": "Initiate",
            "ClaimToDate": [],
            "ClmStatus": null,
            "Cover_End_Date": null,
            "Cover_Start_Date": (new Date()).toISOString().split('T')[0],
            "Data": [
                {
                    "DM Rider Discount_1": 0.1,
                        "DM Rider Discount_2": 0.1,
                        "DM Rider Discount_3": 0.1,
                        "PA_Member": 3,
                        "SM_1_Flag": UserData.SmartHealthOptions,
                        "Acute_Rider_1": "Best_Care",
                        "Acute_Rider_Benefit_1": 5000,
                        "DM_Riders_1": "DM_Gold",
                        "WP_Waiver_1": 2,
                        "SM_2_Flag": "N",
                        "Acute_Rider_2": "Best_Care",
                        "Acute_Rider_Benefit_2": 5000,
                        "DM_Riders_2": "DM_Gold",
                        "WP_Waiver_2": 4,
                        "SM_3_Flag": "N",
                        "Acute_Rider_3": "Best_Care",
                        "Acute_Rider_Benefit_3": 5000,
                        "DM_Riders_3": "DM_Gold",
                        "WP_Waiver_3": 4,
                        "SM_4_Flag": "N",
                        "Acute_Rider_4": "Best_Care",
                        "Acute_Rider_Benefit_4": 5000,
                        "DM_Riders_4": "DM_Gold",
                        "WP_Waiver_4": 4,
                        "SM_5_Flag": "N",
                        "Acute_Rider_5": "Best_Care",
                        "Acute_Rider_Benefit_5": 5000,
                        "DM_Riders_5": "DM_Gold",
                        "WP_Waiver_5": 4,
                        "SM_6_Flag": "N",
                        "Acute_Rider_6": "Best_Care",
                        "Acute_Rider_Benefit_6": 5000,
                        "DM_Riders_6": "DM_Gold",
                        "WP_Waiver_6": 4,
                        "SM_7_Flag": "N",
                        "Acute_Rider_7": "Best_Care",
                        "Acute_Rider_Benefit_7": 5000,
                        "DM_Riders_7": "DM_Gold",
                        "WP_Waiver_7": 4,
                        "SM_1_DiabetesLoading": UserData.DiabetesOptions,
                        "SM_1_HyperTensionLoading": UserData.HyperTensionOptions,
                        "SM_1_ImpairedLifeLoading": parseFloat(UserData.ImpairedLifeOptions) / 100,
                        "SM_2_DiabetesLoading": "N",
                        "SM_2_HyperTensionLoading": "N",
                        "SM_2_ImpairedLifeLoading": 0,
                        "SM_3_DiabetesLoading": "N",
                        "SM_3_HyperTensionLoading": "N",
                        "SM_3_ImpairedLifeLoading": 0,
                        "SM_4_DiabetesLoading": "N",
                        "SM_4_HyperTensionLoading": "N",
                        "SM_4_ImpairedLifeLoading": 0,
                        "SM_5_DiabetesLoading": "N",
                        "SM_5_HyperTensionLoading": "N",
                        "SM_5_ImpairedLifeLoading": 0,
                        "SM_6_DiabetesLoading": "N",
                        "SM_6_HyperTensionLoading": "N",
                        "SM_6_ImpairedLifeLoading": 0,
                        "SM_7_DiabetesLoading": "N",
                        "SM_7_HyperTensionLoading": "N",
                        "SM_7_ImpairedLifeLoading": 0,
                        "Im_Ind_1": "N",
                        "Im_Ind_2": "N",
                        "Im_Ind_3": "N",
                        "Im_Ind_4": "N",
                        "Im_Ind_5": "N",
                        "Im_Ind_6": "N",
                        "Im_Ind_7": "N"
                }
            ],
            "Deductable": null,
            "DOB": getDateformat(UserData.Owner.DateOfBirth),
            "EventDate": (new Date()).toISOString().split('T')[0],
            "Gender": "U",
            "InsuredParties": [
                {
                    "Kinship": "self",
                    "Dob": "2001-10-21",
                    "Gender": "M",
                    "Id": "A1010101"
                }
            ],
            "Loadings": [
                {
                    "RiskId": "1",
                    "Type": "Y",
                    "Loading": 10,
                    "LoadInd": "P",
                    "LoadTerm": null,
                    "LoadAge": 60,
                    "Date": null
                },
                {
                    "RiskId": "2",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "3",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "4",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "5",
                    "Type": null,
                    "Loading": 10,
                    "LoadInd": "P",
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "6",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                },
                {
                    "RiskId": "7",
                    "Type": null,
                    "Loading": null,
                    "LoadInd": null,
                    "LoadTerm": null,
                    "LoadAge": null,
                    "Date": null
                }
            ],
            "MortalityClass": null,
            "NumberOfCovers": 1,
            "Occupation": UserData.Owner.Profession,
            "Optional_Benefits": [
                {
                    "Option": "PA",
                    "Desc": "Personal Accident Cover",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": 1500000
                },
                {
                    "Option": "HC",
                    "Desc": "Hospital Cash",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": null
                },
                {
                    "Option": "SG",
                    "Desc": "Safeguard",
                    "Plan": null,
                    "Included": "N",
                    "SumInsured": null
                }
            ],
            "Original_Start_Date": (new Date()).toISOString().split('T')[0],
            "OtherPolicyInfo": [],
            "PaymentFrequency": UserData.PayOptions,
            "Plan": UserData.Plan,
            "Policydata": [],
            "PolicyNo": "BUPA-TP-22-909090-",
            "PolicyStatus": null,
            "PremiumBilled": null,
            "PreviousClaimHistory": [],
            "PromoCode": null,
            "Puchase_Date": (new Date()).toISOString().split('T')[0],
            "Ratings": [
                {
                    "RatingFactor": "Staff",
                    "Value": UserData.StaffOptions
                },
                {
                    "RatingFactor": "Standing_Instruction",
                    "Value": "Y"
                },
                {
                    "RatingFactor": "Location",
                    "Value": UserData.CityOptions
                },
                {
                    "RatingFactor": "LiveHealthyBenefitPoints",
                    "Value": 2500
                },
                {
                    "RatingFactor": "Renewal",
                    "Value": "N"
                },
                {
                    "RatingFactor": "FamilyCombination",
                    "Value": UserData.FamilyCombination
                },
                {
                    "RatingFactor": "PaymentMethod",
                    "Value": "CreditCard"
                },
                {
                    "RatingFactor": "PA Cover",
                    "Value": "N"
                }
            ],
            "Reference2": null,
            "SumInsured": UserData.SumInsured,
            "Term": UserData.TermOptions
        },
        "TransactionDate": (new Date()).toISOString(),
        "EngineType": "Type3",
        "Images": [],
        "Reports": [],
        "Solves": [],
        "Revision": "1.2.0",
        "ServiceName": "RIA",
        "Version": "582af67a-1d07-465e-acf1-54b533836415",
        "Description": "",
        "RequestedOutPut": "",
        "EndPoint": "https://excelengine.demo.sonic.dev.coherent.global/api/v1/product/coherent/engines/Execute/RIA",
        "IsRichJson": false,
        "ServiceCategories": [
            "Options",
            "Claims"
        ],
        "RequestedServiceCategory": "",
        "ShouldExecuteInputs": false,
        "shouldLogExecutionHistory": true,
        "CallPurpose": "Spark - API Tester"
    }
}